import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, filter } from 'rxjs/operators';

import { DropDownWritingComponent } from '../drop-down-writing/drop-down-writing.component';

@UntilDestroy()
@Component({
  selector: 'atl-dropdown-autocomplete',
  templateUrl: './dropdown-autocomplete.component.html',
  styleUrls: ['./dropdown-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownAutocompleteComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownAutocompleteComponent
  extends DropDownWritingComponent
  implements OnChanges, OnInit, AfterViewInit
{
  @Input() addingAllowed = true;
  @Input() anchoredButton = false;
  @Input() autocomplete = true;
  @Input() customButtonText!: string;
  @Input() dropdownMargin!: number;
  @Input() dropdownPosition: 'absolute' | 'fixed' = 'absolute';
  @Input() enableVerticalAlign = false;
  @Input() itemCouldBeDeletedFlag: string | boolean = false;
  @Input() itemCouldBeDeletedValue = false;
  @Input() scrollEvent!: Observable<unknown>;
  @Input() placeholder!: string;
  @Input() placeholderInputSearch = 'Input.Search.Placeholder';
  @Input() addButtonLabel = 'Button.Add_new';
  @Input() processHiding = true;
  @Input() notFoundText!: string;
  @Input() matchesQuery = false;
  @Input() clearable = false;
  @Input() set setChange(value: any) {
    if (value) {
      this.changeValue(value);
    }
  }

  @Output() addNewItemEmitter = new EventEmitter();
  @Output() deleteItemEmitter = new EventEmitter<Record<string, unknown>>();
  @Output() openState: EventEmitter<boolean> = new EventEmitter(false);

  @ViewChild('relativeRef') relativeElement!: any;
  @ViewChild('searchInput') searchInput!: ElementRef;

  @ViewChild('inputNewItemRef') set inputNewItemRef(value: ElementRef) {
    this._inputNewItemRef = value;
    if (!this.inputNewItemRef?.nativeElement.value && this.searchQuery) {
      if (this.inputNewItemRef?.nativeElement) {
        this.inputNewItemRef.nativeElement.value = this.searchQuery;
        this.inputNewItemRef.nativeElement.focus();
        this.buttonDisabled = false;
        this.cdr.detectChanges();
      }
    }
  }

  get inputNewItemRef(): ElementRef {
    return this._inputNewItemRef;
  }

  public autocompleteItems: unknown[] | undefined = undefined;

  public isOpened = false;
  public leftOffset!: number;
  public topOffset!: number;
  public newItemCreating = false;
  public isMac = false;
  public buttonDisabled = true;
  public searchQuery: string | undefined;
  private _inputNewItemRef!: ElementRef;
  private openDropdown$ = new BehaviorSubject(false);
  private newItemCreating$ = new BehaviorSubject(false);

  @HostListener('window:keydown', ['$event']) past(event: KeyboardEvent): void {
    if (this.isMac && event.metaKey && event.key === 'k') {
      this.inputSearchFocus();
    }
  }

  clearHandler(event: MouseEvent): void {
    event.stopPropagation();
    this.changeValue(null);
  }

  inputSearchFocus(): void {
    if (this.isOpened) {
      this.searchInput?.nativeElement.focus();
    }
  }

  inputNewItem(value: string): void {
    this.buttonDisabled = !value.length;
  }

  autocompleteChanged(searchStr: string): void {
    this.searchQuery = searchStr;
    if (!searchStr?.length) {
      this.autocompleteItems = undefined;
    } else {
      this.autocompleteItems = this.listOfItems.filter((item) =>
        item[this.bindLabel].toLowerCase().includes(searchStr.toLowerCase())
      );
    }
    this.cdr.detectChanges();
  }

  showAddNewItemInput(event: Event): void {
    event.stopPropagation();
    this.newItemCreating = true;
    this.newItemCreating$.next(this.newItemCreating);
    this.inputNewItemRef?.nativeElement.focus();
  }

  addItemToExistingList(event: Event, str: string): void {
    event.stopPropagation();
    this.addNewItemEmitter.emit(str);
    this.newItemCreating = false;
    this.close();
    this.cdr.markForCheck();
  }

  trackByFn: (i: number, item: any) => number = (i: number, item: any): number => item.id;

  changeValue(value: any): void {
    super.changeValue(value);
    this.close();
  }

  close(): void {
    this.isOpened = false;
    this.resetSearchInput();
    this.newItemCreating = false;
    this.buttonDisabled = true;
    this.openState.emit(false);
  }

  toggle(): void {
    const domRect: DOMRect = this.relativeElement.nativeElement.getBoundingClientRect();
    this.topOffset = domRect.top;
    this.leftOffset = domRect.left;
    this.isOpened = !this.isOpened;
    this.openState.emit(this.isOpened);
    this.openDropdown$.next(this.isOpened);
    if (!this.isOpened) {
      this.resetSearchInput();
      this.buttonDisabled = true;
    } else {
      this.newItemCreating = false;
    }
  }

  handleItemDelete(item: Record<string, unknown>, event: any): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
    this.deleteItemEmitter.emit(item);
  }

  resetSearchInput(): void {
    if (this.searchInput && this.searchInput.nativeElement.value.length) {
      this.searchInput.nativeElement.value = '';
      this.autocompleteChanged(this.searchInput.nativeElement.value);
    }
  }

  listenScrollEvent(): void {
    this.scrollEvent
      .pipe(
        filter(() => this.isOpened),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (this.processHiding) {
          this.close();
          this.cdr.detectChanges();
        }
      });
  }

  ngOnChanges({ scrollEvent }: SimpleChanges): void {
    if (scrollEvent && scrollEvent.currentValue) {
      this.listenScrollEvent();
    }
  }

  ngOnInit(): void {
    this.isMac = window?.navigator.userAgent.includes('Mac');
    this.openDropdown$.pipe(untilDestroyed(this), delay(0)).subscribe(() => {
      this.inputSearchFocus();
    });
    this.newItemCreating$.pipe(untilDestroyed(this), delay(0)).subscribe(() => {
      this.inputNewItemRef?.nativeElement.focus();
    });
  }

  ngAfterViewInit(): void {
    this.inputSearchFocus();
  }
}
