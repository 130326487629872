import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges, SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { of } from 'rxjs';
import { delay, take } from 'rxjs/operators';

import { DateAdapterService } from '../../services/date-adapter.service';
import { InputDateTimePickerComponent } from '../input-date-time-picker/input-date-time-picker.component';

dayjs.extend(customParseFormat);

@Component({
  selector: 'atl-inline-date-time-picker',
  templateUrl: './inline-date-time-picker.component.html',
  styleUrls: ['./inline-date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InlineDateTimePickerComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineDateTimePickerComponent
  extends InputDateTimePickerComponent
  implements ControlValueAccessor, OnChanges, AfterViewInit
{
  private format = 'D.MM.YYYY';

  @Input() set minDate(date: Date | NgbDateStruct) {
    if (date instanceof Date) {
      const minDate = dayjs(date).format(this.format);
      this.min = this.dateAdapter.fromModel(minDate) as NgbDateStruct;
    } else {
      this.minDate = date;
    }
  }

  @Input() dateValue?: string;
  @Input() showFooter = true;
  @Input() showIcon = false;
  @Input() secondInput = false;
  @Input() setDateButton = false;
  @Input() scrollIntoView = false;

  public firstInputPlaceholder = 'Shared.Date_picker.Input.Placeholder';

  @ViewChild('wrapDp', { static: true }) wrapDp!: ElementRef<HTMLDivElement>;

  constructor(public cdr: ChangeDetectorRef, public dateAdapter: DateAdapterService) {
    super(cdr, dateAdapter);
  }

  ngAfterViewInit(): void {
    if (this.dateValue) {
      this.changeValue(this.dateValue);
    }
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.dateValue && typeof changes.dateValue.currentValue === 'string') {
      this.dateValue = changes.dateValue.currentValue as string;
      this.changeValue(this.dateValue);
      this.cdr.detectChanges();
    }
  }

  clearValue(): void {
    if (!this.setDateButton) {
      this.hidePicker();
    }
    this.updateFormValue(null);
  }

  changeValue(value: string | null): void {
    this.updateFormValue(value);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectedDate(initDate: NgbDateStruct): void {
    const simpleDate = this.dateAdapter.toModel(initDate);
    this.changeValue(simpleDate);

    if (!this.setDateButton) {
      if (this.visible) {
        this.onBlur();
      }
      this.hidePicker();
    }
    this.onTouched();
  }

  protected updateFormValue(date: string | null): void {
    if (date) {
      let modelValue = this.dateAdapter.fromModel(date);
      if (!modelValue?.day || !modelValue?.month || !modelValue?.year || modelValue?.year?.toString()?.length < 4) {
        return this.onChange(null);
      }
      if (this.min) {
        const minDate: dayjs.Dayjs = dayjs(`${this.min.day}.${this.min.month}.${this.min.year}`, this.format);
        const inputDate: dayjs.Dayjs = dayjs(date, this.format);

        if (!inputDate.isValid()) {
          return this.onChange(null);
        }

        const isBeforeDate = dayjs(inputDate).isBefore(minDate);
        if (isBeforeDate) {
          modelValue = this.dateAdapter.fromModel(minDate.format(this.format));
        }
      }

      this.datepicker.navigateTo(<NgbDateStruct>modelValue);
      this.datepicker.focusDate(modelValue);
      this.onChange(modelValue);
      this.inputValue = this.dateAdapter.toModel(<NgbDateStruct>modelValue);
      this.input.nativeElement.value = this.inputValue;

      this.cdr.detectChanges();
    } else {
      this.inputValue = '';
      this.input.nativeElement.value = '';
      this.onChange(null);
      this.cdr.detectChanges();
    }
  }

  visiblePicker(): void {
    if (!this.lockedTrigger.focused) return;
    this.visible = true;

    if (this.scrollIntoView) {
      of(null)
        .pipe(delay(0), take(1))
        .subscribe(() => {
          this.wrapDp.nativeElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
        });
    }
  }

  onBlur(): void {
    this.onTouched();
    this.hidePicker();
  }
}
