/* eslint-disable @typescript-eslint/naming-convention */
export const pluralMap: Map<string, Record<string, string>> = new Map([
  ['product', { '=0': 'Shared.Entity.Products', '=1': 'Shared.Entity.Product', other: 'Shared.Entity.Products' }],
  ['floor', { '=0': 'Shared.Entity.Floor', '=1': 'Shared.Entity.Floor', other: 'Shared.Entity.Floors' }],
  ['update', { '=0': 'Shared.Entity.Updates', '=1': 'Title.Newsletter', other: 'Title.Newsletters' }],
  ['task', { '=0': 'Shared.Entity.Tasks', '=1': 'Shared.Entity.Task', other: 'Shared.Entity.Tasks' }],
  ['unit', { '=0': 'Shared.Entity.Units', '=1': 'Shared.Entity.Unit', other: 'Shared.Entity.Units' }],
  ['unit_group', { '=0': 'Shared.Entity.Unit_groups', '=1': 'Shared.Entity.Unit_group', other: 'Shared.Entity.Unit_groups' }],
  ['item', { '=0': 'Shared.Entity.Items', '=1': 'Shared.Entity.Item', other: 'Shared.Entity.Items' }],
]);
