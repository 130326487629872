import { Expose } from 'class-transformer';

import { IReclamationCategory } from './reclamations.model';
import { UnitTypeModel, UnitUserModel } from './unit.model';

export enum ERuleType {
  ReclamationResponsibility = 'reclamation_responsibility',
}

export enum ERuleStatementType {
  MainAdmin = 'MainAdmin',
}

export enum ERuleConditionName {
  Unit = 'unit',
  LayoutType = 'layout_type',
  Category = 'category',
}

export class SpecialRuleModel {
  @Expose({ name: 'id' })
  id?: number;
  @Expose({ name: 'project_id' })
  projectId!: number;
  @Expose({ name: 'statement_type' })
  statementType!: ERuleStatementType;
  @Expose({ name: 'statement_id' })
  statementId!: number;
  @Expose({ name: 'condition_name' })
  conditionName!: ERuleConditionName;
  @Expose({ name: 'condition_value_type' })
  conditionValueType!: string;
  @Expose({ name: 'condition_value_id' })
  conditionValueId!: number | null;
}

export interface IReclamationCategoryExp extends IReclamationCategory {
  disabled?: boolean;
  description?: string;
}

export class UnitTypeModelExp extends UnitTypeModel {
  disabled?: boolean;
  description?: string;
}

export class UnitUserModelExp extends UnitUserModel {
  disabled?: boolean;
  description?: string;
}

export type PermissionItemUnionType = IReclamationCategoryExp | UnitUserModelExp | UnitTypeModelExp;
