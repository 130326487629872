import { ElementRef } from '@angular/core';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types';

export function getSwiperInstanceFromElementRef(el: ElementRef<any> | undefined): Swiper | undefined {
  return el?.nativeElement?.swiper;
}

export const defaultSwiperOptions: SwiperOptions = {
  loop: true,
  keyboard: true,
  navigation: true,
  pagination: false,
  injectStyles: [
    `
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: var(--color-white-1000);
      align-items: center;
      justify-content: center;
      border: 1px solid var(--color-gray-750);
    }
    .swiper-button-prev {
      transform: translateX(22px);
    }
    .swiper-button-prev::after {
      content: "prev";
    }
    .swiper-button-next {
      transform: translateX(-22px);
    }
    .swiper-button-next::after {
      content: "next";
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-family: swiper-icons;
      font-size: 12px;
      font-weight: 500;
      color: var(--color-black-900);
      font-variant: normal;
      letter-spacing: 0;
      line-height: 1;
      text-transform: none;
    }
    .swiper-button-prev svg,
    .swiper-button-next svg {
      display: none;
    }
    .swiper:hover {
      .swiper-button-prev,
      .swiper-button-next {
        display: flex;
      }
    }
    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      background: var(--color-gray-735);
    }
    .swiper-button-prev:active,
    .swiper-button-next:active {
      background: var(--color-black-720);
    }
  `,
  ],
};

export const defaultSwiperModalOptions: SwiperOptions = {
  loop: true,
  keyboard: true,
  navigation: true,
  pagination: false,
  injectStyles: [
    `
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background: var(--color-white-1000);
      align-items: center;
      justify-content: center;
      border: 1px solid var(--color-gray-750);
    }
    .swiper-button-prev {
      transform: translateX(22px);
    }
    .swiper-button-prev::after {
      content: "prev";
    }
    .swiper-button-next {
      transform: translateX(-22px);
    }
    .swiper-button-next::after {
      content: "next";
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-family: swiper-icons;
      font-size: 12px;
      font-weight: 500;
      color: var(--color-black-900);
      font-variant: normal;
      letter-spacing: 0;
      line-height: 1;
      text-transform: none;
    }
    .swiper-button-prev svg,
    .swiper-button-next svg {
      display: none;
    }
    .swiper:hover {
      .swiper-button-prev,
      .swiper-button-next {
        display: flex;
      }
    }
    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      background: var(--color-gray-735);
    }
    .swiper-button-prev:active,
    .swiper-button-next:active {
      background: var(--color-black-720);
    }
  `,
  ],
};

export const constructionCamFullScreenSwiperOptions: SwiperOptions = {
  loop: false,
  keyboard: true,
  navigation: true,
  pagination: false,
  injectStyles: [
    `
    .swiper-button-prev,
    .swiper-button-next {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background: var(--color-white-1000);
      align-items: center;
      justify-content: center;
      border: 1px solid var(--color-gray-750);
    }
    .swiper-button-prev {
      transform: translateX(14px);
    }
    .swiper-button-prev::after {
      content: "prev";
    }
    .swiper-button-next {
      transform: translateX(-14px);
    }
    .swiper-button-next::after {
      content: "next";
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-family: swiper-icons;
      font-size: 12px;
      font-weight: 500;
      color: var(--color-black-900);
      font-variant: normal;
      letter-spacing: 0;
      line-height: 1;
      text-transform: none;
    }
    .swiper-button-prev svg,
    .swiper-button-next svg,
    .swiper-button-disabled {
      display: none;
    }
    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      background: var(--color-gray-735);
    }
    .swiper-button-prev:active,
    .swiper-button-next:active {
      background: var(--color-black-720);
    }
  `,
  ],
};

export const practicalInfoFullScreenSwiperOptions: SwiperOptions = {
  loop: false,
  keyboard: true,
  navigation: true,
  pagination: false,
  injectStyles: [
    `
    .swiper-button-prev,
    .swiper-button-next {
      width: 40px;
      height: 40px;
      border-radius: 12px;
      background: var(--color-white-1000);
      align-items: center;
      justify-content: center;
      border: 1px solid var(--color-gray-750);
    }
    .swiper-button-prev {
      transform: translateX(14px);
    }
    .swiper-button-prev::after {
      content: "prev";
    }
    .swiper-button-next {
      transform: translateX(-14px);
    }
    .swiper-button-next::after {
      content: "next";
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-family: swiper-icons;
      font-size: 12px;
      font-weight: 500;
      color: var(--color-black-900);
      font-variant: normal;
      letter-spacing: 0;
      line-height: 1;
      text-transform: none;
    }
    .swiper-button-prev svg,
    .swiper-button-next svg,
    .swiper-button-disabled {
      display: none;
    }
    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      background: var(--color-gray-735);
    }
    .swiper-button-prev:active,
    .swiper-button-next:active {
      background: var(--color-black-720);
    }
  `,
  ],
};

export const atlImageSliderSwiperOptions: SwiperOptions = {
  slidesPerView: 1,
  loop: true,
  keyboard: true,
  navigation: true,
  mousewheel: false,
  speed: 1,
  allowTouchMove: false,
  pagination: false,
  injectStyles: [
    `
    .swiper-button-prev,
    .swiper-button-next {
      box-sizing: border-box;
      display: none;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: var(--color-white-1000);
      align-items: center;
      justify-content: center;
      border: 1px solid var(--color-gray-750);
    }
    .swiper-button-prev {
      transform: translateX(22px);
    }
    .swiper-button-prev::after {
      content: "prev";
      filter: var(--filter-color-gray);
    }
    .swiper-button-next {
      transform: translateX(-22px);
    }
    .swiper-button-next::after {
      content: "next";
      filter: var(--filter-color-gray);
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-family: swiper-icons;
      font-size: 12px;
      font-weight: 500;
      color: var(--color-black-900);
      font-variant: normal;
      letter-spacing: 0;
      line-height: 1;
      text-transform: none;
    }
    .swiper-button-prev svg,
    .swiper-button-next svg {
      display: none;
    }
    .swiper:hover {
      .swiper-button-prev,
      .swiper-button-next {
        display: flex;
      }
    }
    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      background: var(--color-gray-735);
    }
    .swiper-button-prev:active,
    .swiper-button-next:active {
      background: var(--color-black-720);
    }
  `,
  ],
};

export const recentProjectsSwiperOptions: SwiperOptions = {
  speed: 1,
  loop: false,
  keyboard: true,
  navigation: true,
  allowTouchMove: true,
  slidesPerView: 3,
  slidesPerGroup: 3,
  spaceBetween: 24,
  injectStyles: [
    `
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: var(--color-white-1000);
      align-items: center;
      justify-content: center;
      border: 1px solid var(--color-gray-750);
    }
    .swiper-button-prev {
      transform: translateX(-25px);
    }
    .swiper-button-prev::after {
      content: "prev";
    }
    .swiper-button-next {
      transform: translateX(25px);
    }
    .swiper-button-next::after {
      content: "next";
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-family: swiper-icons;
      font-size: 12px;
      font-weight: 500;
      color: var(--color-black-900);
      font-variant: normal;
      letter-spacing: 0;
      line-height: 1;
      text-transform: none;
    }
    .swiper-button-prev svg,
    .swiper-button-next svg,
    .swiper-button-disabled {
      display: none!important;
    }
    .swiper {
      overflow-x: visible;
    }
    .swiper:hover {
      .swiper-button-prev,
      .swiper-button-next {
        display: flex;
      }
    }
    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      background: var(--color-gray-735);
    }
    .swiper-button-prev:active,
    .swiper-button-next:active {
      background: var(--color-black-720);
    }
  `,
  ],
};
