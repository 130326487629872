import { Injectable } from '@angular/core';
import { ILayoutTypeData } from '@atlas-workspace/shared/models';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  specificPage$ = new ReplaySubject<{ page: string }>(1);
  private _selectedUnitTypeData$ = new ReplaySubject<ILayoutTypeData>(1);

  public get selectedUnitTypeData$(): Observable<ILayoutTypeData> {
    return this._selectedUnitTypeData$.asObservable();
  }

  public setSelectedUnitTypeData(data: ILayoutTypeData): void {
    this._selectedUnitTypeData$.next(data);
  }
}
