import { Expose, Type } from 'class-transformer';
import * as dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { ContractorModel, IContractor } from './contractor.model';
import { IProject, ProjectModel } from './project.model';
import { VendorModel } from './vendor.model';

dayjs.extend(quarterOfYear);

/* eslint-disable */

export interface IUpdateEvent {
  index: number;
  phase: EditPhaseModel;
  updatedPhases: EditPhaseModel[];
  selectedIds: number[];
  state: 'create' | 'update' | 'remove' | 'sort_changes';
  changedSort: ITimeLineSortItem;
}

const quarters = [
  [1, 2, 3],
  [4, 5, 6],
  [7, 8, 9],
  [10, 11, 12],
];

export interface IChart {
  date_range: {
    end_date: string;
    start_date: string;
  };

  phases: {
    end_date: string;
    id: number;
    name: string;
    position: number;
    start_date: string;
    contractor: IContractor;
    project: IProject;
  }[];
}

export interface IPrepDate {
  nameOfMonth: string;
  spaceRight: number;
  isCurrentDay?: boolean;
  isShowCircle: boolean;
  isStartQuarter: boolean;
}

interface ICalcDayWidth {
  countDays: number;
  dayWidth: number;
}

export interface IBootstrapDate {
  day: number;
  month: number;
  year: number;
}

const fullFormatDate = 'MM/DD/YYYY';
const formatYear = 'YYYY';
const formatMonth = 'M';

class DateRange {
  @Expose({ name: 'end_date' })
  endDate: string;
  @Expose({ name: 'start_date' })
  startDate: string;

  constructor() {
    this.endDate = '';
    this.startDate = '';
  }
}

export class ChartModel {
  countMoth = 12;
  dayWidth = 1.3;
  monthWidth = 0;
  countYears = 0;
  currentDay: string;
  currentQuarter: number;
  currentYear: string;
  isMock? = false;

  @Expose({ name: 'date_range' })
  @Type(() => DateRange)
  dateRange!: DateRange;
  @Expose({ name: 'phases' })
  @Type(() => TypeChartModel)
  phases: Exclude<TypeChartModel[], EditPhaseModel[]> = [];

  @Expose({ name: 'progress' })
  progress: number;

  preparationDate: IPrepDate[];

  containerWidth = 0;

  constructor() {
    const currentDay = dayjs().format(fullFormatDate);

    this.progress = 0;
    this.preparationDate = [];
    this.currentDay = currentDay;
    this.currentQuarter = dayjs(currentDay).quarter();
    this.currentYear = dayjs(currentDay).format('YYYY');
    this.phases = [];
    this.dateRange = {
      endDate: currentDay,
      startDate: currentDay,
    };
  }

  getListMenus(): number[] {
    return this.phases.slice().map((phases) => phases.id);
  }

  preparationDates(isCalculationDayWidth = false): IPrepDate[] | [] {
    const middleOfYear = 6;
    this.preparationDate = [];
    this.containerWidth = 0;
    this.dateRange.startDate = this.dateRange.startDate ? this.dateRange.startDate : dayjs().startOf('year').format();
    this.dateRange.startDate = this.dateRange.startDate ? this.dateRange.startDate : this.currentDay;
    const startYear = dayjs(this.dateRange.startDate).format(formatYear);
    const startMonth = Number(dayjs(this.dateRange.startDate).format(formatMonth));
    const quarterNumber = dayjs(this.dateRange.startDate).quarter();
    let endYear = dayjs(this.dateRange.endDate).format(formatYear);
    const endMonth = Number(dayjs(this.dateRange.endDate).format(formatMonth));

    if (startYear === endYear && endMonth - startMonth < middleOfYear) {
      endYear = (Number(endYear) + 1).toString();
    }

    const years = this.calculationYears(startYear, endYear);
    if (years.length === 1) {
      const [year] = years;
      years.push(...[year + 1, year + 2]);
    }
    const startMonthQuarter = this.checkFirstMonthQuarter(quarterNumber, startMonth);

    years.forEach((year, index) => {
      const isFirstYear = index === 0;
      this.onInitYear(year.toString(), isCalculationDayWidth, isFirstYear, startMonthQuarter);
    });
    this.calcPhaseWidth(startYear, isCalculationDayWidth, startMonthQuarter);

    return this.preparationDate || [];
  }

  checkFirstMonthQuarter(quarter: number, startMonth: number): any {
    const index = quarters[quarter - 1].findIndex((month) => month === startMonth);
    return startMonth - index;
  }

  calculationYears(startYear: string, endYear: string): number[] {
    const startedYearDiff = dayjs('01/01/' + startYear);
    const endedYearDiff = dayjs('01/01/' + endYear);
    let startedYear = Number(startYear);
    const length = (this.countYears = endedYearDiff.diff(startedYearDiff, 'year')) + 1;
    const years = [startedYear];
    let i = 0;

    for (; i < length; i++) {
      years.push(++startedYear);
    }

    return years;
  }

  calcPhaseWidth(startYear: string, isCalculationDayWidth = false, startedMonth: number): void {
    const startDateConst = 'startDate';
    const endDateConst = 'endDate';

    const dateFirstMonth = new Date(Number(startYear), startedMonth - 1, 1);

    this.phases = this.phases.map((phase) => {
      const startDate = dayjs(phase[startDateConst]);
      const endDate = dayjs(phase[endDateConst]);
      const numberDaysStart = startDate.diff(dateFirstMonth, 'day');
      const numberDaysEnd = endDate.diff(startDate, 'day') || 0;
      const startQuarter = startDate.quarter();
      const endQuarter = endDate.quarter();
      const startYearStr = startDate.format('YYYY');
      const endYearStr = endDate.format('YYYY');

      let phaseStartWidth: number;
      let phaseEndWidth: number;

      if (isCalculationDayWidth) {
        const startDiffMonth = startDate.diff(dateFirstMonth, 'month') || 0;
        const endDiffMonth = endDate.diff(startDate, 'month');

        const listOfStartDays: ICalcDayWidth[] = this.calcDayWidth(dateFirstMonth, startDiffMonth);
        phaseStartWidth = this.calcPhasePosition(listOfStartDays, numberDaysStart, startDiffMonth);

        const listOfEndDays: ICalcDayWidth[] = this.calcDayWidth(
          <Date>phase[startDateConst],
          !endDiffMonth ? 1 : endDiffMonth
        );
        phaseEndWidth = this.calcPhasePosition(listOfEndDays, numberDaysEnd, startDiffMonth);
      } else {
        phaseStartWidth = numberDaysStart * this.dayWidth;
        phaseEndWidth = numberDaysEnd * this.dayWidth;
      }

      return {
        ...phase,
        start: phaseStartWidth,
        end: phaseEndWidth,
        isCurrentQuarter: this.detectQuarterInfo(
          startQuarter,
          endQuarter,
          startYearStr,
          endYearStr,
          this.currentQuarter,
          this.currentYear
        ),
      };
    });
  }

  detectQuarterInfo(
    phaseQuarterStart: number,
    phaseQuarterEnd: number,
    phaseYearStart: string,
    phaseYearEnd: string,
    currentQuarter: number,
    currentYear: string
  ): boolean {
    if (phaseYearStart === currentYear || phaseYearEnd === currentYear) {
      return phaseQuarterStart === currentQuarter || phaseQuarterEnd >= this.currentQuarter;
    }
    return false;
  }

  calcPhasePosition(listOfDays: ICalcDayWidth[], startDiffDay: number, countMonth: number): number {
    let width = 0;

    for (let i = 0; i < listOfDays.length; i++) {
      if (listOfDays[i].countDays >= startDiffDay) {
        width += startDiffDay * listOfDays[i].dayWidth;
        break;
      } else {
        width += listOfDays[i].countDays * listOfDays[i].dayWidth;
        startDiffDay -= listOfDays[i].countDays;
      }
    }

    return width;
  }

  calcDayWidth(startDate: Date, countMonths: number, calcEndDate = false): ICalcDayWidth[] {
    const width: ICalcDayWidth[] = [];
    let counter = 1;
    let countDays = dayjs(startDate).daysInMonth();
    width.push({ countDays, dayWidth: this.monthWidth / countDays });
    while (counter <= countMonths) {
      const newMonth = dayjs(startDate).add(counter, 'month');
      countDays = dayjs(newMonth).daysInMonth();
      width.push({ countDays, dayWidth: this.monthWidth / countDays });

      counter++;
    }

    return width;
  }

  onInitYear(year: string, isCalculationDayWidth = false, isFirstYear = false, startedMonth: number): void {
    let month = isFirstYear ? startedMonth : 1;
    const numberOfMonth = new Date(this.currentDay).getMonth() + 1;
    let currentQuarter = null;
    let isDisplayQuarterName = false;
    //TODO: need implement logic for toggle (Month/Quarter)[future].

    while (month <= this.countMoth) {
      const quarterNane = dayjs(`${year}/${month}/01`).quarter();
      const nameOfMonth = `${year} Q${quarterNane}`;
      const daysOfMonth = dayjs(`${year}/${month}/01`).daysInMonth();

      if (currentQuarter !== quarterNane) {
        currentQuarter = quarterNane;
        isDisplayQuarterName = true;
      } else {
        isDisplayQuarterName = false;
      }

      if (isCalculationDayWidth) {
        this.dayWidth = this.monthWidth / daysOfMonth;
      }

      const isShowCircle = month % 3 !== 0;
      let spaceRight = daysOfMonth * this.dayWidth;

      if (month === numberOfMonth && year === this.getYearOfCurrentMonth()) {
        const fullMonth = dayjs(`${year}/${month}/01`);
        const countDays = dayjs(this.currentDay).diff(fullMonth, 'day');

        this.preparationDate.push(
          this.returnMonth(
            isDisplayQuarterName ? nameOfMonth : '',
            isShowCircle,
            countDays * this.dayWidth,
            false,
            isDisplayQuarterName
          ),
          // TODO: need double check.
          this.returnMonth(
            isDisplayQuarterName ? nameOfMonth : (countDays as any),
            false,
            (daysOfMonth - countDays) * this.dayWidth,
            true,
            isDisplayQuarterName
          )
        );
      } else {
        this.preparationDate.push(
          this.returnMonth(
            isDisplayQuarterName ? nameOfMonth : '',
            isShowCircle,
            spaceRight,
            false,
            isDisplayQuarterName
          )
        );
      }
      month++;
    }
  }

  returnMonth(
    nameOfMonth: string,
    isShowCircle = false,
    spaceRight: number,
    isCurrentDay = false,
    isStartQuarter = false
  ): IPrepDate {
    this.containerWidth += spaceRight;
    return {
      nameOfMonth,
      isShowCircle,
      spaceRight,
      isCurrentDay,
      isStartQuarter,
    };
  }

  getYearOfCurrentMonth(): string {
    return dayjs(this.currentDay).format(formatYear);
  }
}

export class TypeChartModel {
  color: string;

  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'name' })
  name: string;
  @Expose({ name: 'position' })
  position: number;
  @Expose({ name: 'progress' })
  progress: number;
  @Expose({ name: 'start_date' })
  startDate: string | Date;
  @Expose({ name: 'end_date' })
  endDate: string | Date;
  @Expose({ name: 'start_project_calculation' })
  startProjectCalculation!: boolean;

  @Expose({ name: 'contractor' })
  @Type(() => ContractorModel)
  contractor!: ContractorModel | VendorModel;

  @Expose({ name: 'project' })
  @Type(() => ProjectModel)
  project: ProjectModel | undefined;

  start: number;
  end: number;
  changed = false;

  isMoveInDate = false;
  isCurrentQuarter = false;

  constructor() {
    this.name = '';
    this.startDate = '';
    this.endDate = '';
    this.position = 0;
    this.id = 0;
    this.progress = 0;

    this.start = 0;
    this.end = 0;

    this.color = '#2C6BFD';
  }
}

export class EditPhaseModel extends TypeChartModel {
  isSelect: boolean = false;
  id: number = 0;
  name: string = '';
  startAt: IBootstrapDate = { day: 1, month: 1, year: 2021 };
  endAt: IBootstrapDate = { day: 1, month: 1, year: 2021 };
  company_id!: string;
}

export interface ITimeLineSort {
  [key: string]: ITimeLineSortItem;
}

export interface ITimeLineSortItem {
  active: boolean;
  sortName: string;
  status: string;
}
