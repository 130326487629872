import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { OnboardingService } from '@atlas-workspace/shared/service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription, take } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: '[atlOnboardingSelector]',
  standalone: true,
})
export class OnboardingSelectorDirective implements OnInit {
  @Input('atlOnboardingSelector') step!: number | string;
  protected stepSubscription?: Subscription;
  protected completeSubscription?: Subscription;
  protected readonly mobileViewSize = 744;

  constructor(
    private readonly onboardingService: OnboardingService,
    private readonly elementRef: ElementRef,
    private readonly render: Renderer2
  ) {}

  ngOnInit(): void {
    this.completeSubscribe();
    this.subscribeToMobileFirstLoad();
  }

  get isMobileView(): boolean {
    return this.mobileViewSize >= window.innerWidth;
  }

  private subscribeToMobileFirstLoad(): void {
    this.onboardingService.currentMobileStep$.pipe(untilDestroyed(this)).subscribe((step: number) => {
      if (step === +this.step) {
        if (this.isMobileView) {
          this.render.addClass(this.elementRef.nativeElement, 'client-highlighted__mobile');
        } else {
          this.render.addClass(this.elementRef.nativeElement, 'client-highlighted');
        }
      }
    })
  }

  private stepSubscribe(): void {
    this.stepSubscription = this.onboardingService.currentStep$.pipe(untilDestroyed(this)).subscribe((step) => {
      if (step === +this.step) {
        if (this.isMobileView) {
          this.render.addClass(this.elementRef.nativeElement, 'client-highlighted__mobile');
        } else {
          this.render.addClass(this.elementRef.nativeElement, 'client-highlighted');
        }
      } else {
        if (this.isMobileView) {
          this.render.removeClass(this.elementRef.nativeElement, 'client-highlighted__mobile');
        } else {
          this.render.removeClass(this.elementRef.nativeElement, 'client-highlighted');
        }
      }
    });
  }

  private completeSubscribe(): void {
    this.completeSubscription = this.onboardingService.completeOnboarding$
      .pipe(untilDestroyed(this))
      .subscribe((val) => {
        if (val) {
          this.render.removeClass(this.elementRef.nativeElement, 'client-highlighted');
          this.render.removeClass(this.elementRef.nativeElement, 'client-highlighted__mobile');
          this.stepSubscription?.unsubscribe();
        } else {
          this.stepSubscribe();
        }
      });
  }
}
