import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {NgbDropdownItem} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'atl-fdv-tree-menu',
  templateUrl: 'fdv-tree-menu.component.html',
  styleUrls: ['fdv-tree-menu.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgbDropdownItem,
  ]
})
export class FdvTreeMenuComponent  {
  @Input() readonly removeItem = true;
  @Input() readonly renameItem = true;
  @Input() readonly downloadItem = false;
  @Input() readonly moveItem = false;
  @Input() readonly uploadItem = true;
  @Input() readonly copyItem = false;
  @Input() readonly copyItemTitle = 'Entity.Document';
  @Input() folderMenu = false;
  @Output() readonly create = new EventEmitter();
  @Output() readonly uploadFile = new EventEmitter();
  @Output() readonly uploadFolder = new EventEmitter();
  @Output() readonly rename = new EventEmitter();
  @Output() readonly download = new EventEmitter();
  @Output() readonly delete = new EventEmitter();
  @Output() readonly copy = new EventEmitter();
  @Output() readonly move = new EventEmitter();
}
