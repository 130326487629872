<div 
  *ngIf="completed ? text : true" 
  class="free-text__wrapper" 
  [class.editable]="editable" 
  (click)="$event.stopPropagation()"
  >
  <div *ngIf="isString" class="free-text__value ellipsis" (click)="focusInput(); input.focus()">
    {{ input.value }}
    <span *ngIf="!input.value">{{ placeholder | translate }}</span>
  </div>
  <textarea
    [style.opacity]="isString ? 0 : 1"
    #input
    atlReplaceInput
    atlAutoresize
    atlCapitalLetter
    class="free-text__content v-scrollbar"
    [ngClass]="{
      active: !isString,
      progress: completed
    }"
    (focus)="focusInput()"
    (blur)="blurInput()"
    (input)="inputText(input.value)"
    [value]="text"
    [readonly]="completed"
  ></textarea>
  <div *ngIf="!completed && !previewMode" class="free-text__button" [ngClass]="{ 'two-line': input.offsetHeight > 40 }">
    <button [disabled]="isChange" class="newest-button" [ngClass]="{ active: !isString }" (click)="save()">
      {{ 'Button.Save' | translate }}
    </button>
  </div>
</div>
