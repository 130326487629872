<div class="global-label">
  <ng-select
    #ngSelect
    atlLockField
    [sweechLockOff]="sweechLockOff"
    [class.pointer-events-none]="locked"
    (isLocked)="isLocked($event, ngSelect)"
    [tabIndex]="tabIndex"
    class="drop-down-v2"
    [class.creation]="isActiveStaticCreation"
    [class.no-items-list]="noItemsList"
    [placeholder]="
      placeholder ? ((isOpen && focusedPlaceholder ? focusedPlaceholder : placeholder) | translate) : placeholder
    "
    [multiple]="true"
    [items]="listOfItems"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [editableSearchTerm]="editableSearchTerm"
    [disabled]="disabled"
    [dropdownPosition]="position"
    [ngModel]="inputValue"
    (change)="changeValue($event)"
    [searchable]="searchable"
    [clearable]="clearable"
    (clear)="onRemoveValue()"
    (search)="emitValueSearch.emit($event)"
    (input)="changeInput($event)"
    [searchFn]="searchFn"
    [groupBy]="groupBy"
    (close)="onSaveSearchByClose()"
    (open)="openDropDownHandler()"
    (focus)="initSearch()"
    [notFoundText]="notFoundText | translate"
    [loading]="loading"
    [closeOnSelect]="closeOnSelect"
    [trackByFn]="trackByFn"
    #dropDownInput
  >
    <ng-template ng-optgroup-tmp let-item="item">
      {{ item.name || ('Shared.Entity.Unnamed_group' | translate) }}
    </ng-template>

    <ng-container *ngIf="useCustomLabelView && !!labelTemplate; else defaultLabelViewTemplate">
      <ng-template ng-label-tmp let-item="item" let-index="index">
        <ng-container>
          <ng-template
            [ngTemplateOutlet]="labelTemplate"
            [ngTemplateOutletContext]="{ item: item, index: index }"
          ></ng-template>
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="useCustomItemView && !!itemTemplate; else defaultItemlViewTemplate">
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" let-item$="item$">
        <ng-container>
          <ng-template
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{ item: item, selected: item$.selected, index: index }"
          ></ng-template>
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-template #defaultLabelViewTemplate ng-label-tmp let-item="item">
      <div class="multiple-select-user-card-wrapper">
        <div>
          <span>{{ truncate ? (item && item[bindLabel] | truncate: truncate) : item && item[bindLabel] }}</span>
          <img
            class="multiple-select-user-card-remove"
            src="/assets/multiple_remove_icon.svg"
            alt="Remove icon"
            (click)="onRemoveSelectedItem(item)"
          />
        </div>
      </div>
    </ng-template>

    <ng-template #defaultItemlViewTemplate ng-option-tmp let-item="item">
      <div class="multiple-select-user-card-wrapper">
        <div>
          <span>{{ truncate ? (item && item[bindLabel] | truncate: truncate) : item && item[bindLabel] }}</span>
          <img
            class="multiple-select-user-card-remove"
            src="/assets/multiple_remove_icon.svg"
            alt="Remove icon"
            (click)="onRemoveSelectedItem(item)"
          />
        </div>
        <img
          *ngIf="removable && item?.id !== savedItemId"
          class="remove-dropdown-item"
          (click)="removeItem(item, $event)"
          src="/assets/trash-icon-sm.svg"
          alt="remove"
        />
        <img
          class="multiple-select-user-card-checkbox"
          src="/assets/multiple_select_checkbox.svg"
          alt="Remove selected icon"
        />
      </div>
    </ng-template>

    <ng-container *ngIf="notFoundText">
      <ng-template [ngIf]="(listOfItems || [])?.length && createNotFound">
        <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
          <div class="dropdown-v2-create ng-option" (click)="createUnfound.emit(searchTerm)">
            <span class="fw-400 fz-14 lh-24 br-4">{{ 'Shared.Button.Create' | translate }} </span>
            <button class="fw-400 fz-14 lh-24 br-4 ml-8">{{ searchTerm }}</button>
          </div>
        </ng-template>
        <ng-template [ngIf]="!createNotFound">
          <div class="ng-select__not-found ng-option disabled">
            <span>{{ notFoundText || 'Shared.Entity.Not_found' | translate }}</span>
          </div>
        </ng-template>
      </ng-template>
      <ng-container *ngIf="(searchTerm && creation) || (!searchTerm && !(listOfItems || [])?.length)">
        <div class="ng-select__not-found ng-option disabled">
          <span>{{ notFoundText || 'Shared.Entity.Not_found' | translate }}</span>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="creation">
      <ng-template ng-footer-tmp>
        <div class="drop-down-v2-footer">
          <div
            class="drop-down-v2-footer-label fw-400 br-4"
            [class.d-none]="isActiveStaticCreation"
            (click)="openCreation()"
          >
            <span class="fz-20 lh-16">+</span><span class="fz-14 lh-24 ml-8">{{ creationTitle | translate }}</span>
          </div>
          <div class="drop-down-v2-footer-control" [class.d-none]="!isActiveStaticCreation">
            <input
              #creationInput
              atlPreventDoubleSpace
              [maxLength]="maxLength"
              atlReplaceInput
              class="br-4 w-100 fz-14 lh-24"
              [class.invalid]="invalidCreationInput"
              type="text"
              [placeholder]="creationInputTitle | translate"
              [value]="savedValue"
              (input)="inputCreation($event)"
              (keydown.enter)="onCreation(creationInput.value, $event)"
            />
            <atl-custom-icon
              class="drop-down-v2-footer-actions accept"
              [icon]="acceptIcon"
              (click)="onCreation(creationInput.value)"
            ></atl-custom-icon>
            <img
              src="assets/dropdown_close_circle.svg"
              class="drop-down-v2-footer-actions reject"
              (click)="closeCreation()"
              alt="Close"
            />
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-select>
</div>
