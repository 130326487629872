import { Injectable } from '@angular/core';
import {
  EModelSettings,
  EThreadFolders,
  IFavoritesMenu,
  IMenu,
  ISettingsMenu,
  IThreadHeaderMenu,
} from '@atlas-workspace/shared/models';

import allIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/all_messages_icon.svg';
import arrowDownIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/arrow_down_small.svg';
import draftsIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/draft_icon_pen.svg';
import inspectionIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/inspections.svg';
import changeRecuestIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/left-menu-client/change-request.svg';
import contactIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/v3/nav-contacts-v3.svg';
import fdvIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/v3/nav-fdv-v3.svg';
import documentsIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/v3/nav-documents-v3.svg';
import homeIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/v3/nav-home-v3.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import meetingsIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/v3/nav-meetings-v3.svg';
import messagesIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/v3/nav-messages-v3.svg';
import optionsIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/v3/nav-options-v3.svg';
import practicalInfoIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/v3/nav-practical-info-v3.svg';
import reclamationsIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/v3/nav-reclamations-v3.svg';
import surveysIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/v3/nav-surveys-v3.svg';
import taskIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/v3/nav-tasks-v3.svg';
import updatesIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/v3/nav-updates-v3.svg';

@Injectable()
export class GlobalStorageService {
  leftMenu: IMenu[] = [
    { title: 'Title.Home_menu', icon: homeIcon, route: 'home/view', id: 'home' },
    { title: 'Title.Todo_list', icon: taskIcon, route: 'tasks/view', id: 'tasks', access: EModelSettings.PageTasks },
    {
      title: 'Entity.Options',
      icon: optionsIcon,
      route: 'options/view',
      id: 'options',
      access: EModelSettings.PageOptions,
    },
    {
      title: 'Title.Change_requests',
      icon: changeRecuestIcon,
      route: 'change-request/view',
      access: EModelSettings.PageChangeRequests,
      id: 'change_requests',
    },
    {
      title: 'Title.Messages',
      icon: messagesIcon,
      route: 'threads/view',
      id: 'messages',
      counter: 20,
      access: EModelSettings.PageMessages,
    },
    {
      title: 'Title.FDV',
      icon: fdvIcon,
      route: 'fdv/view',
      id: 'fdv',
      access: EModelSettings.PageFdv,
    },
    {
      title: 'Title.Documents',
      icon: documentsIcon,
      route: 'documents/view',
      id: 'documents',
      access: EModelSettings.PageDocuments,
    },
    { title: 'Title.Contacts', icon: contactIcon, route: 'contacts/view', id: 'contacts', access: EModelSettings.PageContacts },
    {
      title: 'Title.Reclamations',
      icon: reclamationsIcon,
      route: 'reclamations/view',
      id: 'reclamations',
      access: EModelSettings.PageReclamations,
    },
    {
      title: 'Title.Newsletter',
      icon: updatesIcon,
      route: 'update/view',
      id: 'updates',
      access: EModelSettings.PageUpdates,
    },
    {
      title: 'Title.Practical_info',
      icon: practicalInfoIcon,
      route: 'practical_info/view',
      id: 'practical-info',
      access: EModelSettings.PagePracticalInfo,
    },
    { title: 'Title.Inspection', icon: inspectionIcon, route: 'client/inspection', id: 'inspection', disableRedirect: true },
    { title: 'Title.Surveys', icon: surveysIcon, route: 'surveys/view/list', id: 'surveys', access: EModelSettings.PageSurveys },
    { title: 'Title.Meetings', icon: meetingsIcon, route: 'meetings/view', id: 'meetings', access: EModelSettings.PageMeetings },
    //TODO: Home Value (should be excluded for now);
    // { title: 'Entity.Home_value', icon: homeValueIcon, route: 'home-value/view', access: EModelSettings.PageHomeValue },
  ];

  favoriteProjects: IFavoritesMenu = {
    icon: arrowDownIcon,
    projects: [],
  };

  settingsMenu: ISettingsMenu[] = [
    { name: 'Entity.General', disabled: false, route: 'profile', id: 'cy-profile-settings-tab' },
    { name: 'Entity.Members', disabled: false, route: 'members', id: 'cy-members-settings-tab' },
    { name: 'Entity.Notification', disabled: false, route: 'notification', id: 'cy-notification-settings-tab' },
  ];

  threadsHeaderMenu: IThreadHeaderMenu[] = [
    { title: 'Shared.Title.All_messages', icon: allIcon, counter: 0, key: EThreadFolders.Inbox, route: 'inbox' },
    { title: 'Messages.Drafts', icon: draftsIcon, counter: 0, key: EThreadFolders.Drafts, route: 'drafts' },
  ];
}
