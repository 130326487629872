import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewContainerRef } from '@angular/core';
import { EMPTY, merge, Observable, Subscription } from 'rxjs';

import { DropdownPanel } from './dropdown-panel';

@Directive({
  selector: '[atlDropdownTriggerFor]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '(click)': 'toggleDropdown()',
  },
})
export class DropdownTriggerForDirective implements OnDestroy {
  private isDropdownOpen = false;
  private overlayRef!: OverlayRef;
  private dropdownClosingActionsSub = Subscription.EMPTY;

  @Input('atlDropdownTriggerFor') public dropdownPanel!: DropdownPanel;
  @Input() closeOnClick = true;
  @Input() offsetBottom = {
    offsetX: -16,
    offsetY: -8,
  };

  @Input() offsetTop = {
    offsetX: -16,
    offsetY: -49,
  };
  @Input() disabledDropdown = false;
  @Input() private readonly customPaneClass = false;
  @Output() isOpen = new EventEmitter<boolean>();

  constructor(
    private readonly overlay: Overlay,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly viewContainerRef: ViewContainerRef
  ) {}

  toggleDropdown(): void {
    this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
  }

  openDropdown(): void {
    if (this.disabledDropdown) {
      return;
    }
    const customWidth = 'custom-overlay-width';
    this.isDropdownOpen = true;
    this.isOpen.emit(this.isDropdownOpen);
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetX: this.offsetBottom.offsetX,
            offsetY: this.offsetBottom.offsetY,
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'bottom',
            offsetX: this.offsetTop.offsetX,
            offsetY: this.offsetTop.offsetY,
          },
        ]),
      panelClass: this.customPaneClass ? customWidth : '',
    });
    const templatePortal = new TemplatePortal(this.dropdownPanel?.templateRef, this.viewContainerRef);
    this.overlayRef.attach(templatePortal);

    this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(() => this.destroyDropdown());
  }

  private dropdownClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this.overlayRef.backdropClick();
    const detachment$ = this.overlayRef.detachments();
    const dropdownClosed = this.closeOnClick ? this.dropdownPanel.closed.asObservable() : EMPTY;
    return merge(backdropClick$, detachment$, dropdownClosed);
  }

  public destroyDropdown(): void {
    if (!this.overlayRef || !this.isDropdownOpen) {
      return;
    }

    this.dropdownClosingActionsSub.unsubscribe();
    this.isDropdownOpen = false;
    this.isOpen.emit(this.isDropdownOpen);
    this.overlayRef.detach();
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
}
