<!-- 
  ******************************************************
  @Cypress
  - interaction with footer buttons
  ******************************************************
-->
<div class="mark-modal__wrapper">
  <div class="mark-modal__header">
    <div class="mark-modal__header-header">
      <div class="mark-modal__header-title">
        {{ (oneMarkMode ? titleMarkReclamation : titleNewReclamation) | translate }} {{ '' }}
      </div>
      <atl-header-icon
        icon="/assets/close-icon.svg"
        (click)="closeModal()"
        [ngbTooltip]="'Alt.Close' | translate"
        [openDelay]="tooltipDelay"
        [class.client]="client"
      ></atl-header-icon>
    </div>
    <div *ngIf="floorPlan?.length > 1" class="mark-modal__header-action">
      <atl-simple-tabs 
        [class.client-tabs]="client"
        [withId]="true"
        [activeId]="activeId"
        [tabs]="navList"
        [routeNavigation]="false"
        (selectingTab)="selectTab($event)"
      ></atl-simple-tabs>
    </div>
  </div>
  <div class="mark-modal__content" [class.floors]="floorPlan?.length > 1" [class.client]="(disabled || multiplePins) && client">
    <ng-container *ngIf="floorPlan?.length; else unitSelect">
      <ng-container *ngTemplateOutlet="floorPlanItem"></ng-container>
    </ng-container>
  </div>

  <div class="mark-modal__footer" *ngIf="!disabled && !multiplePins">
    <button (click)="closeModal()" class="newest-button button-gray mr-16">
      {{ 'Shared.Button.Cancel' | translate }}
    </button>
    <button *ngIf="oneMarkMode" (click)="addTags()" class="global-button newest-button" data-cy="plan-mark-modal-save-tags">
      {{ 'Shared.Button.Save_tags' | translate }}
    </button>
    <button *ngIf="!oneMarkMode" (click)="addTags()" class="global-button newest-button">
      {{ 'Shared.Reclamation.Button.Create' | translate }}
    </button>
  </div>
  <div class="mark-modal__footer" *ngIf="(disabled || multiplePins) && !client">
    <button (click)="closeModal()" class="newest-button button-gray fz-16 br-32 h-40" data-cy="plan-mark-modal-done">
      {{ 'Shared.Button.Done' | translate }}
    </button>
  </div>
</div>

<ng-template #floorPlanItem>
  <ng-container [ngSwitch]="activeId">
    <ng-container *ngFor="let plan of floorPlan; index as i; trackBy: floorTrackBy">
      <ng-container *ngSwitchCase="plan.id">
        <ng-container *ngIf="plan.plan; else noFloorPlan">
          <div class="mark-modal__image">
            <atl-image-mark
              (existingMarkClick)="existingMarkClick($event, plan)"
              [mark]="mark | floorMark: plan.id"
              [imageUrl]="plan.plan?.fullImage.url"
              [floorId]="plan.id"
              [floorNumber]="plan.floorNumber"
              [floorType]="plan.floorType"
              [multiplePins]="!oneMarkMode"
              [editor]="oneMarkMode"
              [disableDrag]="disableDrag"
              (setMark)="setMark($event)"
              class="v-scrollbar h-scrollbar"
            ></atl-image-mark>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #unitSelect>
  <div class="mark-modal__select-unit">
    <img src="/assets/reclamation-unit-empty.svg" alt="icon" />
    <h4 class="mt-16">{{ 'Shared.Title.No_unit_selected' | translate }}</h4>
    <p class="mt-16">{{ 'Shared.Description.Floor_plan_select' | translate }}</p>
    <div class="mt-16">
      <atl-newest-drop-down
        [searchable]="false"
        [listOfItems]="units"
        [bindLabel]="'identifier'"
        [bindValue]="'id'"
        [placeholder]="'Shared.Placeholder.Select_unit' | translate"
        (changeValueHandler)="selectUnit($event)"
      ></atl-newest-drop-down>
    </div>
  </div>
</ng-template>

<ng-template #noFloorPlan>
  <div class="mark-modal__image flex-column">
    <h4>{{ titleMarkReclamation | translate }}</h4>
    <p *ngIf="client || !hasUnitAccess; else adminText">{{ 'Shared.Mark.Message.Floor_plan_empty' | translate }}</p>
    <ng-template #adminText>
      <p>
        {{ 'Shared.Mark.Message.No_floor_plan' | translate }}
        <span (click)="unitRedirect()">{{ 'Shared.Mark.Message.Unit_details_page' | translate }}</span>
      </p>
    </ng-template>
  </div>
</ng-template>
