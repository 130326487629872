import { ElementRef, Injectable } from '@angular/core';
import {
  CableChannelRemoveThreadEventModel,
  EThreadFolders,
  EThreadUnitSelectionType,
  FileModel,
  imageJpegMime,
  ImageModel,
  imagePngMime,
  IThreadExtraData,
  IThreadFilterModel,
  IThreadInboxSubFoldersFilter,
  IThreadProject,
  IThreadViewShortModel,
  ThreadDraftModel,
  ThreadDraftUnitModel,
  ThreadFoldersCounterType,
  ThreadMentionUserModel,
  ThreadModel,
  ThreadUnitsWithGroupsModel,
  ThreadUserModel,
  ThreadViewCounters,
  ThreadViewModel,
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Editor } from 'tinymce';

@Injectable({
  providedIn: 'root',
})
export class ThreadsHelperService {
  private _search = new BehaviorSubject<string>('');
  private _counters = new Subject<ThreadFoldersCounterType>();
  private _savedCounters!: ThreadFoldersCounterType;
  private _reclamationFiltersCounters = new Subject<ThreadViewCounters>();
  private _threadToRead = new Subject<number>();
  private _updateList$ = new Subject<ThreadDraftModel | ThreadModel>();
  private _singleFilter = new Subject<IThreadInboxSubFoldersFilter>();
  private _customFilter = new BehaviorSubject<IThreadFilterModel>({});
  private _threadExtraData = new BehaviorSubject<IThreadExtraData>({});
  public isThreadsAccrossFirm = true;
  public readonly nonBreakingSpaceEntity = '&nbsp;';
  public readonly zeroWidthNoBreakSpaceSymbol = '\uFEFF';
  private _setUnreadThread$ = new Subject<boolean>();

  private _customThreadId = new BehaviorSubject<string>(EThreadFolders.All);

  public get customThreadId$(): Observable<string> {
    return this._customThreadId.asObservable();
  }

  public setCustomThreadId(val: string): void {
    this._customThreadId.next(val);
  }

  public get reclamationFiltersCounters$(): Observable<ThreadViewCounters> {
    return this._reclamationFiltersCounters.asObservable();
  }

  public setReclamationFiltersCounters(val: ThreadViewCounters): void {
    this._reclamationFiltersCounters.next(val);
  }

  public get updateList$(): Subject<ThreadDraftModel | ThreadModel> {
    return this._updateList$;
  }

  public getSearch(): Observable<string> {
    return this._search.asObservable();
  }

  public setSearch(val: string): void {
    this._search.next(val);
  }

  public getSingleFilter(): Observable<IThreadInboxSubFoldersFilter> {
    return this._singleFilter.asObservable();
  }

  public setSingleFilter(val: IThreadInboxSubFoldersFilter): void {
    this._singleFilter.next(val);
  }

  public getCustomFilter(): Observable<IThreadFilterModel> {
    return this._customFilter.asObservable();
  }

  public setCustomFilter(val: IThreadFilterModel): void {
    this._customFilter.next(val);
  }

  public get getExtraData(): Observable<IThreadExtraData> {
    return this._threadExtraData.asObservable();
  }

  public setExtraData(val: IThreadExtraData): void {
    this._threadExtraData.next(val);
  }

  public removeWhitespaces(htmlString: string): string {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html').body;
      let correctFormatting = false;
      do {
        const lastChild = doc.lastChild;
        if (lastChild) {
          const contextWithoutWhitespaces = (lastChild.textContent || '').replace(/\s/g, '');
          if (!contextWithoutWhitespaces.length) {
            doc.removeChild(lastChild);
          } else {
            let element = doc.children[doc.children.length - 1];
            while (element.lastElementChild) {
              element = element.lastElementChild;
            }
            element.textContent = (element.textContent || '').trim();
            correctFormatting = true;
          }
        } else {
          correctFormatting = true;
        }
      } while (!correctFormatting);
      return doc.innerHTML;
    } catch (error) {
      return '';
    }
  }

  public removeEditorBookmarksFromMessage(html: string): string {
    const domParser = new DOMParser();
    const doc = domParser.parseFromString(html, 'text/html');
    const bookmarks: NodeListOf<HTMLSpanElement> = doc.querySelectorAll(`[id^="mce_"]`);
    bookmarks.forEach((span) => {
      span.outerHTML = '';
    });

    if (doc.body.children.length > 1) {
      const p = doc.createElement('p');
      for (let i = 0; i < doc.body.children.length; i++) {
        p.appendChild(doc.body.children[i].cloneNode(true));
      }
      doc.body.innerHTML = '';
      doc.body.appendChild(p);
      return doc.body.innerHTML || html;
    }
    return doc.body.firstElementChild?.outerHTML || html;
  }

  public replaceMentionSegmentToElement(
    html: string,
    segment: string,
    bookmarkId: string,
    uid: number,
    label: string
  ): string {
    const searchable = `${segment}<span data-mce-type="bookmark" id="${bookmarkId}_start"`;
    let i = html.lastIndexOf(searchable);
    if (i === -1) {
      i = html.indexOf(`${segment}${this.nonBreakingSpaceEntity}<code`);
    }

    if (i > html.length - 1) return html;
    // eslint-disable-next-line max-len
    const snippet = `<code contenteditable="false" style="font-family: 'SF Pro', 'Inter', serif !important;" data-uid="${uid}">&#64;${label}</code><span>&nbsp;</span><span id="${bookmarkId}"></span>`;
    return html.substring(0, i) + snippet + html.substring(i + segment.length);
  }

  public detectTaggedUsers(html: string): number[] {
    if (!html) {
      return [];
    }
    const domParser = new DOMParser();
    const doc = domParser.parseFromString(html, 'text/html');
    const taggedElements: NodeListOf<HTMLSpanElement> = doc.querySelectorAll('[data-uid]');
    const taggedIds: number[] = [];
    taggedElements.forEach((span) => {
      const id = Number(span.getAttribute('data-uid'));
      if (id && !taggedIds.includes(id)) {
        taggedIds.push(id);
      }
    });
    return taggedIds;
  }

  public getThreadStateReadId(): Observable<number> {
    return this._threadToRead.asObservable();
  }

  public setThreadStateReadId(id: number): void {
    this._threadToRead.next(id);
  }

  public threadCustomModelToThreadShortModel<
    T extends ThreadViewModel | CableChannelRemoveThreadEventModel = ThreadViewModel
  >(model: T): IThreadViewShortModel {
    const isRemoveModel = model instanceof CableChannelRemoveThreadEventModel;
    const shortModel: IThreadViewShortModel = {
      id: isRemoveModel ? (<CableChannelRemoveThreadEventModel>model).messageThread.id : (<ThreadViewModel>model).id,
      project: {
        id: isRemoveModel
          ? (<CableChannelRemoveThreadEventModel>model).messageThread.projectId
          : (<ThreadViewModel>model).projectId,
      } as IThreadProject,
      state: model.state,
      removed: isRemoveModel ? true : undefined,
    };

    return shortModel;
  }

  public isImage(file: Partial<File | FileModel | ImageModel>): boolean {
    if (file.type) {
      return file.type === imageJpegMime || file.type === imagePngMime || file.type === 'image';
    }
    return false;
  }

  public isPrintableKeyEvent(e: KeyboardEvent): boolean {
    return [
      32, // Spacebar
      ...Array.from({ length: 12 }, (_, i) => i + 48), // keys 0-9
      ...Array.from({ length: 26 }, (_, i) => i + 65), // keys A-Z
    ].includes(e.keyCode);
  }

  public formatMentions(
    html: string,
    users: ThreadUserModel[] | undefined,
    taggingParams: Record<string, any>
  ): ThreadMentionUserModel[] {
    const taggedUsersIds = this.detectTaggedUsers(html);
    return !users
      ? []
      : users
          .filter((user) => taggedUsersIds.includes(user.id))
          .map((i) => plainToClass(ThreadMentionUserModel, i))
          .map((m) => {
            m.identifier = taggingParams.KEY + m.identifier;
            return m;
          });
  }

  public threadDraftUnitModelToThreadUnitsWithGroupsModel(
    draftUnits: ThreadDraftUnitModel[]
  ): ThreadUnitsWithGroupsModel[] {
    return draftUnits.map(({ id, identifier, selected }) => {
      return <ThreadUnitsWithGroupsModel>{ id, identifier, type: EThreadUnitSelectionType.Unit, selected };
    });
  }

  public fixAutofocusOnZeroWidthNoBreakSpaceSymbol(editor: Editor): void {
    const selData = editor.selection.getSel()!;
    const focusNode = selData.focusNode!;
    let focusNodeContent = '';
    if (focusNode && focusNode.nodeType === Node.TEXT_NODE) {
      focusNodeContent = (focusNode as Text).data;
    }
    if (focusNodeContent !== this.zeroWidthNoBreakSpaceSymbol) {
      return;
    }
    const previousMentionSiblingNode = focusNode.previousSibling?.previousSibling;
    if (typeof (previousMentionSiblingNode as Text).data === 'string') {
      editor.selection.setCursorLocation(previousMentionSiblingNode!, (previousMentionSiblingNode as Text).data.length);
    }
  }

  public preventDefaultButtonsTitlesReflection(ref: ElementRef<HTMLElement | undefined>): void {
    if (ref.nativeElement) {
      ref.nativeElement.querySelectorAll('.tox-tbtn').forEach((button) => {
        if (button.hasAttribute('title')) button.setAttribute('title', '');
      });
    }
  }

  public triggerUnreadThreadEvent(val: boolean): void {
    this._setUnreadThread$.next(val);
  }

  public get unreadThreadEvent(): Observable<boolean> {
    return this._setUnreadThread$.asObservable();
  }
}
