import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';
import { debounceTime } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'atl-filter--dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
})
export class FilterDropdownComponent implements OnInit, OnChanges {
  @Input() set _items(data: any[]) {
    this.items = data;

    if (this.hasDifferentModels && this.secondComparisonKey) {
      this.selectedItemIds = this.selectedItemRecords.map(({ id }) => id);
      this.filteredItems = cloneDeep(
        this.items.map((item) => ({
          ...item,
          checked: !!this.selectedItemRecords.find(
            (rec) =>
              rec.id === item.id &&
              rec.type === item.type &&
              rec[this.secondComparisonKey!] === item[this.secondComparisonKey!]
          ),
        }))
      );
    } else {
      this.filteredItems = cloneDeep(
        this.items.map((item) => ({
          ...item,
          checked: this.selectedItemIds.includes(this.filterById ? item.id : item.name),
        }))
      );
    }
  }
  @Input() disabled = false;
  @Input() readonly buttonText = 'Entity.Filters';
  @Input() readonly key = 'name';
  @Input() readonly cyKey?: string;
  @Input() readonly filterById = true;
  @Input() readonly reset!: boolean;
  @Input() readonly showUserIcon = false;
  @Input() readonly showUserIconForProp?: string;
  @Input() readonly showUserIconWhenPropEq?: any;
  @Input() readonly showSubtitle = false;
  @Input() readonly showSubtitleForProp?: string;
  @Input() readonly showCategory = false;
  @Input() readonly showCounter = false;
  @Input() readonly showCustomField = false;
  @Input() readonly customField = '';
  @Input() readonly isStatusFilter = false;
  @Input() readonly isResponsibleFilter = false;
  @Input() readonly showSearch = true;
  @Input() readonly apiSearch = false;
  @Input() readonly hasDifferentModels = false;
  @Input() readonly secondComparisonKey?: string;
  @Input() selectedItemRecords: Record<string, any>[] = [];
  @Input() selectedItemIds: (number | string)[] = [];

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() private readonly onSelectedItems = new EventEmitter<Record<string, any>>();
  @Output() private readonly selectedIds = new EventEmitter<(number | string)[]>();
  @Output() private readonly nextPageEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() private readonly searchEvent: EventEmitter<string> = new EventEmitter<string>();

  public items: any[] = [];
  public readonly dotDivider = ' · ';

  constructor(private readonly fb: FormBuilder) {}

  public form!: FormGroup;
  public filteredItems: any[] = [];
  public searchQuery = '';
  public searchDelay = 500;

  public isDropdownOpen = false;

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reset?.currentValue) {
      this.resetFilters(false);
    }
  }

  askNextPage(last: boolean): void {
    this.nextPageEvent.emit(last);
  }

  clearSearch(): void {
    this.resetSearch();
  }

  openChange(v: boolean): void {
    this.isDropdownOpen = v;
    if (!v) {
      this.resetSearch();
    }

    this.restoreSelectedItems();
  }

  toggleItem<T extends { id?: number | string; name?: string }>(item: T): void {
    const typedItem = item as T & { checked: boolean };
    typedItem.checked = !typedItem.checked;

    const idOrName = this.filterById ? typedItem.id : typedItem.name;
    if (typedItem.checked) {
      if (idOrName) {
        this.selectedItemIds.push(idOrName as number);
      }
    } else {
      if (idOrName) {
        this.selectedItemIds = this.selectedItemIds.filter((idOrNameInArray) => idOrNameInArray !== idOrName);
      }
    }
    this.selectedIds.emit(this.selectedItemIds);
    if (this.hasDifferentModels) {
      this.selectedItemRecords = this.filteredItems.filter((itm) => itm.checked);
      this.onSelectedItems.emit(this.selectedItemRecords);
    }
  }

  resetFilters(emit = true): void {
    this.filteredItems.forEach((item) => {
      item.checked = false;
    });
    this.selectedItemIds = [];
    this.selectedItemRecords = [];
    if (emit) {
      this.selectedIds.emit(this.selectedItemIds);
    }
    if (emit && this.hasDifferentModels) {
      this.onSelectedItems.emit(this.selectedItemRecords);
    }
  }

  private initForm(): void {
    this.form = this.fb.group({ search: new FormControl('') });
    this.form
      .get('search')
      ?.valueChanges.pipe(untilDestroyed(this), debounceTime(this.searchDelay))
      .subscribe((searchValue) => {
        this.searchQuery = searchValue;
        if (this.apiSearch) {
          this.searchEvent.emit(this.searchQuery);
          return;
        }
        const checkingFn = (el: any) => {
          if (this.hasDifferentModels && this.secondComparisonKey) {
            return !!this.selectedItemRecords.find(
              (rec) =>
                rec.id === el.id &&
                rec.type === el.type &&
                rec[this.secondComparisonKey!] === el[this.secondComparisonKey!]
            );
          } else {
            return this.selectedItemIds.includes(this.filterById ? el.id : el.name);
          }
        };
        this.filteredItems = this.items
          .map((item) => ({
            ...item,
            checked: checkingFn(item),
          }))
          .filter((item) => {
            const itemValue = item[this.key];
            if (this.hasDifferentModels && this.secondComparisonKey) {
              const secondItemValue = item[this.secondComparisonKey];
              return (
                itemValue.toLowerCase().includes(searchValue.toLowerCase()) ||
                (secondItemValue && secondItemValue.toLowerCase().includes(searchValue.toLowerCase()))
              );
            }
            return itemValue.toLowerCase().includes(searchValue.toLowerCase());
          });
      });
  }

  private resetSearch(): void {
    this.form.controls.search.setValue('', { emitEvent: false });
    this.searchQuery = '';

    if (this.apiSearch) {
      this.searchEvent.emit(this.searchQuery);
      return;
    }

    this.filteredItems = cloneDeep(this.items);
    this.restoreSelectedItems();
  }

  private restoreSelectedItems(): void {
    if (this.hasDifferentModels && this.secondComparisonKey) {
      this.filteredItems.forEach((item) => {
        item.checked = !!this.selectedItemRecords.find(
          (rec) =>
            rec.id === item.id &&
            rec.type === item.type &&
            rec[this.secondComparisonKey!] === item[this.secondComparisonKey!]
        );
      });
    } else {
      this.filteredItems.forEach((item) => {
        item.checked = this.selectedItemIds.includes(this.filterById ? item.id : item.name);
      });
    }
  }
}
