<div class="newest-document-card__wrapper" [class.has-active-options]="isOptionsOpened">
  <div class="newest-document-card__info">
    <div>
      <ng-container *ngIf="isImage; else documentView">
        <img
          class="newest-document-card__image-container"
          [src]="image?.fileName.w260 ? image?.fileName.w260.url : image?.fileName.url"
          alt="image"
        />
      </ng-container>
      <ng-template #documentView>
        <img class="newest-document-card__extension" [src]="fileType | iconByType" alt="icon" />
      </ng-template>
    </div>

    <div class="newest-document-card__file-wrapper">
      <div
        #fileTitle
        [ngbTooltip]="fileName"
        [disableTooltip]="fileTitle.offsetWidth >= fileTitle.scrollWidth"
        container="body"
        class="newest-document-card__file-name ellipsis w-100"
        [openDelay]="tooltipDelay"
      >
        {{ fileName }}
      </div>

      <div *ngIf="fileSize" class="fz-12 newest-document-card__file-size">{{ fileSize | filesize }}</div>

      <div *ngIf="sharedText" class="fz-12 newest-document-card__shared-text">{{ sharedText }}</div>
    </div>
  </div>

  <div
    *ngIf="hasRemove"
    class="newest-document-card__icon-control newest-document-card__icon-control-trash"
    (click)="onRemoveFile(); $event.stopImmediatePropagation()"
  >
    <atl-custom-icon [icon]="trashIcon"></atl-custom-icon>
  </div>

  <ng-container *ngIf="useDropdownOptions && isImage; then dropdownOptions; else defaultLink"></ng-container>

  <ng-template #defaultLink>
    <a
      tabindex="-1"
      *ngIf="hasDownload"
      [href]="downloadLink"
      download=""
      class="newest-document-card__icon-control newest-document-card__icon-control-download"
      (click)="$event.stopImmediatePropagation()"
      [ngbTooltip]="'Shared.Entity.Download' | translate"
      [openDelay]="tooltipDelay"
    >
      <atl-custom-icon [icon]="downloadIcon"></atl-custom-icon>
    </a>
  </ng-template>

  <ng-template #dropdownOptions>
    <div
      class="newest-document-card__icon-control newest-document-card__icon-control-download newest-document-card__options-btn"
      (click)="$event.stopImmediatePropagation(); setOptionsVisibility()"
    >
      <atl-custom-icon [icon]="dotsIcon" [size]="16"></atl-custom-icon>
    </div>
    <div class="newest-document-card__options" [class.above-btn]="isOptionsAboveBtn">
      <div
        *ngIf="isOptionsOpened"
        class="newest-document-card__options-panel d-flex flex-column"
        atlClickOutside
        (clickOutside)="setOptionsVisibility(false)"
      >
        <!-- Download -->
        <a
          *ngIf="hasDownload"
          [href]="downloadLink"
          download=""
          class="newest-document-card__options-item"
          (click)="$event.stopImmediatePropagation();setOptionsVisibility(false)"
        >
          <img class="" src="/assets/download-16x16.svg" alt="Copy icon" />
          <span>{{ 'Shared.Entity.Download' | translate }}</span>
        </a>
        <!-- Copy image -->
        <div class="newest-document-card__options-item" (click)="$event.stopImmediatePropagation(); copyImage()">
          <img class="" src="/assets/copy-link.svg" alt="Copy icon" />
          <span>{{ 'Shared.Description.Copy_image' | translate }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</div>
