export const stepsOnboarding = [
  {
    icon: '/assets/logo-icon.svg',
    title: 'Title.Welcome_to_journey',
    description: 'Onboarding.Start.Description',
    windowClass: 'client-onboarding-modal',
    backdropClass: 'onboarding-backdrop',
  },
  {
    icon: '/assets/onboarding-menu.svg',
    title: 'Title.Menu',
    description: 'Onboarding.One.Description',
    windowClass: 'client-onboarding-modal step-one',
    backdropClass: 'onboarding-backdrop',
  },
  {
    icon: '/assets/onboarding-apps.svg',
    title: 'Title.Mobile_app',
    description: 'Onboarding.Two.Description',
    windowClass: 'client-onboarding-modal step-two',
    backdropClass: 'onboarding-backdrop',
  },
  {
    icon: '/assets/onboarding-home.svg',
    title: 'Title.Units',
    description: 'Onboarding.Three.Description',
    windowClass: 'client-onboarding-modal step-three',
    backdropClass: 'onboarding-backdrop',
  },
  // TODO: missing icon in header
  // {
  //   icon: '/assets/onboarding-helper.svg',
  //   title: 'Title.Help_center',
  //   description: 'Onboarding.Four.Description',
  //   windowClass: 'client-onboarding-modal step-four',
  //   backdropClass: 'onboarding-backdrop'
  // },
  {
    icon: '/assets/onboarding-profile.svg',
    title: 'Title.Profile_settings',
    description: 'Onboarding.Five.Description',
    windowClass: 'client-onboarding-modal step-five',
    backdropClass: 'onboarding-backdrop',
  },
];

export const stepsOptionsOnboarding = [
  {
    icon: '/assets/options-onboarding-start.svg',
    title: 'Onboarding.Options.Start.Title',
    description: 'Onboarding.Options.Start.Description',
    windowClass: 'client-options-onboarding-modal',
    backdropClass: 'onboarding-backdrop',
  },
  {
    icon: '/assets/options-onboarding-step-1_4.svg',
    title: 'Onboarding.Options.One.Title',
    description: 'Onboarding.Options.One.Description',
    windowClass: 'client-options-onboarding-modal step-one',
    backdropClass: 'onboarding-backdrop',
  },
  {
    icon: '/assets/options-onboarding-step-2.svg',
    title: 'Onboarding.Options.Two.Title',
    description: 'Onboarding.Options.Two.Description',
    windowClass: 'client-options-onboarding-modal step-two',
    backdropClass: 'onboarding-backdrop',
  },
  {
    icon: '/assets/options-onboarding-step-3.svg',
    title: 'Onboarding.Options.Three.Title',
    description: 'Onboarding.Options.Three.Description',
    windowClass: 'client-options-onboarding-modal step-three',
    backdropClass: 'onboarding-backdrop',
  },
  {
    icon: '/assets/options-onboarding-step-1_4.svg',
    title: 'Onboarding.Options.Four.Title',
    description: 'Onboarding.Options.Four.Description',
    windowClass: 'client-options-onboarding-modal step-four',
    backdropClass: 'onboarding-backdrop',
  },
  {
    icon: '/assets/options-onboarding-step-5.svg',
    title: 'Onboarding.Options.Five.Title',
    description: 'Onboarding.Options.Five.Description',
    windowClass: 'client-options-onboarding-modal step-five',
    backdropClass: 'onboarding-backdrop',
  },
  {
    icon: '/assets/options-onboarding-step-6.svg',
    title: 'Onboarding.Options.Six.Title',
    description: 'Onboarding.Options.Six.Description',
    windowClass: 'client-options-onboarding-modal step-six',
    backdropClass: 'onboarding-backdrop',
  },
];
