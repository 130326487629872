export const sharedProdEnvironment = {
  production: true,
  appVersion: '0.1',
  apiBaseUrl: 'https://api.journeyapp.tech/',
  apiShowroomUrl: 'https://digital-showroom-api.dev.scrij.com/',
  environment: 'prod',
  tinyMceApiKey: 's25r594h1db8p0axzzvzowc7mjsl3fptfrv7bn0avvle8lyh',
  threadsWssUrl: 'wss://anycable.journeyapp.tech',
  googleApiKey: 'AIzaSyAyHQYUAe3Q3dq5giAaaQeGrr3XiXHiVHo',
  accessCode: 'F8341366D62E509243E0970EDD961D5572B22D3FE7C68F290308FFDDC9A197F5',
  analyticsIdClient: 'G-ZNQNJN7GQH',
  analyticsIdAdmin: 'G-7RWQXX9WXP',
  mixpanelToken: 'e7afadc6a6008033872865ac276b91d5',
  apiClientUrl: 'https://client.journeyapp.tech/',
};
