import { Pipe, PipeTransform } from '@angular/core';
import { ELang } from '@atlas-workspace/shared/models';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import nb from 'dayjs/locale/nb';
import sv from 'dayjs/locale/sv';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';

dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(localeData);
dayjs.extend(utc);

@Pipe({
  name: 'messagesIntervalDateDivider',
  standalone: true,
})
export class ThreadMessagesDividerByDatePipe implements PipeTransform {
  public readonly unixToUtcHook = 1000;
  public readonly dayjsLocales = new Map([
    [ELang.English, en],
    [ELang.Norwegian, nb],
    [ELang.Swedish, sv],
  ]);

  constructor(private trs: TranslateService) {}

  transform(value: number, interval: number = 6e4): Observable<string> {
    if (!value) return of('');

    dayjs.locale(this.dayjsLocales.get(<ELang>this.trs.currentLang));

    const transformedDate = dayjs(value * this.unixToUtcHook);

    return timer(0, interval).pipe(
      map(() => {
        const now = dayjs();

        const _isToday = transformedDate.isToday();
        const _isYesterday = transformedDate.isYesterday();
        const isLast7Days = now.diff(transformedDate, 'day') <= 7;

        if (_isToday) {
          return this.trs.instant('Shared.Date.Today');
        } else if (_isYesterday) {
          return this.trs.instant('Shared.Date.Yesterday');
        } else if (isLast7Days) {
          return transformedDate.format('dddd');
        } else {
          return transformedDate.format('DD.MM.YYYY');
        }
      })
    );
  }
}
