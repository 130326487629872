<!--
  ******************************************************
  @Cypress
  - interaction with toogle dropdown menu
  - interaction with search (e.g. fill, clear)
  - interaction with dropdown items (e.g. click, check)
  - interaction with Clear All button
  ******************************************************
-->
<atl-default-dropdown>
  <div #dropdownRef="ngbDropdown" ngbDropdown [autoClose]="'outside'" (openChange)="openChange($event)">
    <button
      class="newest-button newest-button--gray filter-button"
      id="dropdownButton"
      ngbDropdownToggle
      [class.filter-button__active]="isDropdownOpen || selectedItemIds.length"
      [disabled]="disabled"
    >
      <span class="mr-8 fz-14">{{ buttonText }}</span>
      <span
        *ngIf="selectedItemIds?.length && items?.length"
        class="counter fz-14 d-flex align-items-center justify-content-center"
        [class.counter__extended]="selectedItemIds.length > 9"
        >+{{ selectedItemIds.length }}</span
      >
      <img [class.rotated]="isDropdownOpen" src="/assets/caret-down.svg" alt="Icon" />
    </button>

    <div class="dropdown-menu" aria-labelledby="dropdownButton" ngbDropdownMenu>
      <p class="fz-14 lh-24 text-black-810 label">{{ buttonText }}</p>
      <ng-container *ngIf="showSearch">
        <form class="search-form" [formGroup]="form">
          <div class="position-relative d-inline-block">
            <img src="/assets/search.svg" alt="icon" class="search-icon" />
            <input
              class="search-input"
              formControlName="search"
              [placeholder]="'Input.Search.Placeholder_without_dots' | translate"
            />
          </div>

          <img
            (click)="clearSearch()"
            *ngIf="form.controls.search.value.length"
            class="clear-search"
            src="/assets/newest_remove_text_gray.svg"
            alt="icon"
          />
        </form>
      </ng-container>
      <div class="dropdown-content v-scrollbar" [class.dropdown-content-empty]="!filteredItems?.length && !searchQuery">
        <div
          *ngFor="let item of filteredItems; let last = last"
          class="dropdown-item d-flex align-items-center cursor-pointer fw-400"
          [attr.data-cy-id]="cyKey ? item[cyKey] : null"
          [class.has-subtitle]="showSubtitle && item[showSubtitleForProp]"
          (click)="toggleItem(item)"
          (atlInfiniteScroll)="askNextPage(last)"
        >
          <atl-titled-checkbox
            class="ml-8 mr-8"
            [value]="item.checked"
            (changeEvent)="toggleItem(item)"
            (click)="$event.stopPropagation()"
          ></atl-titled-checkbox>
          <atl-firm-icon
            *ngIf="
              (showUserIcon && item.id !== 'none' && !showUserIconForProp) ||
              (showUserIcon && showUserIconForProp && item[showUserIconForProp] === showUserIconWhenPropEq)
            "
            class="mr-8"
            [model]="item"
            [isUser]="true"
            [nameKey]="'name'"
          ></atl-firm-icon>
          <ng-template [ngIf]="item.id !== 'none' && showSubtitle && showSubtitleForProp" [ngIfElse]="baseItemRef">
            <label class="ellipsis w-100 cursor-pointer fz-14 lh-24">
              <div class="d-flex">
                <span class="ellipsis">{{ item[key] | replaceunderscore | upperCaseFirst }}</span>
                <span *ngIf="showCategory && item.id !== 'none'" class="ml-4 text-black-810"
                  >({{ item.category?.name }})</span
                >
                <span *ngIf="showCounter && item.id !== 'none'" class="ml-4 text-black-810">({{ item.count }})</span>
                <span *ngIf="showCustomField" class="text-black-810">{{ dotDivider + ' ' + item[customField] }}</span>
              </div>
              <div class="dropdown-item-subtitle fz-12 lh-16 fw-400 ellipsis">{{ item[showSubtitleForProp] }}</div>
            </label>
          </ng-template>
          <ng-template #baseItemRef>
            <label class="ellipsis w-100 cursor-pointer fz-14 lh-24"
              >
              <span class="ellipsis">{{ item[key] | replaceunderscore | upperCaseFirst }}</span>
              <span *ngIf="showCategory && item.id !== 'none'" class="ml-4 text-black-810"
                >({{ item.category?.name }})</span
              >
              <span *ngIf="showCounter && item.id !== 'none'" class="ml-4 text-black-810">({{ item.count }})</span>
              <span *ngIf="showCustomField" class="text-black-810">{{ dotDivider + ' ' + item[customField] }}</span>
            </label>
          </ng-template>
        </div>
      </div>

      <div class="dropdown-footer d-flex align-items-center" *ngIf="filteredItems.length">
        <p (click)="resetFilters()" class="cursor-pointer fz-14 lh-24 reset-button">
          {{ 'Shared.Entity.Clear_all' | translate }}
        </p>
      </div>

      <div *ngIf="searchQuery && !filteredItems.length" class="mt-8 mb-8 ml-16">
        <span class="fz-14 lh-24"> {{ 'Entity.No_matches_for' | translate }} "{{ searchQuery | truncate: 10 }}" </span>
      </div>
      <div *ngIf="!searchQuery && !filteredItems.length" class="mt-8 mb-8 ml-16">
        <span class="fz-14 lh-24 text-black-810"> {{ 'Shared.Title.Nothing_is_found' | translate }}</span>
      </div>
    </div>
  </div>
</atl-default-dropdown>
