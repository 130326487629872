import dayjs from 'dayjs';

import { IBootstrapDate } from '../models/time-line.model';

export function transformDate(date: IBootstrapDate): Date {
  return new Date(date.year, date.month - 1, date.day);
}

export function transformDateForInput(date: string | Date): { day: string; month: string; year: string } {
  const dateArr = dayjs(date).format('DD/MM/YYYY').split('/');
  return { day: dateArr[0], month: dateArr[1], year: dateArr[2] };
}

export function getEndMinDate(date: IBootstrapDate): IBootstrapDate {
  return Object.assign({}, date, { day: date.day + 1 });
}

export function getStartMaxDate(date: IBootstrapDate): IBootstrapDate {
  return Object.assign({}, date, { day: date.day - 1 });
}

export function dateInvalid(startAt: IBootstrapDate, endAt: IBootstrapDate): boolean {
  const start: Date = new Date(startAt?.year, startAt?.month - 1, startAt?.day);
  const end: Date = new Date(endAt?.year, endAt?.month - 1, endAt?.day);
  const valid = end?.getTime() - start?.getTime();

  return !isNaN(valid) && valid < 0;
}

export function startDateInvalid(startAt: IBootstrapDate, validYear: number): boolean {
  if (startAt?.year) {
    return validYear > startAt.year;
  }
  return false;
}

export const dateValidator =
  // eslint-disable-next-line max-len
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

export function getTimeProjects(date: any, time: any): string {
  return dayjs(`${date?.year}-${date?.month}-${date?.day} ${time?.hour}:${time?.min}`).format('YYYY-MM-DD HH:mm');
}
