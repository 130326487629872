<div class="global-label" [class.global-label__autoresize]="autoResizeInput">
  <span *ngIf="inputTitle" class="label"> {{ inputTitle | translate }} </span>
  <ng-select
    #ngSelect
    atlLockField
    [class.pointer-events-none]="locked"
    (isLocked)="isLocked($event, ngSelect)"
    [tabIndex]="tabIndex"
    [class.global-label--hidden]="chips && selectedItem && selectedItem[bindLabel]?.length"
    [class.global-label--special-style]="isSpecialStyle"
    [class.global-label--not-found]="!listOfItems?.length"
    [placeholder]="placeholder || label | translate"
    [typeToSearchText]="typeToSearchText && (typeToSearchText | translate)"
    [multiple]="multiple"
    [items]="listOfItems"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [editableSearchTerm]="editableSearchTerm"
    [disabled]="disabled"
    [dropdownPosition]="position"
    [ngModel]="inputValue"
    [closeOnSelect]="closeOnSelect"
    (change)="changeValue($event)"
    [searchable]="searchable"
    [clearable]="clearable"
    [selectableGroup]="selectableGroup"
    [selectableGroupAsModel]="selectableGroupAsModel"
    (clear)="onRemoveValue()"
    (search)="onListenInput($event)"
    (input)="changeInput($event)"
    [searchFn]="searchFn"
    [groupBy]="groupBy"
    (close)="onClose()"
    (open)="openDropdown(); scrollToSelectedItem()"
    (focus)="initSearch()"
    (scrollToEnd)="onScrollToEnd()"
    [loading]="loading"
    [appendTo]="appendTo"
    [trackByFn]="trackByFn"
    [typeahead]="typeahead$"
    [compareWith]="compareFunction"
    #dropDownInput
  >
    <ng-container *ngIf="!customGroup">
      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$">
        {{ item.name || ('Shared.Entity.Unnamed_group' | translate) }}
      </ng-template>
    </ng-container>

    <ng-container *ngIf="headerInfo">
      <ng-template ng-header-tmp>
        {{ emptyValueHideHeader && !savedValue && !listOfItems.length ? '' : headerInfo }}
      </ng-template>
    </ng-container>

    <ng-container *ngIf="customHeader">
      <ng-template ng-header-tmp>
        <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="customItemView && !multiLabel">
      <ng-container *ngIf="customLabelView && !multiLabel">
        <ng-template ng-label-tmp let-item="item" let-index="index" let-item$="item$">
          <ng-container>
            <ng-template
              [ngTemplateOutlet]="labelTemplate"
              [ngTemplateOutletContext]="{ item: item, item$: item$, index: index }"
            ></ng-template>
          </ng-container>
        </ng-template>
      </ng-container>

      <ng-template ng-option-tmp let-item="item" let-index="index" let-item$="item$" let-search="searchTerm">
        <ng-container>
          <ng-template
            [ngTemplateOutlet]="valueTemplate"
            [ngTemplateOutletContext]="{ item: item, item$: item$, index: index, context: ngSelect }"
          ></ng-template>
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="customUserForm">
      <ng-template ng-label-tmp let-item="item">
        <span>{{ item.select }}</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <span>{{ item.option }}</span>
      </ng-template>
    </ng-container>

    <ng-template *ngIf="!customNotFoundView" ng-notfound-tmp>
      <div class="ng-select__not-found ng-option disabled" *ngIf="notFoundText">
        <span [innerHTML]="notFoundText | translate"></span>
        <ng-content></ng-content>
      </div>
    </ng-template>

    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
      <div class="ng-option custom-view-container" *ngIf="customNotFoundView && !multiFooter">
        <ng-template
          [ngTemplateOutlet]="notFoundTemplate"
          [ngTemplateOutletContext]="{ searchTerm: searchTerm }"
        ></ng-template>
      </div>
    </ng-template>

    <ng-container *ngIf="customItemView && multiLabel">
      <ng-container *ngIf="customLabelView && multiLabel">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <ng-container>
            <ng-template
              [ngTemplateOutlet]="multiLabelTemplate"
              [ngTemplateOutletContext]="{ items: items }"
            ></ng-template>
          </ng-container>
        </ng-template>
      </ng-container>

      <ng-template ng-option-tmp let-item="item" let-index="index" let-item$="item$" let-search="searchTerm">
        <ng-container>
          <ng-template
            [ngTemplateOutlet]="valueTemplate"
            [ngTemplateOutletContext]="{ item: item, index: index, item$: item$ }"
          ></ng-template>
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="customGroup">
      <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
        <ng-template
          [ngTemplateOutlet]="groupTemplate"
          [ngTemplateOutletContext]="{ item: item, item$: item$, index: index, context: ngSelect }"
        ></ng-template>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="multiFooter">
      <ng-template ng-footer-tmp>
        <ng-template
          [ngTemplateOutlet]="notFoundTemplate"
          [ngTemplateOutletContext]="{ searchTerm: savedValue, context: ngSelect }"
        ></ng-template>
      </ng-template>
    </ng-container>
  </ng-select>

  <div class="chips__wrapper" *ngIf="chips && selectedItem && selectedItem[bindLabel].length">
    <div class="chips__card">
      <div class="chips__title">{{ selectedItem[bindLabel] }}</div>
      <div class="chips__close">
        <button (click)="dropDownInput.handleClearClick()">
          <atl-custom-icon [icon]="closeSvg"></atl-custom-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="dropdown__input-width" *ngIf="autoResizeInput" #inputWidth>{{ savedOriginalValue }}</div>
</div>
