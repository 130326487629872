import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import deleteIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/basket-white.svg';

@Component({
  selector: 'atl-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteModalComponent implements OnChanges {
  @Input() showInvite = false;
  @Input() showDownloadQR = false;
  @Input() showTransfer = false;
  @Input() showDelete = true;
  @Input() disabledTransferTooltips = false;
  @Input() disabledDownloadTooltips = false;
  @Input() showMoveIn = false;
  @Input() showDownload = false;
  @Input() deleteTooltip = 'Shared.Button.Delete';
  @Input() showMarkRead = false;
  @Input() showRename = false;
  @Input() publishUnpublish = false;
  @Output() passCancel: EventEmitter<boolean> = new EventEmitter();
  @Output() passDelete: EventEmitter<boolean> = new EventEmitter();
  @Output() passSendInvite: EventEmitter<boolean> = new EventEmitter();
  @Output() passDownloadQR: EventEmitter<boolean> = new EventEmitter();
  @Output() passMoveIn: EventEmitter<boolean> = new EventEmitter();
  @Output() passDownload: EventEmitter<boolean> = new EventEmitter();
  @Output() passTransfer: EventEmitter<boolean> = new EventEmitter();
  @Output() passRead: EventEmitter<boolean> = new EventEmitter();
  @Output() passRename: EventEmitter<boolean> = new EventEmitter();
  @Output() passPublishUnpublish: EventEmitter<boolean> = new EventEmitter();

  public expandedTransfer = false;
  public expandedDownload = false;

  public deleteIcon = deleteIcon;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabledTransferTooltips) {
      this.disabledTransferTooltips = changes.disabledTransferTooltips.currentValue;
    }
    if (changes.disabledDownloadTooltips) {
      this.disabledDownloadTooltips = changes.disabledDownloadTooltips.currentValue;
    }
  }

  public confirm(): void {
    this.passDelete.emit(true);
  }

  public cancel(): void {
    this.passCancel.emit(true);
  }

  public sendInvite(): void {
    this.passSendInvite.emit(true);
  }
  public downloadQR(): void {
    this.passDownloadQR.emit(true);
  }

  public publishUnpublishHandler(): void {
    this.passPublishUnpublish.emit(true);
  }

  public transfer(val: boolean): void {
    if (val && this.expandedDownload) this.expandedDownload = false;
    this.expandedTransfer = !this.expandedTransfer;
    this.passTransfer.emit(!val ? val : this.expandedTransfer);
  }

  public moveIn(): void {
    this.passMoveIn.emit(true);
  }

  public download(val: boolean): void {
    if (val && this.expandedTransfer) this.expandedTransfer = false;
    this.expandedDownload = !this.expandedDownload;

    this.passDownload.emit(!val ? val : this.expandedDownload);
  }

  public read(): void {
    this.passRead.emit(true);
  }

  public rename(): void {
    this.passRename.emit(true);
  }

  public closeAllPopups(): void {
    this.expandedDownload = false;
    this.expandedTransfer = false;
  }
}
