<div class="fdv-tree-menu">

  <ng-container *ngIf="!folderMenu">
    <ng-container *ngTemplateOutlet="createTemplate"></ng-container>
    <ng-container *ngIf="uploadItem">
      <ng-container *ngTemplateOutlet="uploadFileTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="uploadItem">
      <ng-container  *ngTemplateOutlet="uploadFolderTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="renameItem">
      <ng-container *ngTemplateOutlet="renameTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="downloadItem">
      <ng-container *ngTemplateOutlet="downloadTemplate"></ng-container>
    </ng-container>
  </ng-container>


  <ng-container *ngIf="folderMenu">
    <ng-container *ngIf="renameItem">
      <ng-container *ngTemplateOutlet="renameTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="copyItem">
      <ng-container *ngTemplateOutlet="copyTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="downloadItem">
      <ng-container *ngTemplateOutlet="downloadTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="moveItem">
      <ng-container *ngTemplateOutlet="moveTemplate"></ng-container>
    </ng-container>

  </ng-container>
</div>
<div *ngIf="removeItem" class="fdv-tree-menu footer">
  <div (click)="delete.emit()" ngbDropdownItem class="fdv-tree-menu__item text-red-830">
    <img src="/assets/trash-attachment-red.svg" alt="icon">
    {{ 'Button.Delete' | translate }}
  </div>
</div>

<ng-template #createTemplate>
  <div (click)="create.emit()" ngbDropdownItem class="fdv-tree-menu__item">
    <img src="/assets/add-folder-v3.svg" alt="icon">
    {{ 'Button.Create_folder' | translate }}
  </div>
</ng-template>
<ng-template #uploadFileTemplate>
  <div (click)="uploadFile.emit()" ngbDropdownItem class="fdv-tree-menu__item">
    <img src="/assets/download_simple-v3.svg" alt="icon">
    {{ 'Shared.Entity.Upload_file' | translate }}
  </div>
</ng-template>
<ng-template #uploadFolderTemplate>
  <div (click)="uploadFolder.emit()" ngbDropdownItem class="fdv-tree-menu__item">
    <img src="/assets/upload-folder-v3.svg" alt="icon">
    {{ 'Button.Upload_folder' | translate }}
  </div>
</ng-template>
<ng-template #renameTemplate>
  <div (click)="rename.emit()" ngbDropdownItem class="fdv-tree-menu__item">
    <img src="/assets/edit-gray.svg" alt="icon">
    {{ 'Shared.Entity.Rename' | translate }}
  </div>
</ng-template>
<ng-template #downloadTemplate>
  <div (click)="download.emit()" ngbDropdownItem class="fdv-tree-menu__item">
    <img src="/assets/download-16x16.svg" alt="icon">
    {{ 'Shared.Entity.Download' | translate }}
  </div>
</ng-template>
<ng-template #copyTemplate>
  <div (click)="copy.emit()" ngbDropdownItem class="fdv-tree-menu__item">
    <img src="/assets/document-v3-copy-link.svg" alt="icon">
    {{ 'Entity.Link_for_admin_portal' | translate: {title: (copyItemTitle | translate)} }}
  </div>
</ng-template>
<ng-template #moveTemplate>
  <div (click)="move.emit()" ngbDropdownItem class="fdv-tree-menu__item">
    <img src="/assets/document-v3-move-to.svg" alt="icon">
    {{ 'Shared.Button.Move' | translate }}
  </div>
</ng-template>
