<!--
  ******************************************************
  @Cypress
  - interaction with control
  - interaction with "Show password" icon
  ******************************************************
-->
<div class="global-label" [class.filled]="inputValue">
  <div *ngIf="label" class="input-header">
    <span class="label"> {{ label | translate }}</span>
    <p *ngIf="showForgotLink" (click)="goToForgotPage()" class="link" id="cy-forgot-label">
      {{ 'Shared.Entity.Forgot' | translate }}
    </p>
    <atl-undo-changes
      (countdownFinished)="finishUndo()"
      (undoClick)="cancelUndo()"
      *ngIf="showUndo$ | async"
      [fieldName]="label"
      [undoTimer]="undoTimer$ | async"
    ></atl-undo-changes>
  </div>

  <div *ngIf="icon" class="global-label__icon">
    <img src="assets/{{ icon }}" alt="Input icon" />
  </div>
  <input
    atlReplaceZero
    [atlReplaceZero]="replaceZero"
    atlCapitalLetter
    atlPreventDoubleSpace
    atlReplaceInput
    atlLockField
    (isLocked)="isLocked($event)"
    [sweechLockOff]="sweechLockOff"
    [capitalize]="capitalize"
    [maxLength]="maxLength"
    [currentValue]="inputValue"
    [style.width.px]="width"
    #input
    (blur)="startTimer(); onBlur()"
    (focus)="checkFocus()"
    (input)="changeValue(input.value); resizeInput()"
    (paste)="changeValue(input.value); resizeInput()"
    (mouseenter)="iconActiveHandler(true)"
    (mouseleave)="iconActiveHandler(false)"
    (keyup.enter)="startTimer()"
    [class.global_input--clearable]="clearable && inputValue"
    [class.global_input--without-border]="isWithoutBorder"
    [disabled]="disabled"
    [mask]="mask"
    [ngModel]="inputValue"
    [placeholder]="skipPlaceholderTranslate ? placeholder : (placeholder | translate)"
    [separatorLimit]="separatorLimit"
    [thousandSeparator]="thousandSeparator"
    [showTemplate]="true"
    [suffix]="suffix"
    [type]="type === 'number' ? 'text' : type"
    [allowNegativeNumbers]="allowNegativeNumbers"
    [decimalMarker]="decimalMarker"
    [attr.decimalMarker]="initDecimalMarker ? ',' : null"
    class="global_input"
    [attr.id]="ID ? ID : null"
    [readOnly]="readOnly"
    [tabIndex]="tabindex === undefined ? null : tabindex"
  />
  <ng-template [ngIf]="loading">
    <div class="input__loader">
      <span class="custom-spinner custom-spinner-12"></span>
    </div>
  </ng-template>
  <ng-template [ngIf]="displayShowIcon">
    <img
      class="input__show-password-icon"
      src="/assets/{{ iconName }}"
      alt="Show password"
      data-cy="show-password-icon"
      (click)="toggleDisplayingPassword()"
    />
  </ng-template>
  <span *ngIf="clearable && inputValue" class="input__clear-btn" (click)="clearInput()">×</span>
</div>

<ng-container *ngIf="isAutoResize">
  <span #hiddenText class="input__hidden-checker" [class.survey]="surveyResize">{{ input.value }}</span>
</ng-container>
