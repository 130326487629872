import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  DocumentModel,
  DocumentsLoadingType,
  DocumentTreeStructure,
  DocumentUrlModel,
  DownloadStatus,
  EDocumentType,
  ETablePagination,
  Folder,
  IDocument,
  IFileFolderLoading,
  IPackDocument,
  IPackShowDocument,
  ITablePagination,
  IUserType,
  UploadDocumentsTreeBody,
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, EMPTY, Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  delay,
  expand,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  toArray,
} from 'rxjs/operators';

import { FileHelper } from '../../helpers/file';
import { PaginationUtil } from '../../helpers/pagination.util';
import { DataTableHelperService } from '../data-table-helper/data-table-helper.service';
import { UploadingService } from '../uploading/uploading.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectDocumentsService {
  private documentsPagination$ = new BehaviorSubject<ITablePagination | null>(null);
  private downloadLoading$ = new BehaviorSubject<boolean>(false);
  public renamedFolder$ = new Subject<void>();
  private _uploadingDocuments$ = new BehaviorSubject<IFileFolderLoading>({
    loading: false,
    type: '',
    alreadyLoaded: 0,
  });
  public amountForUpload$ = new BehaviorSubject<number>(0);

  private alreadyUploaded = 0;
  private unitId: number | undefined;

  constructor(
    private http: HttpClient,
    private tableService: DataTableHelperService,
    @Inject('ENVIRONMENT') private env: IEnvironment,
    private uploadingService: UploadingService
  ) {}

  public setUnitIdFromRoute(unitId: number | undefined): void {
    this.unitId = unitId;
  }

  /**
   * @Cypress
   */
  public getDocuments(
    projectId: number,
    apiUrl: string,
    sort = 'default',
    search = '',
    paginate?: ITablePagination,
    folderId: string | null = '',
    isInternal = false,
    documentUnitId: null | number = null,
    documentLayoutTypeId: null | number = null
  ): Observable<DocumentModel[]> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (this.unitId) {
      params = params.append('unit_id', this.unitId.toString());
    }
    if (folderId) {
      params = params.append('folder_id', folderId);
    }
    if (isInternal) {
      params = params.append('internal', `${isInternal}`);
    }
    if (documentUnitId) {
      params = params.append('unit_id', documentUnitId.toString());
    }
    if (documentLayoutTypeId) {
      params = params.append('layout_type_id', documentLayoutTypeId.toString());
    }

    return this.http
      .get<any>(apiUrl + `api/v1/projects/${projectId}/documents`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(result.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(result.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalPages),
          };
          this.documentsPagination$.next(pagination);
        }),
        map((res) => res.body.data.documents),
        map((data: IDocument[]) => plainToClass(DocumentModel, data))
      );
  }

  public getDocumentsState(
    projectId: number,
    apiUrl: string,
    sort = 'default',
    search = '',
    paginate?: ITablePagination,
    folderId: string | null = ''
  ): Observable<any> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (this.unitId) {
      params = params.append('unit_id', this.unitId.toString());
    }
    if (folderId) {
      params = params.append('folder_id', folderId);
    }
    return this.http.get<any>(apiUrl + `api/v1/projects/${projectId}/documents`, {
      params,
      observe: 'response',
    });
  }

  public removeDocument(projectId: number, documentId: string, apiUrl: string): Observable<unknown> {
    const params: HttpParams = this.getUnitIdParams();
    return this.http.delete(apiUrl + `api/v1/projects/${projectId}/documents/${documentId}`, {
      params,
    });
  }

  public getUserTypes(apiUrl: string): Observable<IUserType[]> {
    return this.http.get(apiUrl + 'api/v1/user_data/types').pipe(
      map((res: any) => res.data.user_types),
      map((data) => <IUserType[]>data)
    );
  }

  public createDocument(
    projectId: number,
    formValue: { document: File[] },
    apiUrl: string,
    folderId?: number | null
  ): Observable<DocumentModel[]> {
    const params: HttpParams = this.getUnitIdParams();
    const fd = new FormData();
    formValue.document.forEach((el: File) => {
      fd.append('document_type', 'file');
      fd.append('documents[][filename]', el, el.name);
    });
    if (folderId) {
      fd.append('folder_id', folderId.toString());
    }
    return this.http
      .post<{ data: { documents: IDocument[] } }>(apiUrl + `api/v1/projects/${projectId}/documents`, fd, {
        params,
      })
      .pipe(
        map((res) => res.data.documents),
        map((data: IDocument[]) => plainToClass(DocumentModel, data))
      );
  }

  public createFolder(
    projectId: number,
    folderName: string,
    apiUrl: string,
    folderId?: number | null,
    isInternal?: boolean,
    documentUnitId: null | number = null,
    documentLayoutTypeId: null | number = null
  ): Observable<DocumentModel[]> {
    const fd = new FormData();
    fd.append('document_type', 'folder');
    fd.append('documents[][title]', folderName);

    if (folderId) {
      fd.append('folder_id', folderId.toString());
    }

    if (isInternal) {
      fd.append('internal', `${isInternal}`);
    }

    if (documentUnitId) {
      fd.append('unit_id', documentUnitId.toString());
    }

    if (documentLayoutTypeId) {
      fd.append('layout_type_id', documentLayoutTypeId.toString());
    }

    const params: HttpParams = this.getUnitIdParams();
    return this.http
      .post<{ data: { documents: IDocument[] } }>(apiUrl + `api/v1/projects/${projectId}/documents`, fd, {
        params,
      })
      .pipe(
        map((res) => res.data.documents),
        map((data: IDocument[]) => plainToClass(DocumentModel, data))
      );
  }

  get pagination$(): Observable<ITablePagination | null> {
    return this.documentsPagination$.asObservable();
  }

  setPagination(pagination: ITablePagination): void {
    this.documentsPagination$.next(pagination);
  }

  private getUnitIdParams(): HttpParams {
    return this.unitId ? new HttpParams().set('unit_id', this.unitId.toString()) : new HttpParams();
  }

  /**
   * @Cypress
   */
  public deleteDocuments(
    projectId: number,
    ids: DocumentModel[],
    apiUrl: string,
    unitId?: number,
    layoutTypeId?: number,
    isInternal = false
  ): Observable<string> {
    let params = new HttpParams();
    if (isInternal) {
      params = params.append('internal', `${isInternal}`);
    }
    if (unitId) {
      params = params.append('unit_id', unitId.toString());
    }
    if (layoutTypeId) {
      params = params.append('layout_type_id', layoutTypeId.toString());
    }

    const httpOptions = {
      params: params,
      body: { ids: ids.map((x) => x.id) },
    };
    return this.http
      .delete(`${apiUrl}api/v1/projects/${projectId}/documents/batch_destroy`, httpOptions)
      .pipe(map((res: any) => <string>res?.message));
  }

  public getDocumentById(
    projectId: number,
    apiUrl: string,
    documentId: number,
    unitId?: number,
    layoutTypeId?: number,
    isInternal = false
  ): Observable<DocumentModel> {
    let params = new HttpParams();
    if (isInternal) {
      params = params.append('internal', `${isInternal}`);
    }
    if (unitId) {
      params = params.append('unit_id', unitId.toString());
    }
    if (layoutTypeId) {
      params = params.append('layout_type_id', layoutTypeId.toString());
    }

    return this.http
      .get<{ data: IDocument }>(`${apiUrl}api/v1/projects/${projectId}/documents/${documentId}`, {
        params: params,
      })
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(DocumentModel, data))
      );
  }

  public getDocument(projectId: number, apiUrl: string, documentId?: number): Observable<DocumentModel> {
    return this.http
      .get<{ data: IDocument }>(`${apiUrl}api/v1/projects/${projectId}/documents/${documentId || ''}`)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(DocumentModel, data))
      );
  }

  public moveToFolder(projectId: number, folderId: number, ids: DocumentModel[], apiUrl: string): Observable<string> {
    const data = {
      folder_id: folderId,
      ids: ids.map((x) => x.id),
    };
    const params = this.getUnitIdParams();
    return this.http
      .patch(apiUrl + `api/v1/projects/${projectId}/documents/move_into_folder`, data, {
        params,
      })
      .pipe(map((res: any) => <string>res?.message));
  }

  // ToDo: a separate endpoint should be added to get all and only folders.
  public getNestedFolders(projectId: number, apiUrl: string, folderId = ''): Observable<DocumentModel[]> {
    const perPage = 1000;
    let params: HttpParams = this.getUnitIdParams();
    params = params.append('per_page', String(perPage));
    if (folderId) {
      params = params.append('folder_id', folderId);
    }
    return this.http
      .get<{ data: { documents: IDocument[] } }>(apiUrl + `api/v1/projects/${projectId}/documents`, { params })
      .pipe(
        map((res) => res.data.documents),
        map((data: IDocument[]) =>
          plainToClass(DocumentModel, data).filter((x) => x.documentType === EDocumentType.Folder)
        )
      );
  }

  public showDocument(
    projectId: number,
    documentId: number | null,
    apiUrl: string,
    isInternal = false,
    unitId: number | null = null,
    layoutTypeId: number | null = null
  ): Observable<DocumentModel> {
    const perPage = 1000;
    let params: HttpParams = this.getUnitIdParams();
    params = params.append('per_page', String(perPage));

    if (isInternal) {
      params = params.append('internal', `${isInternal}`);
    }
    if (unitId) {
      params = params.append('unit_id', unitId.toString());
    }
    if (layoutTypeId) {
      params = params.append('layout_type_id', layoutTypeId.toString());
    }
    return this.http.get<any>(apiUrl + `api/v1/projects/${projectId}/documents/${documentId}`, { params }).pipe(
      map((res: any) => res.data),
      map((data: IDocument) => plainToClass(DocumentModel, data))
    );
  }

  public getNewestNestedFolders(
    projectId: number,
    apiUrl: string,
    folderId = '',
    isInternal = false,
    unitId: number | null = null,
    layoutTypeId: number | null = null
  ): Observable<DocumentModel[]> {
    const perPage = 1000;
    let params: HttpParams = this.getUnitIdParams();
    params = params.append('per_page', String(perPage));
    if (folderId) {
      params = params.append('folder_id', folderId);
    }
    if (isInternal) {
      params = params.append('internal', `${isInternal}`);
    }
    if (unitId) {
      params = params.append('unit_id', unitId.toString());
    }
    if (layoutTypeId) {
      params = params.append('layout_type_id', layoutTypeId.toString());
    }
    return this.http
      .get<{ data: { documents: IDocument[] } }>(apiUrl + `api/v1/projects/${projectId}/documents`, { params })
      .pipe(
        map((res) => res.data.documents),
        map((data: IDocument[]) =>
          plainToClass(DocumentModel, data)
        )
      );
  }

  public renameFolder(
    id: string | number,
    projectId: string | number,
    apiUrl: string,
    folderName = '',
    isInternal = false,
    unitId: number | null = null,
    layoutTypeId: number | null = null
  ): Observable<DocumentModel> {
    let params: HttpParams = new HttpParams();
    if (isInternal) {
      params = params.append('internal', `${isInternal}`);
    }
    if (unitId) {
      params = params.append('unit_id', unitId.toString());
    }
    if (layoutTypeId) {
      params = params.append('layout_type_id', layoutTypeId.toString());
    }
    return this.http
      .put<{ data: IDocument }>(
        apiUrl + `/api/v1/projects/${projectId}/documents/${id}`,
        {
          document: { title: folderName },
        },
        {
          params: params,
        }
      )
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(DocumentModel, data))
      );
  }

  get downloadLoading(): Observable<boolean> {
    return this.downloadLoading$.asObservable();
  }

  /**
   * @Cypress
   */
  public createDownloadPack(projectId: number, body: FormData, forFdv = false): Observable<any> {
    this.downloadLoading$.next(true);
    return this.http
      .post<{ data: IPackDocument }>(
        `${this.env.apiBaseUrl}api/v1/projects/${projectId}/document_download_packs`,
        body,
        {
          params: {
            for_fdv: forFdv,
          },
        }
      )
      .pipe(
        map((res: any) => res.data),
        delay(2000),
        mergeMap((pack: IPackDocument) => this.showDownloadPack(projectId, pack.id)),
        expand((showPack: IPackShowDocument) => {
          if (showPack.status !== DownloadStatus.Done) {
            return of(null).pipe(
              delay(2000),
              mergeMap(() => this.showDownloadPack(projectId, showPack.id))
            );
          }

          return EMPTY;
        }),
        filter((showPack: IPackShowDocument) => showPack.status === DownloadStatus.Done),
        finalize(() => this.downloadLoading$.next(false)),
        tap((showPack: IPackShowDocument) => {
          window.open(showPack.filename.download_url, '_self');
        })
      );
  }

  public showDownloadPack(projectId: number, id: number): Observable<IPackShowDocument> {
    return this.http
      .get<{ data: IPackShowDocument }>(
        `${this.env.apiBaseUrl}api/v1/projects/${projectId}/document_download_packs/${id}`
      )
      .pipe(map((res: any) => res.data));
  }

  async loadFile(file: File): Promise<void> {
    await new Promise<void>((resolve, reject) => {
      const reader = new FileReader();
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      reader.onload = () => {
        resolve(void 0);
      };
      reader.onerror = reject;
      if (file) reader.readAsArrayBuffer(file);
    });
  }

  public preparingUploadDocuments(
    node: Folder[],
    tree: DocumentTreeStructure[],
    uploadStateIndex: number,
    cancelSignal$: Subject<void>
  ): Observable<any> {
    return of(node).pipe(
      switchMap((element) => element),
      mergeMap((element) => {
        if (element.file) {
          const subscription: Subscription = new Subscription();
          const uploadSubscription = this.generateDocumentUrlsS3(element.file).pipe(
            mergeMap((urls: DocumentUrlModel[]) => {
              return this.uploadFileToS3(urls[0], element.file as File).pipe(map(() => urls[0]));
            }),
            tap((data) => {
              if (
                this.uploadingService.getUploadByIndex(uploadStateIndex) &&
                this.uploadingService.getUploadByIndex(uploadStateIndex).subscription
              ) {
                const uploads = this.uploadingService.uploads();
                uploads[uploadStateIndex].alreadyLoaded++;
                this.uploadingService.updateUploads(uploads);
              }

              this.uploadingService.setUploadingDocuments({
                loading: true,
                type: '',
                alreadyLoaded: this.alreadyUploaded++,
              });
              tree.push({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                title: element.file!.name,
                filename_remote_url: data.publicUrl,
                children: [],
              });
            }),
            takeUntil(cancelSignal$)
          );

          if (this.uploadingService.getUploadByIndex(uploadStateIndex).subscription) {
            subscription.add(this.uploadingService.getUploadByIndex(uploadStateIndex).subscription as Subscription);
          }
          const uploads = this.uploadingService.uploads();
          uploads[uploadStateIndex].subscription = subscription;
          uploads[uploadStateIndex].cancelSignal$ = cancelSignal$;
          this.uploadingService.updateUploads(uploads);

          return uploadSubscription;
        } else {
          tree.push({
            title: element.name,
            children: [],
          });
          return this.preparingUploadDocuments(
            element.children,
            tree[tree.length - 1].children,
            uploadStateIndex,
            cancelSignal$
          );
        }
      })
    );
  }

  public uploadDocuments(
    node: Folder[],
    projectId: number,
    unitId?: number,
    folderId?: string,
    layoutTypeId?: number,
    internal?: boolean,
    amountFilesForUpload?: number
  ): Observable<any> {
    this.alreadyUploaded = 0;
    const uploadStateIndex = this.uploadingService.uploads().length;

    this.uploadingService.setUploads({
      subscription: null,
      cancelSignal$: new Subject<void>(),
      alreadyLoaded: 0,
      amountFilesForUpload: amountFilesForUpload || 0,
    });
    this.uploadingService.setUploadingDocuments({
      loading: true,
      type: node.length === 1 && !('file' in node[0]) ? DocumentsLoadingType.Folder : DocumentsLoadingType.Items,
      alreadyLoaded: 0,
    });
    const tree: DocumentTreeStructure[] = [];

    return this.preparingUploadDocuments(
      node,
      tree,
      uploadStateIndex,
      this.uploadingService.getUploadByIndex(uploadStateIndex).cancelSignal$
    ).pipe(
      toArray(),
      mergeMap(() => {
        return this.uploadDocumentsTree(tree, projectId, unitId, folderId, layoutTypeId, internal);
      })
    );
  }

  public generateDocumentUrlsS3(file: File): Observable<DocumentUrlModel[]> {
    const fd = new FormData();
    fd.append('filenames[]', file.name);
    return this.http
      .post<{ data: DocumentUrlModel[] }>(`${this.env.apiBaseUrl}api/v1/files/generate_multiple_presigned_urls`, fd, {})
      .pipe(
        map((res: any) => res.data),
        map((data: DocumentUrlModel[]) => plainToClass(DocumentUrlModel, data))
      );
  }

  uploadFileToS3(url: DocumentUrlModel, file: File): Observable<any> {
    let fileType = '';
    if (!file.type) {
      fileType = FileHelper.getFileTypeByExtension(file);
    } else {
      fileType = file.type;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': fileType });

    return this.http.put(url.uploadUrl, file, { headers });
  }

  /**
   * @Cypress
   */
  public uploadDocumentsTree(
    tree: DocumentTreeStructure[],
    projectId: number,
    unitId?: number,
    folderId?: string,
    layoutTypeId?: number,
    internal?: boolean
  ): Observable<any[]> {
    let params = new HttpParams();
    const requestBody: UploadDocumentsTreeBody = { documents: tree };
    if (unitId) params = params.set('unit_id', unitId.toString());
    if (layoutTypeId) params = params.set('layout_type_id', layoutTypeId.toString());
    if (internal) params = params.set('internal', 'true');
    if (folderId) requestBody.folder_id = folderId;

    return this.http
      .post<{ data: any[] }>(`${this.env.apiBaseUrl}api/v1/projects/${projectId}/documents`, requestBody, {
        params,
      })
      .pipe(
        map((res: any) => res.data),
        catchError(() => {
          this.uploadingService.setUploadingDocuments({
            loading: false,
            type: '',
            alreadyLoaded: this.alreadyUploaded,
          });
          return [];
        }),
        finalize(() => {
          this.uploadingService.setUploadingDocuments({
            loading: false,
            type: '',
            alreadyLoaded: this.alreadyUploaded,
          });
        })
      );
  }

  public moveToFolderDocuments(
    projectId: number,
    folderId: number,
    ids: DocumentModel[],
    apiUrl: string,
    internal = false,
    layoutTypeId?: number,
    unitId?: number
  ): Observable<string> {
    let params = new HttpParams();
    params = params.set('internal', internal.toString());
    if (layoutTypeId) {
      params = params.set('layout_type_id', layoutTypeId.toString());
    }

    if (unitId) {
      params = params.set('unit_id', unitId.toString());
    }

    const data = {
      folder_id: folderId,
      ids: ids.map((x) => x.id),
    };

    return this.http
      .patch(apiUrl + `api/v1/projects/${projectId}/documents/move_into_folder`, data, {
        params,
      })
      .pipe(map((res: any) => <string>res?.message));
  }

  public downloadFromS3(url: string, fileName: string): Observable<void> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((blob: Blob) => {
        const fileURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(fileURL);
      })
    );
  }
}
