<div class="requests-filter" *ngIf="changeRequestsStatus">
  <ng-container *ngFor="let item of statusList | slice: 0:viewItemsCount; index as i">
    <div
      *ngIf="changeRequestsStatus[item.status] === undefined || changeRequestsStatus[item.status].count"
      class="requests-filter__control-item"
      [class.active]="activeStatus === item.status"
      (click)="setStatus(item.status, i)"
    >
      <ng-container *ngIf="i !== 0">
        <ng-container *ngIf="!(activeStatusIndex + 1 === i)">
          <img class="requests-filter__control-item-dot" src="assets/dot_messages.svg" alt="Dot" />
        </ng-container>
      </ng-container>
      <span>
        {{
          changeRequestsStatus[item.status] !== undefined
            ? changeRequestsStatus[item.status].localizedStatus
            : ('Entity.All' | translate)
        }}
      </span>
      <span class="ml-4">
        {{ changeRequestsStatus[item.status] !== undefined ? changeRequestsStatus[item.status].count : totalCount }}
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="customListView">
    <ng-container *ngFor="let item of actualFilterList; index as i">
      <div
        *ngIf="changeRequestsStatus[item.value].count"
        class="requests-filter__control-item"
        [class.active]="item.active"
        (click)="setFilter(item.value)"
      >
        <ng-container *ngIf="!item.active">
          <ng-container *ngIf="!actualFilterList[i - 1]?.active">
            <ng-container *ngIf="!(i === 0 && activeStatusIndex === lastIndex)">
              <img class="requests-filter__control-item-dot" src="assets/dot_messages.svg" alt="Dot" />
            </ng-container>
          </ng-container>
        </ng-container>
        <span>{{ changeRequestsStatus[item.value].localizedStatus }}</span>
        <span class="ml-4">
          {{ changeRequestsStatus[item.value].count }}
        </span>
      </div>
    </ng-container>
  </ng-container>
  <div
    *ngIf="isShowExpand"
    (click)="statusExpandHandler()"
    class="requests-filter__control-item"
    [class.active]="statusExpanded || activeStatusIndex === null"
    atlClickOutside
    (clickOutside)="statusExpandClose()"
  >
    <ng-container
      *ngIf="
        customListView ? !actualFilterList[actualFilterList.length - 1]?.active : !(activeStatusIndex === lastIndex)
      "
    >
      <img *ngIf="!statusExpanded" class="requests-filter__control-item-dot" src="assets/dot_messages.svg" alt="Dot" />
    </ng-container>
    <span class="mr-4">{{ 'Shared.Button.More' | translate }}</span>
    <span class="arrow-icon">
      <atl-custom-icon [icon]="arrowDownIcon" [size]="12"></atl-custom-icon>
    </span>
    <div class="expanded-wrap" [class.expanded]="statusExpanded">
      <ng-container *ngFor="let item of statusList | slice: viewItemsCount">
        <div
          *ngIf="changeRequestsStatus[item.status].count"
          class="expanded-wrap__item"
          [class.active]="item.status === activeStatus"
          (click)="setStatus(item.status, null)"
        >
          <span>
            {{
              changeRequestsStatus[item.status] !== undefined
                ? changeRequestsStatus[item.status].localizedStatus
                : ('Entity.All' | translate)
            }}
          </span>
          <span class="text-black-810">{{ changeRequestsStatus[item.status].count }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="requests-filter__dropdown-wrapper" atlClickOutside (clickOutside)="closeMobileDropdown()">
  <div
    class="requests-filter__dropdown-btn"
    (click)="showMobileDropdown()"
    [ngbTooltip]="'Shared.Filters' | translate"
    placement="right"
  >
    <atl-custom-icon [icon]="filterIcon"></atl-custom-icon>
  </div>
  <div class="expanded-wrap" [class.expanded]="!dropdownMobileCollapsed">
    <ng-container *ngFor="let item of statusList | slice: 0:1; index as i">
      <div
        class="expanded-wrap__item"
        [class.active]="activeStatus === item.status"
        (click)="setStatus(item.status, i)"
      >
        <span class="lh-24 fz-14">{{ 'Entity.All' | translate }}</span>
        <span class="lh-24 fz-14 text-black-810">{{ totalCount }}</span>
      </div>
    </ng-container>

    <ng-container *ngIf="customListView">
      <ng-container *ngFor="let item of actualFilterList; index as i">
        <div
          *ngIf="changeRequestsStatus[item.value].count"
          class="expanded-wrap__item"
          [class.active]="item.active"
          (click)="setFilter(item.value)"
        >
          <span class="lh-24 fz-14">{{ changeRequestsStatus[item.value].localizedStatus }}</span>
          <span class="lh-24 fz-14 text-black-810">{{ changeRequestsStatus[item.value].count }}</span>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngFor="let item of statusList | slice: 1; index as i">
      <div
        *ngIf="changeRequestsStatus && changeRequestsStatus[item.status]?.count"
        class="expanded-wrap__item"
        [class.active]="activeStatus === item.status"
        (click)="setStatus(item.status, i + 1)"
      >
        <span class="lh-24 fz-14">
          {{
            changeRequestsStatus[item.status] !== undefined
              ? changeRequestsStatus[item.status].localizedStatus
              : ('Entity.All' | translate)
          }}
        </span>
        <span class="lh-24 fz-14 text-black-810">
          {{ changeRequestsStatus[item.status] !== undefined ? changeRequestsStatus[item.status].count : totalCount }}
        </span>
      </div>
    </ng-container>
  </div>
</div>
