import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FdvModel} from "@atlas-workspace/shared/models";
import {CommonModule} from "@angular/common";
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'atl-fdv-breadcrumbs',
  templateUrl: './fdv-breadcrumbs.component.html',
  styleUrls: ['./fdv-breadcrumbs.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgbDropdownModule
  ]
})
export class FdvBreadcrumbsComponent {
  @Input() selectFolder!: FdvModel;
  @Output() readonly selectParent = new EventEmitter<number>()

  select(id: number): void {
    this.selectParent.emit(id);
  }
}
