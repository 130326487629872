import {Expose, Type} from 'class-transformer';

import {ContractorCompany, EContractorRoles} from './company.model';
import {BuyerUnitsModel} from "./buyer.model";

export enum EUserKind {
  Admin = 'admin',
  Contractor = 'contractor',
  User = 'user'
}

export enum EGlobalUserType {
  Member = 'member',
  Buyer = 'buyer'
}

export const globalUserKindTitle = {
  [EUserKind.Admin]: 'Entity.Account_member',
  [EUserKind.Contractor]: 'Entity.Contractor_v2',
  [EUserKind.User]: 'Entity.Buyer',
};

export const activeKind = {
  [EUserKind.Admin]: false,
  [EUserKind.Contractor]: false,
  [EUserKind.User]: false,
};

export enum EGlobalFirmRole {
  FirmOwner = 'firm_owner',
  FirmAdmin = 'firm_admin',
  FirmMember = 'firm_member',
  FirmContractor = 'firm_contractor',
  UnitOwner = 'unit_owner',
  UnitCoOwner = 'unit_co_owner',
  UnitMember = 'unit_member',
  Multiple = 'multiple'
}

export interface IRoleArray {
  value: EGlobalFirmRole;
  title: EGlobalRolesTitle;
  active: boolean;
}

export interface GlobalContractorCompany {
  company: ContractorCompany;
  role: EContractorRoles;
  contractorCompanyId: number;
}

export enum EGlobalRolesTitle {
  FirmOwner = 'Shared.Entity.Primary_owner',
  FirmAdmin = 'Shared.Entity.Admin',
  FirmMember = 'Shared.Entity.Member',
  FirmContractor = 'Shared.Entity.Contractor',
  UnitOwner = 'Shared.Entity.Unit_owner',
  UnitCoOwner = 'Shared.Entity.Unit_co-owner',
  UnitMember = 'Shared.Entity.Unit_guest',
  Multiple = 'Shared.Entity.Multiple',
}

export const globalUsersRolesList: IRoleArray[] = [
  { value: EGlobalFirmRole.FirmOwner, title: EGlobalRolesTitle.FirmOwner, active: false },
  { value: EGlobalFirmRole.FirmAdmin, title: EGlobalRolesTitle.FirmAdmin, active: false },
  { value: EGlobalFirmRole.FirmMember, title: EGlobalRolesTitle.FirmMember, active: false },
  { value: EGlobalFirmRole.FirmContractor, title: EGlobalRolesTitle.FirmContractor, active: false },
  { value: EGlobalFirmRole.UnitOwner, title: EGlobalRolesTitle.UnitOwner, active: false },
  { value: EGlobalFirmRole.UnitCoOwner, title: EGlobalRolesTitle.UnitCoOwner, active: false },
  { value: EGlobalFirmRole.UnitMember, title: EGlobalRolesTitle.UnitMember, active: false },
  { value: EGlobalFirmRole.Multiple, title: EGlobalRolesTitle.Multiple, active: false },
];

export const globalUsersRoleTitle = {
  [EGlobalFirmRole.FirmOwner]: EGlobalRolesTitle.FirmOwner,
  [EGlobalFirmRole.FirmAdmin]: EGlobalRolesTitle.FirmAdmin,
  [EGlobalFirmRole.FirmMember]: EGlobalRolesTitle.FirmMember,
  [EGlobalFirmRole.FirmContractor]: EGlobalRolesTitle.FirmContractor,
  [EGlobalFirmRole.UnitOwner]: EGlobalRolesTitle.UnitOwner,
  [EGlobalFirmRole.UnitCoOwner]: EGlobalRolesTitle.UnitCoOwner,
  [EGlobalFirmRole.UnitMember]: EGlobalRolesTitle.UnitMember,
  [EGlobalFirmRole.Multiple]: EGlobalRolesTitle.Multiple,
};

export interface IGlobalUserFilter {
  projects: number[];
  roles: string[];
  companies: CompaniesCountModel[];
}

export interface ITransformGlobalFirmUser {
  id: number;
  kind: EUserKind;
  name: string;
  email: string;
  phone: string;
  compoundRole: EGlobalFirmRole;
  registered: boolean;
  lastActivity: string | null;
  createdAt: string;
  sentInvite: boolean;
  adminsFirmId?: number;
  units?: BuyerUnitsModel[];
}

export class GlobalFirmUser {
  @Expose({ name: 'character_id' })
  id!: number;
  @Expose({ name: 'character_type' })
  characterType!: string;
  @Expose({ name: 'kind' })
  kind!: EUserKind;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'phone' })
  phone!: string;
  @Expose({ name: 'compound_role' })
  compoundRole!: EGlobalFirmRole;
  @Expose({ name: 'registered' })
  registered!: boolean;
  @Expose({ name: 'last_activity' })
  lastActivity!: string;
  @Expose({ name: 'invite_is_sent' })
  inviteSent!: boolean;
  @Expose({ name: 'invite_sent_at' })
  inviteSentAt!: boolean | null;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'project_names' })
  projectNames!: string[];
  @Expose({ name: 'company_names' })
  companyNames!: string[];
  @Expose({ name: 'admins_firm_id' })
  adminsFirmId!: number;

  selected?: boolean;
}

export class KindsCountModel {
  @Expose({ name: 'kind' })
  kind!: EUserKind;
  @Expose({ name: 'amount' })
  amount!: number;
}

export class ProjectsCountModel {
  @Expose({ name: 'project_id' })
  id!: number;
  @Expose({ name: 'project_name' })
  projectName!: string;
  @Expose({ name: 'amount' })
  amount!: number;

  selected?: boolean;
}

export class CompaniesCountModel {
  @Expose({ name: 'kind' })
  kind!: EUserKind;
  @Expose({ name: 'company_id' })
  id!: number;
  @Expose({ name: 'company_name' })
  companyName!: string;
  @Expose({ name: 'amount' })
  amount!: number;

  selected?: boolean;
}

export class RolesCountModel {
  @Expose({ name: 'kind' })
  kind!: EUserKind;
  @Expose({ name: 'role' })
  role!: EGlobalFirmRole;
  @Expose({ name: 'amount' })
  amount!: number;

  selected?: boolean;
}

export class GlobalFirmUsersList {
  @Expose({ name: 'characters' })
  @Type(() => GlobalFirmUser)
  users!: GlobalFirmUser[];

  @Expose({ name: 'characters_count_by_kinds' })
  @Type(() => KindsCountModel)
  kindsCount!: KindsCountModel[];

  @Expose({ name: 'characters_count_by_projects' })
  @Type(() => ProjectsCountModel)
  projectsCount!: ProjectsCountModel[];

  @Expose({ name: 'characters_count_by_companies' })
  @Type(() => CompaniesCountModel)
  companiesCount!: CompaniesCountModel[];

  @Expose({ name: 'characters_count_by_roles' })
  @Type(() => RolesCountModel)
  rolesCount!: RolesCountModel[];
}

export interface IGlobalFirmUsersCounters {
  kindsCount: KindsCountModel[];
  projectsCount: ProjectsCountModel[];
  companiesCount: CompaniesCountModel[];
  rolesCount: RolesCountModel[];
}

export class FirmBuyerUnits {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'user_role' })
  userRole!: string;
  @Expose({ name: 'main_owner' })
  mainOwner!: boolean;
}

export class FirmBuyerProjects {
  @Expose({ name: 'project_id' })
  projectId!: number;
  @Expose({ name: 'project_name' })
  projectName!: string;
  @Expose({ name: 'units' })
  @Type(() => FirmBuyerUnits)
  units!: FirmBuyerUnits[];
}

export interface IFirmBuyerProjects {
  projectId: number;
  projectName: string;
  unit: FirmBuyerUnits;
}

export class FIrmContractorCompanies {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'role' })
  role!: EContractorRoles;
  @Expose({ name: 'contractor_company_member_id' })
  contractorCompanyId!: number;
}
