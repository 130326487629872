import { Pipe, PipeTransform } from '@angular/core';
import { ETimeFormat } from '@atlas-workspace/shared/models';
import dayjs from 'dayjs';

@Pipe({
  name: 'dateTimePipe',
})
export class DateTimePipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value) value;

    const timeFormat = ETimeFormat.FormatEu;

    return `${dayjs(value).format('DD MMM YYYY')} • ${dayjs(value).format(timeFormat)}`;
  }
}
