<button
  #button
  (click)="onClickButton($event)"
  [disabled]="disabled"
  [class.global-button]="!newest"
  [class.btn-default-size]="!newest"
  [class.old-button]="!newest"
  [class.newest-button]="newest"
  [class.loading]="newest && loading"
  [ngClass]="addClass"
  [ngStyle]="{ width: newest && widthButton ? widthButton + 'px' : null }"
  [ngbTooltip]="tooltip"
  [openDelay]="tooltipDelay"
  [disableTooltip]="!showTooltip"
>
  <img *ngIf="loading" class="spinner" src="assets/spinner.svg" alt="Spinner" />
  {{ loading ? null : (label | translate) }}
</button>
