import { Expose, Type } from 'class-transformer';

export interface ICompany {
  id: number;
  title: string;
}

export interface IContactorCompanies {
  id: number;
  contractor_company_role: EContractorRoles;
  name: string;
  email: string;
  phone: string;
  registered: boolean;
  last_activity: string;
}

export interface IContactorCompaniesCreation {
  name: string;
  email: string;
  phone: string;
  contractor_company_role: EContractorRoles;
  invite_is_sent?: boolean;
}

export enum EContractorRoles {
  Member = 'member',
  KeyContact = 'key_contact',
}

export interface ICreateContractorCompany {
  contractor_company_role: EContractorRoles;
  name: string;
  email: string;
  phone: string;
  invite_is_sent?: boolean;
}

export interface IEditContractorCompany {
  contractor_company_role: EContractorRoles;
  name: string;
  email: string;
  phone: string;
}

export class CompanyModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'title' })
  title: string;

  constructor() {
    this.id = 0;
    this.title = '';
  }
}

export class ContractorProjectModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
}

export class ContractorCompanyModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'main_admin_id' }) mainAdminId!: number;
  @Expose({ name: 'contractor_company_role' }) contractorCompanyRole!: EContractorRoles;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'email' }) email!: string;
  @Expose({ name: 'phone' }) phone!: string;
  @Expose({ name: 'registered' }) registered!: boolean;
  @Expose({ name: 'last_activity' }) lastActivity!: string;
  @Expose({ name: 'invite_is_sent' }) inviteIsSent!: boolean;
  @Expose({ name: 'created_at' }) createdAt!: string;
  @Type(() => ContractorProjectModel)
  @Expose({ name: 'projects' }) projects!: ContractorProjectModel[];
  @Expose({ name: 'selected' }) selected?: boolean;
  @Expose({ name: 'invite_sent_at' }) inviteSentAt?: string;
}

export interface IContractorCompany {
  name: string;
  description: string;
  address?: string;
  longitude: string;
  latitude: string;
}

export class ContractorCompany {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'description' }) description!: string;
  @Expose({ name: 'address' }) address!: string;
  @Expose({ name: 'longitude' }) longitude!: string;
  @Expose({ name: 'latitude' }) latitude!: string;
  @Expose({ name: 'contractors_count' }) contractorsCount!: number;
  @Type(() => ContractorCompanyModel)
  @Expose({ name: 'key_contacts' })
  keyContacts!: ContractorCompanyModel[];
  @Expose({ name: 'main_default_recipient_id' })
  mainDefaultRecipientId!: number | null;
  @Expose({ name: 'default_recipient' })
  defaultRecipient!: DefaultCompanyRecipientModel | null;

  firstKeyContact?: ContractorCompanyModel;

  selected = false;
}

export class DefaultCompanyRecipientModel {
  id!: number;
  name!: string;
  email!: string;

  constructor(id: number, name: string, email: string) {
    this.id = id;
    this.name = name;
    this.email = email;
  }
}

export interface IDefaultCompanyRecipientResponce {
  id: number;
  recipient: DefaultCompanyRecipientModel;
  project: {
    id: number;
    name: string;
  } | null;
}
