<!--
  ******************************************************
  @Cypress
  - interaction with control
  - interaction with close button
  ******************************************************
-->
<div class="newest__input-wrapper" [ngClass]="lang">
  <input
    #input
    [ngClass]="{
      'newest__input--active': !!inputValue,
      'no-mobile': noMobile,
      'no-border': noBorderWhenHasValue,
      'newest__input--client': platform === platformTypes.WEB_CLIENT
    }"
    (input)="changeValue(input.value)"
    (focus)="checkFocus()"
    (blur)="checkBlur()"
    [disabled]="disabled"
    [mask]="mask"
    [ngModel]="inputValue"
    [placeholder]="placeholder | translate"
    [separatorLimit]="separatorLimit"
    [suffix]="suffix"
    [readonly]="readonly"
    placeholder="Search"
    type="text"
    class="newest__input"
    atlReplaceInput
  />
  <img 
    (click)="input.focus()" 
    class="newest__input-icon-search" 
    [class.newest__input-icon-search--client]="platform === platformTypes.WEB_CLIENT"
    src="/assets/search.svg" 
    alt="Search icon" 
  />
  <span
    *ngIf="showCloseIcon"
    (click)="onRemoveValue(); input.blur()"
    class="newest__input-icon-remove"
    data-cy="newest-input-close"
  >
    <img src="/assets/newest_remove_text_gray.svg" alt="Remove text" />
  </span>
</div>
