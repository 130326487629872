<div class="image-cropper__wrapper" (atlCloseModalEsc)="closeModal(true)">
  <div class="image-cropper__header">
    <span>{{ 'Shared.Entity.Crop_image' | translate }}</span>
    <div
      data-cy="cy-close-modal"
      (click)="closeModal(true)"
      class="button-icon black"
      [ngbTooltip]="'Alt.Close' | translate"
      [openDelay]="tooltipDelay"
    >
      <img src="assets/close_newest_gray.svg" alt="Close the task" />
    </div>
  </div>
  <div class="image-cropper__crop-area">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [imageFile]="originalImage"
      [maintainAspectRatio]="true"
      [aspectRatio]="16 / 9"
      [transform]="transform"
      (imageCropped)="imageCropped($event)"
      [autoCrop]="false"
      (imageLoaded)="imageLoaded()"
      [cropper]="cropper"
    >
    </image-cropper>
    <div class="image-cropper__zoom-range">
      <span class="image-cropper__zoom-btn fz-24 cursor-pointer user-select-none" (click)="decrementZoom()">-</span>
      <input
        class="image-cropper__range-input"
        type="range"
        #range
        min="0.1"
        max="1.9"
        step="0.1"
        [value]="scale"
        (input)="zoomChange(range.value)"
      />
      <span class="image-cropper__zoom-btn fz-24 cursor-pointer user-select-none" (click)="incrementZoom()">+</span>
    </div>
  </div>
  <div class="image-cropper__footer d-flex" [class.justify-content-end]="newConfirmBtnPosition">
    <button
      *ngIf="loading$ | async; else saveBlock"
      class="global-button"
      [ngClass]="newConfirmBtnPosition ? 'newest-button loading' : 'image-cropper__save'"
    >
      <img class="spinner image-cropper__save-spinner" [src]="spinnerImage" alt="spinner"/>
    </button>
  </div>
</div>

<ng-template #saveBlock>
  <button
    data-cy="cy-save-changes-btn"
    class="global-button"
    [ngClass]="newConfirmBtnPosition ? 'newest-button' : 'image-cropper__save'"
    (click)="closeModal(false)"
  >
    {{ confirmBtnText | translate }}
  </button>
</ng-template>
