<div
  #dropdown
  #dropdownRef="ngbDropdown"
  ngbDropdown
  dropdownClass=""
  atlLockField
  (isLocked)="isLocked($event)"
  [autoClose]="'outside'"
  [display]="'dynamic'"
  class="confirm-dropdown"
  [class.pointer-events-none]="locked"
  [placement]="placement"
  [container]="useBody ? 'body' : null"
>
  <div ngbDropdownToggle id="dropdown-{{ index }}" class="confirm-dropdown__button" [class.disabled]="disabled">
    <span class="confirm-dropdown__button-value" [class.mr-8]="value">
      <ng-container>
        <ng-template
          [ngTemplateOutlet]="useCustomButtonVal ? itemTemplate : itemLabelTemplate"
          [ngTemplateOutletContext]="{ item: value, selected: false }"
        ></ng-template>
      </ng-container>
    </span>
    <input
      *ngIf="searchKey.length"
      [value]="search"
      (input)="searchInput($event)"
      type="text"
      class="confirm-dropdown__button-input"
      [placeholder]="!item?.selected && !value ? placeholder : ''"
    />
    <img *ngIf="arrow" src="assets/arrow_down_option_store.svg" alt="arrow" />
  </div>
  <div
    atlZoom
    ngbDropdownMenu
    [attr.aria-labelledby]="'dropdown-' + index"
    class="confirm-dropdown__dropdown zoom-element"
    [style.min-width]="dropdown.clientWidth + 'px'"
    [style.max-width]="dropdown.clientWidth + 'px'"
    [ngClass]="customClass"
  >
    <div class="confirm-dropdown__dropdown-list v-scrollbar small">
      <ng-container *ngFor="let item of filterSearchList">
        <div (click)="selectItem(item)" class="confirm-dropdown__dropdown-item" [class.selected]="item?.selected">
          <ng-container>
            <ng-template
              [ngTemplateOutlet]="itemLabelTemplate"
              [ngTemplateOutletContext]="{ item: item, selected: item?.selected }"
            ></ng-template>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="confirm-dropdown__dropdown-footer">
      <button 
        (click)="confirm()" 
        [disabled]="!selectedItem && !allowEmptyVal" 
        type="button" 
        class="newest-button newest-button-sm"
        data-cy="confirm-dropdown"
      >
        {{ confirmTitle | translate }}
      </button>
    </div>
  </div>
</div>
