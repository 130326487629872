import { Expose, Type } from 'class-transformer';

import { FileModel } from './file.model';
import { ImageModel } from './image.model';
import { ProductListItemModelCPB } from './product.model';

export interface IUpdatePackage {
  optionId: number;
  name: string;
  description?: string;
  images: ImageModel[] | FileModel[];
  attributes: number[];
}

export class PackageModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'images' }) @Type(() => ImageModel) images!: ImageModel[];
  @Expose({ name: 'products' }) @Type(() => ProductListItemModelCPB) products!: ProductListItemModelCPB[];
}

export class PackageModelShort {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'price' }) price!: number;
}

export class CategoryProductModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'selected' })
  selected!: boolean;
  @Expose({ name: 'products' }) @Type(() => ProductListItemModelCPB) products!: ProductListItemModelCPB[];
}

export class ClientPackageModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'total_price' })
  totalPrice!: string;
  @Expose({ name: 'currency' })
  currency!: string;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'selected' })
  selected!: boolean;
  @Expose({ name: 'images' }) @Type(() => ImageModel) images!: ImageModel[];
  @Expose({ name: 'categories' }) @Type(() => CategoryProductModel) categories!: CategoryProductModel[];
}

export class ClientPackageDataModel {
  @Expose({ name: 'layout_option_packages' }) @Type(() => ClientPackageModel) packages!: ClientPackageModel[];
  @Expose({ name: 'show_category_packages' }) showCategoryPackages!: boolean;
}
