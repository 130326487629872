import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FileLoadService } from '@atlas-workspace/shared/service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IModalHeaderInterface } from '../../interfaces/modal-header.interface';
import { EPlatform } from '@atlas-workspace/shared/models';
import { WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';

@Component({
  selector: 'atl-default-modal',
  templateUrl: './default-modal.component.html',
  styleUrls: ['./default-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DefaultModalComponent implements OnInit {
  @Input() public headerData!: IModalHeaderInterface;
  @Input() public commentBtn = false;
  @Input() public constructionPhoto = false;
  @Input() private commentStatus = false;
  @Input() public showTitle = true;
  @Output() private toggleComment = new EventEmitter<boolean>();

  public isClient = false;

  readonly tooltipDelay = 500;

  constructor(
    private activeModal: NgbActiveModal, 
    private fileLoadService: FileLoadService,
    @Inject(WEB_PLATFORM_TYPE) private platformType: EPlatform,
  ) {}

  ngOnInit(): void {
    this.isClient = this.platformType === EPlatform.WEB_CLIENT;
  }

  public close(): void {
    this.activeModal.close();
  }

  public onToggleComment(): void {
    this.commentStatus = !this.commentStatus;
    this.toggleComment.emit(this.commentStatus);
  }

  public onCopyImage(): void {
    this.fileLoadService.copyFileFromModel({
      url: this.headerData.downloadLink!,
      name: this.headerData.title!,
    });
  }
}
