import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  ILockedTrigger,
  InputLabelTemplateDirective,
  InputTemplateDirective,
} from '@atlas-workspace/shared/directives';
import { DropdownPosition, NgSelectComponent } from '@ng-select/ng-select';
import emojiRegex from 'emoji-regex';

import acceptIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/dropdown_accept_circle.svg';

@Component({
  selector: 'atl-drop-down-v2',
  templateUrl: './drop-down-v2.component.html',
  styleUrls: ['./drop-down-v2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropDownV2Component),
      multi: true,
    },
  ],
})
export class DropDownV2Component implements ControlValueAccessor, AfterViewInit {
  inputValue!: any[];
  regex = emojiRegex() as RegExp;
  isOpen = false;
  selectedItem: any;

  savedValue = '';
  savedOriginalValue!: string;
  isActiveStaticCreation = false;
  invalidCreationInput = false;

  public acceptIcon = acceptIcon;
  public locked = false;

  @Input() listOfItems!: any[];
  @Input() bindLabel!: string;
  @Input() bindValue!: string;
  @Input() notFoundText!: string;

  @Input() multiple = false;
  @Input() searchParams: string[] = [];
  @Input() placeholder = '';
  @Input() focusedPlaceholder = '';
  @Input() useCustomItemView = false;
  @Input() useCustomLabelView = false;
  @Input() customUserForm = false;
  @Input() position: DropdownPosition = 'bottom';
  @Input() searchable = true;
  @Input() clearable = true;
  @Input() editableSearchTerm = false;
  @Input() groupBy?: string;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() initFocus = false;
  @Input() saveTermSearch = false;
  @Input() createNotFound = false;
  @Input() creation = false;
  @Input() creationTitle = 'Shared.Button.Create_new';
  @Input() creationInputTitle = '';
  @Input() tabIndex = 0;
  @Input() selectOnBlur = false;
  @Input() removable = false;
  @Input() savedItemId: number | undefined;
  @Input() closeOnSelect = true;
  @Input() trackByFn!: any;
  @Input() creationPattern?: RegExp;
  @Input() noItemsList = false;
  @Input() maxLength = 30;
  @Input() truncate!: number;
  @Input() sweechLockOff = false;

  @Output() emitValueSearch = new EventEmitter();
  @Output() clearDropDown = new EventEmitter();
  @Output() openDropDown = new EventEmitter();
  @Output() createUnfound = new EventEmitter();
  @Output() emitOnCreation = new EventEmitter();
  @Output() selectHandler = new EventEmitter<any>();

  @Output() private readonly emitOnRemove = new EventEmitter<number>();

  @ContentChild(InputTemplateDirective, { read: TemplateRef, static: false })
  itemTemplate: TemplateRef<ElementRef> | undefined;

  @ContentChild(InputLabelTemplateDirective, { read: TemplateRef, static: false })
  labelTemplate: TemplateRef<ElementRef> | undefined;

  @ViewChild('dropDownInput') dropDownInput!: NgSelectComponent;
  @ViewChild('creationInput') creationInput?: ElementRef;

  constructor(protected cdr: ChangeDetectorRef) {}

  onChange: (value: any) => void = () => null;
  onTouched: () => void = () => null;

  onBlur(): void {
    this.onTouched();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: any[]): void {
    this.inputValue = value;
  }

  isLocked(value: ILockedTrigger, ngSelect: NgSelectComponent): void {
    this.locked = value.locked ? !value.focused : value.locked;
    if (!this.locked) {
      ngSelect.blur();
    }
  }

  changeValue(value: any[]): void {
    if (value.length) {
      this.inputValue = this.multiple ? [...value] : [value[value.length - 1]];
    } else {
      this.inputValue = [];
    }

    if (this.selectOnBlur) {
      this.dropDownInput.blur();
    }

    this.onChange(this.inputValue);
    this.selectHandler.emit(this.inputValue);
    this.cdr.markForCheck();
  }

  ngAfterViewInit(): void {
    if (this.initFocus) {
      this.dropDownInput.focus();
    }
  }

  searchFn = (term: string, item: any): boolean => {
    if (this.searchParams.length) {
      return this.searchParams.some((x) => item[x]?.toLowerCase().includes(term.toLowerCase()));
    }
    return item[this.bindLabel].toLowerCase().includes(term.toLowerCase());
  };

  onRemoveValue(): void {
    this.clearDropDown.emit();
    this.savedValue = '';
    this.cdr.markForCheck();
  }

  changeInput(event: any): void {
    this.savedOriginalValue = event.target.value;
    const replaceEmoji = event.target.value.replaceAll(this.regex, '');
    const replaceCyrillic = replaceEmoji.replace(/[а-яА-ЯёЁ]/g, '');
    this.savedValue = event.target.value = replaceCyrillic;
  }

  initSearch(): void {
    if (this.savedValue) {
      this.dropDownInput.searchTerm = this.savedValue;
    } else if (this.searchable) {
      this.emitValueSearch.emit({ term: '' });
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  openDropDownHandler(): void {
    this.openDropDown.emit();
    this.isOpen = true;
  }

  onSaveSearchByClose(): void {
    this.isOpen = false;
    if (this.saveTermSearch && this.savedValue) {
      this.dropDownInput.searchTerm = this.savedValue;
    } else {
      this.savedValue = '';
    }
    this.closeCreation();
    this.isOpen = false;
    this.onBlur();
  }

  compareWith(item: any, selected: any): boolean {
    return item[this.bindValue] === selected[this.bindValue];
  }

  onRemoveSelectedItem(item: any): void {
    const filteredValue = this.inputValue.filter((a) => this.bindValue ? !this.compareWith(a, item) : a.id !== item.id);
    this.changeValue(filteredValue);
  }

  removeItem(item: any, e: Event): void {
    e?.stopImmediatePropagation();
    this.emitOnRemove.emit(item.id);
  }

  openCreation(): void {
    this.isActiveStaticCreation = true;
    setTimeout(() => this.creationInput?.nativeElement.focus(), 0);
  }

  closeCreation(): void {
    this.isActiveStaticCreation = false;
    if (this.creationInput) {
      this.creationInput.nativeElement.value = '';
    }
  }

  inputCreation(e: Event): void {
    e.stopPropagation();
    this.invalidCreationInput = false;
  }

  onCreation(term: string, e?: KeyboardEvent): void {
    if (e) {
      e.stopPropagation();
    }

    if (this.creationPattern && !this.creationPattern.test(term)) {
      this.invalidCreationInput = true;
      return;
    }

    if (term) {
      this.emitOnCreation.emit(term);
      this.dropDownInput.close();
    }
  }
}
