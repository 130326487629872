import {Expose, Transform, Type} from 'class-transformer';
import {EDocumentType} from "./file.model";
import {UnitModel, UnitUserModel} from "./unit.model";

export interface ICreateFDV {
  title?: string;
  parent_id?: number;
  filename_remote_url?: string;
  fileName?: string;
  fileSize?: number;
  fileExtension?: string;
  children: ICreateFDV[]
}

export class FdvPeople {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
}

export class ParentFolder {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'folder_name' })
  name!: string;
}

export class FdvModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'ancestry' })
  ancestry!: null;
  @Expose({ name: 'kind' })
  documentType!: EDocumentType;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'updated_at' })
  updatedAt!: string;
  @Expose({ name: 'folder_name' })
  name?: string;
  @Expose({ name: 'title' })
  title!: string;
  @Expose({ name: 'folder_size' })
  size?: number;
  @Expose({ name: 'creator' })
  @Type(() => FdvPeople)
  creator!: FdvPeople;
  @Expose({ name: 'updater' })
  @Type(() => FdvPeople)
  updater!: FdvPeople;
  @Expose({ name: 'children' })
  @Type(() => FdvModel)
  @Transform(({value}) => {
    return (value as FdvModel[])?.sort((a, b) =>
      a.documentType === EDocumentType.Folder ? -1 : 1) || [];
  })
  children!: FdvModel[];
  @Expose({ name: 'parents' })
  @Type(() => ParentFolder)
  @Transform(({value}) => {
    return (value as ParentFolder[])?.reverse() || [];
  })
  parents!: ParentFolder[];
  @Expose({ name: 'filename_remote_url' })

  filenameRemoteUrl?: string;
  @Expose({ name: 'file_size' })
  fileSize?: number;
  @Expose({ name: 'file_extension' })
  fileExtension?: string;
  @Expose({ name: 'filename' })
  filename?: {
    identifier: string;
    content_type: string;
    url: string;
    download_url: string
  }

  active!: boolean
  connected = false;
  selected = false;
  disabled = false;
}

export interface IUnitSelectFDV {
  unit: UnitUserModel | UnitModel;
  fdv: FdvModel[];
}

export type UnitsFDV = {
  [key: number]: IUnitSelectFDV;
}

export interface IMoveToRequest {
  folder_id: number | null;
  ids: number[]
}
