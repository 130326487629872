<div
  *ngIf="step"
  class="modal-arrow"
  [ngClass]="{
    one: step === 1,
    two: step === 2,
    three: step === 3,
    four: step === 4,
    five: step === 5,
    six: step === 6,
    mobile: isMobile
  }"
></div>
<div class="onboarding-modal">
  <div class="onboarding-modal__body">
    <div class="onboarding-modal__header">
      <div
        *ngIf="icon"
        class="onboarding-modal__icon"
        [ngClass]="{
        start: step === 0,
        step: step > 0
      }"
      >
        <img [src]="icon" alt="icon" />
      </div>
      <atl-header-icon
        *ngIf="step === 0"
        class="client"
        data-cy="close"
        (click)="skipAllSteps()"
        icon="/assets/close_newest_gray.svg"
        [ngbTooltip]="'Alt.Close' | translate"
        placement="bottom"
      ></atl-header-icon>
    </div>

    <div class="onboarding-modal__title">
      {{ title | translate }}
    </div>
    <div class="onboarding-modal__description">
      {{ description | translate }}
    </div>
  </div>

  <div class="onboarding-modal__footer">
    <ng-container *ngIf="step === 0">
      <span class="fz-14 lh-24"></span>
      <button (click)="nextStep()" class="newest-button h-px-32 fz-14 fw-500 next-button">
        {{ 'Title.Guided_tour' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="step > 0">
      <span class="fz-14 lh-24 text-gray-890">
        {{ step }} {{ 'Entity.Of' | translate }} {{ steps - 1 }}
        <span *ngIf="step !== steps - 1">
          ·
          <span (click)="skipAllSteps()" class="cursor-pointer underline">{{ 'Title.Skip_all' | translate }}</span>
        </span>
      </span>
      <div class="d-inline-flex">
        <button
          *ngIf="step > 1"
          (click)="prevStep()"
          class="newest-button newest-button--outline h-px-32 mr-8 fz-14 fw-500 onboarding-button"
        >
          {{ 'Title.Previous' | translate }}
        </button>
        <button *ngIf="step !== steps - 1" (click)="nextStep()" class="newest-button h-px-32 fz-14 fw-500 onboarding-button">
          {{ 'Title.Next' | translate }}
        </button>
        <button *ngIf="step === steps - 1" (click)="skipAllSteps()" class="newest-button h-px-32 fz-14 fw-500 onboarding-button">
          {{ 'Shared.Button.Done' | translate }}
        </button>
      </div>
    </ng-container>
  </div>
</div>
