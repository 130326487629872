import { Expose, Transform, Type } from 'class-transformer';

import { EMeetingEffectiveStatus, EMeetingStatus, EMeetingStatusValue } from '../interface/meeting-status.enum';
import { FileModel, FileNameModel } from './file.model';
import { ImageModel } from './image.model';
import { MembersModel } from './members.model';
import { ReclamationsModel } from './reclamations.model';
import { ITablePagination } from './table-pagination.interface';
import { UnitMemberModel } from './unit-member.model';
import { EFirmRoles } from './user-roles.model';

export interface TAttendeesPerson {
  id: number;
  protocolId: number;
  proxy: boolean;
  role: EAttendeesRole;
  signatureRequired: boolean;
  type: ESignerType;
  personId: number;
  name: string;
  email: string;
  attendeesId: number;
  signature: FileModel;
  note: string;
  hideInputName: string;
  fileResources: FileModel[];
  adminId?: number;
  offlineSignature?: string | ArrayBuffer | null;
}

export interface TProxyUser {
  name: string;
  email: string;
  note: string;
  files: File[];
}

export interface TSeparateSignature {
  existing: TAttendeesPerson[];
  acceptable: TAttendeesPerson[];
}

export class MeetingTypeModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'category' }) category!: EMeetingCategory;
  @Expose({ name: 'firm_id' }) firmId!: number | null;
}

export enum EMeetingCategory {
  Default = 'default',
  Custom = 'custom',
}

export interface IBodyMeetingModel {
  union_id: number;
  kind?: string;
  title: string;
  start_at_in_timezone: string;
  end_at_in_timezone: string;
  unit_ids: number[];
  message: string;
  invite_is_sent?: boolean;
  send_via_sms?: boolean;
  guest_ids?: ICreateGuestMeetingModel[];
  assignee_id?: number;
  protocols_template_id?: number;
  visible_to_unit_ids?: number[];
  visible_to_unit_group_ids?: number[];
}

export interface ICreateGuestMeetingModel {
  id: number;
}

export class MeetingPeople {
  id!: number;
  name!: string;
  disabled?: boolean;
  originalName?: string;
}

export class MeetingType {
  id!: number;
  name!: string;
  category!: string;
  @Expose({ name: 'localized_name' }) localizedName!: string;
}

export interface TProtocolRemoval {
  id: number | null;
  isProxy: boolean;
  personId: number | null;
}

export class MeetingUnitOwner {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'email' }) email!: string;
  @Expose({ name: 'main_owner' }) mainOwner!: boolean;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'user_role' }) userRole!: string;
}

export class MeetingUnit {
  id!: number;
  identifier!: string;
  @Expose({ name: 'primary_owners' }) @Type(() => MeetingUnitOwner) primaryOwners!: MeetingUnitOwner[];
}

export class MeetingUnitList {
  id!: number;
  status!: EMeetingStatusValue;
  @Expose({ name: 'unit' }) @Type(() => MeetingUnit) unit!: MeetingUnit;
  @Expose({ name: 'attendees_amount' }) attendeesAmount!: number;

  selected = false;
}

export enum EMeetingKind {
  Single = 'single',
  Group = 'group',
}

export class VisibleUnits {
  id!: number;
  identifier!: string;
}

export class VisibleGroup {
  id!: number;
  name!: string;
}

export class ClientMeetingUnitModel {
  id!: number;
  status!: EMeetingStatusValue;
  @Expose({ name: 'attendees_amount' }) attendeesAmount!: number;
  unit!: {
    id: number;
    identifier: string;
  };
}

export class ProxyUnitMemberModel extends UnitMemberModel {
  isProxy = false;
  hasAttachments = false;
  personId!: number;
  hideInputName = '';
}

export class ProxyMemberModel extends MembersModel {
  isProxy = false;
  hasAttachments = false;
  personId!: number;
  hideInputName = '';
}

export type MeetingUserTypes = ProxyUnitMemberModel | ProxyMemberModel | TAttendeesPerson;

export class ProtocolTemplateList {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'kind' }) kind!: string;
  @Expose({ name: 'localized_name' }) localizedName!: string;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'label' }) label!: string;
  @Expose({ name: 'firm_id' }) firmId!: string;
}

export class MeetingGuestAdminsFirms {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'main_admin_id' })
  main_admin_id!: number;
  @Expose({ name: 'firm_id' })
  firm_id!: number;
  @Expose({ name: 'role' })
  role!: EFirmRoles;
}

export class MeetingGuestPeople {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'phone' })
  phone!: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'updated_at' })
  updatedAt!: string;
  @Expose({ name: 'admins_firms' })
  @Type(() => MeetingGuestAdminsFirms)
  @Transform(({ value }) => value?.[0])
  adminsFirms!: MeetingGuestAdminsFirms;
}

export class MeetingModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'message' }) message!: string;
  @Expose({ name: 'start_at' }) startAt!: string;
  @Expose({ name: 'start_at_in_timezone' }) startAtTimezone!: string;
  @Expose({ name: 'end_at' }) endAt!: string;
  @Expose({ name: 'end_at_in_timezone' }) endAtTimezone!: string;
  @Expose({ name: 'created_at' }) createdAt!: string;
  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'assignee' }) @Type(() => MeetingPeople) assignee!: MeetingPeople;
  @Expose({ name: 'meeting_type' }) @Type(() => MeetingType) meetingType!: MeetingType;
  @Expose({ name: 'meetings_union' }) meetingsUnion!: { id: number };
  @Expose({ name: 'meetings_units' }) @Type(() => MeetingUnitList) units!: MeetingUnitList[];

  @Expose({ name: 'guests' }) @Type(() => MeetingGuestPeople) guests!: MeetingGuestPeople[];
  @Expose({ name: 'buyers' }) @Type(() => MeetingPeople) buyers!: MeetingPeople[];
  @Expose({ name: 'buyers_count' }) buyersCount!: number;
  @Expose({ name: 'guests_count' }) guestsCount!: number;
  @Expose({ name: 'invite_is_sent' }) invite!: boolean;
  @Expose({ name: 'protocol_available' }) protocolAvailable!: boolean;
  @Expose({ name: 'kind' }) kind!: EMeetingKind;
  @Expose({ name: 'max_units' }) maxUnits!: number;
  @Expose({ name: 'visible_to_unit_groups' }) visibleToUnitGroups!: VisibleGroup[];
  @Expose({ name: 'visible_to_units' }) visibleToUnits!: VisibleUnits[];
  @Expose({ name: 'protocols_template' }) @Type(() => ProtocolTemplateList) protocolsTemplate!: ProtocolTemplateList;
  @Expose({ name: 'title' }) title!: string;

  @Expose({ name: 'protocol' }) protocol!: {
    id: number;
    identifier: string;
  };
}

export class MeetingsModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'message' }) message!: string;
  @Expose({ name: 'start_at' }) startAt!: string;
  @Expose({ name: 'start_at_in_timezone' }) startAtTimezone!: string;
  @Expose({ name: 'end_at' }) endAt!: string;
  @Expose({ name: 'end_at_in_timezone' }) endAtTimezone!: string;
  @Expose({ name: 'status' }) status!: EMeetingStatusValue;
  @Expose({ name: 'assignee' }) @Type(() => MeetingPeople) assignee!: MeetingPeople;
  @Expose({ name: 'meeting_type' }) @Type(() => MeetingType) meetingType!: MeetingType;
  @Expose({ name: 'meetings_units' }) @Type(() => MeetingUnitList) units!: MeetingUnitList[];
  @Expose({ name: 'meetings_union' }) meetingsUnion!: { id: number };
  @Expose({ name: 'invite_is_sent' }) inviteIsSent!: boolean;
  @Expose({ name: 'kind' }) kind!: EMeetingKind;
  @Expose({ name: 'max_units' }) maxUnits!: number;
  @Expose({ name: 'visible_to_unit_groups' }) visibleToUnitGroups!: VisibleGroup[];
  @Expose({ name: 'visible_to_units' }) visibleToUnits!: VisibleUnits[];
  @Expose({ name: 'title' }) title!: string;
  @Expose({ name: 'protocol_available' }) protocolAvailable!: boolean;
  @Expose({ name: 'protocols_template' }) @Type(() => ProtocolTemplateList) protocolsTemplate?: ProtocolTemplateList;

  selected = false;
}

export type MeetingsCountersType = {
  [key in EMeetingStatusValue]: number;
};

export type MeetingsFilterParamType = {
  [key in EMeetingStatusValue]: boolean;
};

export class MeetingsResponseModel {
  @Expose({ name: 'today_meetings_count' })
  todayMeetingsCount!: number;

  @Expose({ name: 'meetings' })
  @Type(() => MeetingsModel)
  meetings!: MeetingsModel[];
  @Expose({ name: 'status_stats' })
  @Transform(({ value }) => {
    return (<{ name: EMeetingStatusValue; count: number }[]>value).reduce(
      (counters, item) => {
        counters[item.name] = item.count;
        return counters;
      },
      <MeetingsCountersType>{},
    );
  })
  counters!: MeetingsCountersType;
}

export class ClientMeetingModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'message' }) message!: string;
  @Expose({ name: 'start_at' }) startAt!: string;
  @Expose({ name: 'start_at_in_timezone' }) startAtTimezone!: string;
  @Expose({ name: 'end_at' }) endAt!: string;
  @Expose({ name: 'end_at_in_timezone' }) endAtTimezone!: string;
  @Expose({ name: 'status' }) status!: EMeetingStatusValue;
  @Expose({ name: 'assignee' }) @Type(() => MeetingPeople) assignee!: MeetingPeople;
  @Expose({ name: 'meeting_type' }) @Type(() => MeetingType) meetingType!: MeetingType;
  @Expose({ name: 'meetings_unit' }) @Type(() => ClientMeetingUnitModel) units!: ClientMeetingUnitModel;
  @Expose({ name: 'meetings_union' }) meetingsUnion!: { id: number };
  @Expose({ name: 'guests' }) @Type(() => MeetingPeople) guests!: MeetingPeople[];
  @Expose({ name: 'buyers' }) @Type(() => MeetingPeople) buyers!: MeetingPeople[];
  @Expose({ name: 'kind' }) kind!: EMeetingKind;
  @Expose({ name: 'guests_count' }) guestsCount!: number;
  @Expose({ name: 'max_units' }) maxUnits!: number | null;
  @Expose({ name: 'title' }) title!: string;
  @Expose({ name: 'effective_status' }) effectiveStatus!: EMeetingEffectiveStatus;
}

export class ClientMeetingsModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'message' }) message!: string;
  @Expose({ name: 'start_at' }) startAt!: string;
  @Expose({ name: 'start_at_in_timezone' }) startAtTimezone!: string;
  @Expose({ name: 'end_at' }) endAt!: string;
  @Expose({ name: 'end_at_in_timezone' }) endAtTimezone!: string;
  @Expose({ name: 'status' }) status!: EMeetingStatusValue;
  @Expose({ name: 'assignee' }) @Type(() => MeetingPeople) assignee!: MeetingPeople;
  @Expose({ name: 'meeting_type' }) @Type(() => MeetingType) meetingType!: MeetingType;
  @Expose({ name: 'meetings_unit' }) @Type(() => ClientMeetingUnitModel) units!: ClientMeetingUnitModel;
  @Expose({ name: 'meetings_union' }) meetingsUnion!: { id: number };
  @Expose({ name: 'kind' }) kind!: EMeetingKind;
  @Expose({ name: 'max_units' }) maxUnits!: number;
  @Expose({ name: 'title' }) title!: string;
}

export interface IMeetingsCount {
  [Key: string]: { count: number; localizedName: string };
}

export class ClientMeetingsResponseModel {
  @Expose({ name: 'meetings' })
  @Type(() => ClientMeetingsModel)
  meetings!: ClientMeetingsModel[];
  @Expose({ name: 'status_stats' })
  @Transform(({ value }) => {
    return value.reduce((acc: IMeetingsCount, val: any) => {
      acc[val.name] = { count: val.count, localizedName: val.localized_name };
      return acc;
    }, {});
  })
  statusStats!: IMeetingsCount;
}

export const statusList = {
  [EMeetingStatusValue.Today]: {
    style: 'today',
    title: EMeetingStatus.Today,
  },
  [EMeetingStatusValue.Available]: {
    style: 'available',
    title: EMeetingStatus.Available,
  },
  [EMeetingStatusValue.Confirmed]: {
    style: 'booked',
    title: EMeetingStatus.Booked,
  },
  [EMeetingStatusValue.Completed]: {
    style: 'completed',
    title: EMeetingStatus.Completed,
  },
  [EMeetingStatusValue.Invite]: {
    style: 'invited',
    title: EMeetingStatus.Invited,
  },
  [EMeetingStatusValue.Declined]: {
    style: 'declined',
    title: EMeetingStatus.Declined,
  },
  [EMeetingStatusValue.Filled]: {
    style: 'booked',
    title: EMeetingStatus.Booked,
  },
};

export interface IGetClientMeetingsProps {
  unitId: string,
  sort: string,
  paginate?: ITablePagination,
  distinctOn: boolean,
  unionId?: number,
  statuses?: string[],
  typeIds?: number[],
  search: string,
}

class ComputedProtocolData {
  @Expose({ name: 'buyer' }) buyer!: string;
  @Expose({ name: 'protocol_date' }) protocolDate!: string;
  @Expose({ name: 'protocol_name' }) protocolName!: string;
  @Expose({ name: 'seller' }) seller!: string;
  @Expose({ name: 'unit_identifier' }) unitIdentifier!: string;
  @Expose({ name: 'unit_address' }) unitAddress!: string;
  @Expose({ name: 'meeting_type' }) meetingType!: string;
  @Expose({ name: 'unit_ids' }) unitIds!: number[];
}

export class ProtocolAnswers {
  @Expose({ name: 'field_id' }) fieldId!: number;
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'protocol_id' }) protocolId!: number;
  @Expose({ name: 'value' }) value!: string;
  @Expose({ name: 'uploaded_images' })
  @Type(() => ImageModel)
  uploadedImages!: ImageModel[];
}

class ProtocolMeeting {
  @Expose({ name: 'end_at' }) endAt!: string;
  assignee!: {
    id: number;
    name: string;
  };
  @Expose({ name: 'id' }) id!: number;
}

export class ProtocolSigner {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'type' }) type!: string;
  @Expose({ name: 'signature' }) signature!: string;
}

class ProtocolSigners {
  @Expose({ name: 'buyers' }) @Type(() => ProtocolSigner) buyers!: ProtocolAnswers[];

  @Expose({ name: 'guests' }) @Type(() => ProtocolSigner) guests!: ProtocolAnswers[];

  @Expose({ name: 'seller' }) @Type(() => ProtocolSigner) seller!: ProtocolAnswers[];
}

class SignatureImage {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) mane!: string;
  @Expose({ name: 'position' }) position!: number;
  @Expose({ name: 'type' }) type!: string;
  @Expose({ name: 'filename' }) @Type(() => FileNameModel) fileName!: FileNameModel;
}

export class ProtocolSignatures {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'protocol_id' }) protocolId!: number;
  @Expose({ name: 'signer_id' }) signerId!: number;
  @Expose({ name: 'signer_type' }) signerType!: string;

  @Expose({ name: 'image' }) @Type(() => SignatureImage) image!: SignatureImage;
}

export class AttendeesPersonModel {
  @Expose({ name: 'type' }) type!: ESignerType;
  @Expose({ name: 'id' }) personId!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'email' }) email!: string;
  @Expose({ name: 'note' }) note!: string;
  @Expose({ name: 'file_resources' }) @Type(() => FileModel) fileResources: FileModel[] = [];
}

export class AttendeesModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'protocol_id' }) protocolId!: number;
  @Expose({ name: 'proxy?' }) proxy!: boolean;
  @Expose({ name: 'role' }) role!: EAttendeesRole;
  @Expose({ name: 'signature' }) @Type(() => FileModel) signature!: FileModel;
  @Expose({ name: 'signature_required?' }) signatureRequired!: boolean;
  @Expose({ name: 'person' }) @Type(() => AttendeesPersonModel) person!: AttendeesPersonModel;
}

export class ProtocolTemplateSectionField {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'label' }) label!: string;
  @Expose({ name: 'category' }) category!: EProtocolSectionCategory;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'position' }) position!: number;
  @Expose({ name: 'section_id' }) sectionId!: number;
  @Expose({ name: 'answer_required' }) answerRequired!: boolean;
  @Expose({ name: 'dependence' }) dependence!: {
    [key: string]: any;
  };

  @Expose({ name: 'default_value' }) defaultValue?: string;
}

export class ProtocolTemplateSection {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'label' }) label!: string;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'position' }) position!: number;
  @Expose({ name: 'template_id' }) templateId!: number;

  @Expose({ name: 'fields' })
  @Type(() => ProtocolTemplateSectionField)
  fields!: ProtocolTemplateSectionField[];
}

class ProtocolTemplate {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'kind' }) kind!: string;
  @Expose({ name: 'localized_name' }) localizedName!: string;
  @Expose({ name: 'name' }) name!: string;

  @Expose({ name: 'sections' })
  @Type(() => ProtocolTemplateSection)
  sections!: ProtocolTemplateSection[];
}

//toDO: update the model with the latest changes
export class MeetingProtocolModel {
  @Expose({ name: 'answers' }) @Type(() => ProtocolAnswers) answers!: ProtocolAnswers[];

  @Expose({ name: 'computed' }) @Type(() => ComputedProtocolData) computed!: ComputedProtocolData;

  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'identifier' }) identifier!: string | null;

  @Expose({ name: 'meeting' }) @Type(() => ProtocolMeeting) meeting!: ProtocolMeeting;

  @Expose({ name: 'reclamations' }) @Type(() => ReclamationsModel) reclamations!: ReclamationsModel[];

  @Expose({ name: 'message' }) message!: string;
  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'other_attendees' }) otherAttendees!: string;
  @Expose({ name: 'other_recipients' })
  @Transform(({ value }) => {
    return value.filter((i: string) => i.length);
  })
  otherRecipients!: string[];

  @Expose({ name: 'signers' }) @Type(() => ProtocolSigners) signers!: ProtocolSigners;

  @Expose({ name: 'template' })
  @Type(() => ProtocolTemplate)
  template!: ProtocolTemplate;
  @Expose({ name: 'signatures' }) @Type(() => ProtocolSignatures) signatures!: ProtocolSignatures[];

  @Expose({ name: 'attendees' }) @Type(() => AttendeesModel) attendees!: AttendeesModel[];
  @Expose({ name: 'ready_to_complete?' }) readyToComplete!: boolean;

  //ToDO: add type
  @Expose({ name: 'labels' }) labels!: any;

  @Expose({ name: 'connected_to_protocols' }) connectedToProtocols!: {
    id: number;
    identifier: string;
    name: string;
  }[];
}

export enum ESignerType {
  User = 'User',
  MainAdmin = 'MainAdmin',
}

export enum EMeetingProtocol {
  Completed = 'completed',
  InProgress = 'in_progress',
}

export enum EAttendeesRole {
  Buyer = 'buyer',
  Seller = 'seller',
  Contractor = 'contractor',
}

export enum ESectionName {
  Reclamations = 'reclamations',
  Inspection = 'inspection',
  Introduction = 'introduction',
  Attendees = 'attendees',
  Signatures = 'signatures',
  AttendeeList = 'attendee_list',
  GeneralInfo = 'general_info',
  GeneralSpecification = 'general_specification',
  ElectricityFortum = 'electricity_fortum',
  SettlementConfirmation = 'settlement_confirmation',
  ElectricityMeter = 'electricity_meter',
}

export enum EProtocolSectionCategory {
  Custom = 'custom',
  Static = 'static',
  Integer = 'integer',
  Float = 'float',
  Text = 'text',
  Boolean = 'boolean',
  String = 'string',
  SingleSelect = 'single_select',
  BooleanInline = 'boolean_inline',
  Payment = 'payment',
  Date = 'date',
  Image = 'image',
  UploadedImages = 'uploaded_images',
}

export enum EFormKey {
  Assignee = 'assignee',
  TypeMeeting = 'meetingType',
  Units = 'units',
  Date = 'date',
  Guest = 'guest',
  Message = 'message',
  Invite = 'invite',
  Title = 'title',
  Protocol = 'protocol',
  VisibleFor = 'visibleFor',
  Buyer = 'buyer',
  MaxUnits = 'maxUnits',
  AttendeesAmount = 'attendeesAmount',
}

export enum EBookingType {
  Single = 'single',
  Multi = 'group',
  Invite = 'invite',
}

export interface IBookingType {
  title: string;
  value: EBookingType;
  body: EMeetingKind;
}

export const bookingTypeList: IBookingType[] = [
  { title: 'Meeting.Modal.Booking_Type.Single', value: EBookingType.Single, body: EMeetingKind.Single },
  { title: 'Meeting.Modal.Booking_Type.Multi', value: EBookingType.Multi, body: EMeetingKind.Group },
  { title: 'Meeting.Modal.Booking_Type.Invite', value: EBookingType.Invite, body: EMeetingKind.Single },
];

export interface IBatchMeetingsAttributes {
  start_at_in_timezone: string;
  end_at_in_timezone: string;
  max_units?: number | null;
  unit_ids?: number[];
  kind: EMeetingKind;
}

export interface IBatchCreate {
  meetings_union: {
    kind?: EMeetingKind;
    title: string;
    message: string;
    meeting_type_id?: number;
    assignee_id: number;
    protocols_template_id: number;
    invite_is_sent: boolean;
    guest_ids: number[];
    visible_to_unit_ids: number[];
    visible_to_unit_group_ids: number[];
    confirmation: boolean;
    meetings_attributes: IBatchMeetingsAttributes[];
    send_via_sms: boolean;
  };
}

export enum EChangeStatusURL {
  Cansel = 'cancel_booking',
  Confirm = 'confirm_booking',
  Decline = 'decline_booking',
}

export interface IRequestAttendees {
  id: number;
  attendees_amount: number;
}

export interface IRequestMeetingUpdate {
  start_at?: string;
  end_at?: string;
  meeting_type_id?: number;
  kind?: string;
  title?: string;
  max_units?: number;
  message?: string;
  unit_ids?: number[];
  assignee_id?: number;
  invite_is_sent?: boolean;
  guest_ids?: number[];
  visible_to_unit_ids?: number[];
  visible_to_unit_group_ids?: number[];
  meetings_units_attributes?: IRequestAttendees[];
}

export class HomeMeetingModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'end_at_in_timezone' }) endAt!: string;
  @Expose({ name: 'kind' }) kind!: string;
  @Expose({ name: 'start_at_in_timezone' }) startAt!: string;
  @Expose({ name: 'title' }) title!: string;
  @Expose({ name: 'project' }) project!: {
    id: number;
    name: string;
  };
  @Expose({ name: 'assignee' }) assignee!: {
    id: number;
    name: string;
  };
  @Expose({ name: 'meetings_units' }) meetingsUnits!: {
    id: number;
    status: string;
    unit: {
      id: number;
      identifier: string;
      primary_owners: {
        email: string;
        id: number;
        main_owner: boolean;
        name: string;
        user_role: string;
      };
    };
  }[];
}

export class MeetingsDownloadModel {
  @Expose({ name: 'content_type' }) contentType!: string;
  @Expose({ name: 'download_url' }) downloadUrl!: string;
  @Expose({ name: 'file_extension' }) fileExtension!: string;
  @Expose({ name: 'file_size' }) fileSize!: number;
  @Expose({ name: 'filename' }) filename!: string;
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'project_id' }) projectId!: number;
  @Expose({ name: 'url' }) url!: string;
}

export class UnionListModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'title' }) title!: string;
  @Expose({ name: 'created_at' }) createdAt!: string;
  @Expose({ name: 'project_id' }) projectId!: number;
  @Expose({ name: 'attendees_number' }) attendeesNumber!: number;
  @Expose({ name: 'meetings_number' }) meetingsNumber!: number;
  @Expose({ name: 'filled_meetings_number' }) filledMeetingsNumber!: number;
  @Expose({ name: 'confirmed_bookings_number' }) confirmedBookingsNumber!: number;
  @Expose({ name: 'invite_is_sent' }) inviteIsSent!: boolean | null;
  @Expose({ name: 'assignee' }) assignee!: {
    id: number;
    name: string;
    email: string;
    phone: string;
  } | null;
}

export interface IUnionMeetingsLisResponse {
  meetings: UnionListModel[];
  pagination: ITablePagination;
}

export class UnionMeetingModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'project_id' }) projectId!: number;
  @Expose({ name: 'title' }) title!: string;
  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'start_at' }) startAt!: string;
  @Expose({ name: 'start_at_in_timezone' }) startAtTimezone!: string;
  @Expose({ name: 'end_at' }) endAt!: string;
  @Expose({ name: 'end_at_in_timezone' }) endAtTimezone!: string;
  @Expose({ name: 'kind' }) kind!: EMeetingKind;
  @Expose({ name: 'assignee' }) @Type(() => MeetingPeople) assignee!: MeetingPeople;
  @Expose({ name: 'guests' }) @Type(() => MeetingGuestPeople) guests!: MeetingGuestPeople[];
  @Expose({ name: 'meetings_units' }) @Type(() => MeetingUnitList) units!: MeetingUnitList[];
}

export class UnionModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'title' }) title!: string;
  @Expose({ name: 'created_at' }) createdAt!: string;
  @Expose({ name: 'project_id' }) projectId!: number;
  @Expose({ name: 'message' }) @Transform(({ value }) => value || '') message!: string;
  @Expose({ name: 'attendees_number' }) attendeesNumber!: number;
  @Expose({ name: 'meetings_number' }) meetingsNumber!: number;
  @Expose({ name: 'filled_meetings_number' }) filledMeetingsNumber!: number;
  @Expose({ name: 'invite_is_sent' }) inviteIsSent!: boolean | null;
  @Expose({ name: 'send_via_sms' }) sendViaSms!: boolean | null;
  @Expose({ name: 'assignee' }) @Type(() => MeetingPeople) assignee!: MeetingPeople;
  @Expose({ name: 'protocols_template' })
  @Type(() => ProtocolTemplateList)
  protocolsTemplate!: ProtocolTemplateList | null;
  @Expose({ name: 'meetings' }) @Type(() => MeetingModel) meetings!: MeetingModel[];
  @Expose({ name: 'guests' }) @Type(() => MeetingGuestPeople) guests!: MeetingGuestPeople[];
  @Expose({ name: 'visible_to_unit_groups' })
  @Transform(({ value }) => value.map((v: any) => v.id))
  unitGroupsIds!: number[];
  @Expose({ name: 'visible_to_units' })
  @Transform(({ value }) => value.map((v: any) => v.id))
  unitsIds!: number[];
}

export enum EVisibleForHeaderTab {
  Units = 'Title.Units',
  UnitsGroup = 'Title.Unit__groups',
}

export const visibleForHeaderMenu = [
  {
    name: EVisibleForHeaderTab.Units,
    disabled: false,
  },
  {
    name: EVisibleForHeaderTab.UnitsGroup,
    disabled: false,
  },
];

export class AssigneeCounterModel {
  @Expose({ name: 'amount' }) count!: number
  @Expose({ name: 'assignee_id' }) id!: number | string | null;

  name: string | null = null;
}

export class MeetingsFilterCounter {
  @Expose({ name: 'assignee' })
  @Type(() => AssigneeCounterModel)
  assignee!: AssigneeCounterModel[];
}
