import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReclamationHelperService {
  private updateUnitListCounters$ = new Subject<boolean>();
  private openSettingsModal$ = new Subject<boolean>();

  get updateUnitListCountersEvent$(): Observable<boolean> {
    return this.updateUnitListCounters$.asObservable();
  }

  public triggerUnitListCounter(): void {
    this.updateUnitListCounters$.next(true);
  }

  get openSettingsModalEvent$(): Observable<boolean> {
    return this.openSettingsModal$.asObservable();
  }

  public triggerOpenSettingsModal(): void {
    this.openSettingsModal$.next(true);
  }
}
