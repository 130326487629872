<div class="notification">
  <div class="notification__header d-flex justify-content-between align-items-center">
    <h3 class="fz-16 fw-500 lh-24 notification__title ml-24">{{ title }}</h3>
    <img (click)="closeModal()" src="/assets/close_newest_gray.svg" alt="icon" class="cursor-pointer mr-24" />
  </div>

  <div class="fz-16 lh-24 notification__desc">
    {{ description }}
  </div>
</div>
