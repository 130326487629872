<div (clickOutside)="close()" atlClickOutside class="global-label">
  <span *ngIf="inputTitle" class="label"> {{ inputTitle }} </span>
  <div class="dropdown" #relativeRef>
    <button
      (click)="toggle()"
      *ngIf="!anchoredButton; else anchoredButtonRef"
      [class.opened]="isOpened"
      [disabled]="disabled"
      class="dropdown__main-container"
      type="button"
    >
      <ng-template #placeholderRef
        ><span class="dropdown__placeholder">{{ placeholder || label }}</span></ng-template
      >
      <span *ngIf="inputValue && selectedItem; else placeholderRef" class="dropdown__value">{{
        selectedItem[bindLabel]
      }}</span>
      <img [class.dropdown__rotate-icon]="isOpened" alt="" class="dropdown__icon" src="assets/arrow-down-sm.svg" />
    </button>
    <div
      [hidden]="!isOpened"
      class="dropdown__popup-container"
      atlVerticalAlign
      [enableVerticalAlign]="enableVerticalAlign"
      [marginTop]="dropdownMargin"
      [positionFixed]="dropdownPosition === 'fixed'"
      [relativeElement]="relativeRef"
      [topOffset]="topOffset"
      [leftOffset]="leftOffset"
    >
      <div class="gap-wrapper">
        <div class="d-flex flex-column">
          <div *ngIf="autocomplete" class="">
            <input
              #searchInput
              (input)="autocompleteChanged(searchInput.value)"
              class="dropdown__popup-search-field"
              type="text"
              [placeholder]="placeholderInputSearch | translate"
              atlReplaceInput
            />
          </div>
          <div
            *ngIf="autocompleteItems?.length || listOfItems?.length"
            class="gap-wrapper dropdown__items-wrapper v-scrollbar"
          >
            <div class="d-flex flex-column">
              <div
                *ngFor="let item of autocompleteItems || listOfItems; index as i; trackBy: trackByFn"
                class="gap-wrapper dropdown__item"
              >
                <button
                  (click)="changeValue(item)"
                  [class.selected]="item[bindValue] === inputValue"
                  [value]="item[bindValue]"
                  class="dropdown__popup-item fz-14 lh-24"
                  type="button"
                >
                  <span>{{ item[bindLabel] }}</span>
                  <img
                    (click)="handleItemDelete(item, $event)"
                    *ngIf="item[itemCouldBeDeletedFlag] == itemCouldBeDeletedValue"
                    height="20"
                    src="assets/trash-icon-sm.svg"
                    width="20"
                    alt="trash"
                  />
                </button>
              </div>
            </div>
          </div>

          <div
            *ngIf="searchInput?.nativeElement?.value && !autocompleteItems?.length"
            class="d-flex align-items-center justify-content-center h-150"
          >
            <span class="fz-14"
              >{{ 'Shared.Entity.No_mathes_for' | translate }} "{{
                searchInput.nativeElement.value | truncate: 15
              }}"</span
            >
          </div>
        </div>
      </div>

      <div *ngIf="addingAllowed" class="dropdown__add-new-item">
        <button
          (click)="showAddNewItemInput($event)"
          *ngIf="!newItemCreating; else addNewItemRef"
          class="dropdown__popup-item add-new"
          type="button"
        >
          <img alt="plus icon" class="plus-icon" src="assets/plus-blue.svg" />
          <span class="label-text">
            {{ addButtonLabel | translate }}
            <span class="text-lowercase">{{ customButtonText || label }}</span></span
          >
        </button>
        <ng-template #addNewItemRef>
          <div class="position-relative">
            <input
              (input)="inputNewItem($event.target.value)"
              #inputNewItemRef
              class="new-item-input"
              atlReplaceInput
            />
            <button
              (click)="addItemToExistingList($event, inputNewItemRef.value)"
              class="new-item-button"
              type="button"
              [disabled]="buttonDisabled"
            >
              <img
                alt="plus icon"
                class="plus-icon"
                src="assets/{{ buttonDisabled ? 'plus-button-gray-disable.svg' : 'plus-blue.svg' }}"
              />
              <span class="label-text">{{ 'Shared.Button.Add' | translate }}</span>
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #anchoredButtonRef>
  <button (click)="toggle()" class="anchored-button" type="button">
    <ng-content select="anchoredButton"></ng-content>
  </button>
</ng-template>
