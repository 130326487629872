import { ChangeDetectionStrategy, Component } from '@angular/core';

import { NotificationModalComponent } from '../notification-modal/notification-modal.component';

@Component({
  selector: 'atl-nnotification-modal-v2',
  templateUrl: './notification-modal-v2.component.html',
  styleUrls: ['./notification-modal-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewNotificationModalComponent extends NotificationModalComponent {}
