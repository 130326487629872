import { Component } from '@angular/core';

import { CompleteProtocolModalComponent } from '../complete-protocol-modal/complete-protocol-modal.component';

@Component({
  selector: 'atl-confirm-signicat',
  templateUrl: './confirm-signicat.component.html',
  styleUrls: ['./confirm-signicat.component.scss'],
})
export class ConfirmSignicatComponent extends CompleteProtocolModalComponent {}
