/* eslint-disable @typescript-eslint/no-invalid-this */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  GroupTemplateDirective, ILockedTrigger,
  InputHeaderDirective,
  InputNotFoundDirective,
  InputTemplateDirective,
  InputTranslateTemplateDirective,
  MultiInputTemplateDirective,
} from '@atlas-workspace/shared/directives';
import { CommonHelper } from '@atlas-workspace/shared/service';
import { DropdownPosition, NgSelectComponent } from '@ng-select/ng-select';
import emojiRegex from 'emoji-regex';
import { of } from 'rxjs';
import { delay, take } from 'rxjs/operators';

//@ts-ignore
import closeSvg from '!!raw-loader?!@atlas-workspace/shared/assets/lib/checkbox-cross.svg';

@Component({
  selector: 'atl-drop-down-writing',
  templateUrl: './drop-down-writing.component.html',
  styleUrls: ['./drop-down-writing.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropDownWritingComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropDownWritingComponent implements ControlValueAccessor, OnChanges, AfterViewInit {
  inputValue!: string;
  regex = emojiRegex() as RegExp;

  @Input() asPrimitiveSelector = false;
  @Input() inputTitle!: string;
  @Input() listOfItems!: any[];
  @Input() notFoundText!: string;
  @Input() isSpecialStyle = false;
  @Input() customItemView = false;
  @Input() customLabelView = false;
  @Input() customLabelTranslateView = false;
  @Input() customUserForm = false;
  @Input() customHeader = false;
  @Input() emptyValueHideHeader = false;
  @Input() headerInfo!: string;
  @Input() position: DropdownPosition = 'bottom';
  @Input() creatioTitle = '';
  @Input() bindLabel!: string;
  @Input() bindSecondLabel!: string;
  @Input() bindValue!: string;
  @Input() searchLabel!: string;
  @Input() searchable = true;
  @Input() clearable = true;
  @Input() editableSearchTerm = false;
  @Input() groupBy?: string;
  @Input() placeholder?: string;
  @Input() label = '';
  @Input() appendTo = '';
  @Input() customNotFoundView = false;

  @Input() disabled = false;
  @Input() tabIndex = 0;

  @Input() isShowMoreInfo = false;
  @Input() searchParams: string[] = [];
  @Input() initFocus = false;
  @Input() openDropdownHandle = false;
  @Input() chips = false;
  @Input() saveTermSearch = false;
  @Input() autoResizeInput = false;
  @Input() maxLength = 256;

  @Input() removable = false;
  @Input() serverSideSearch = false;
  @Input() useInfiniteScroll = false;
  @Input() threshold = 0.5;
  @Input() showPreview = false;

  @Output() protected readonly emitValueSearch = new EventEmitter();
  @Output() protected readonly itemSelected = new EventEmitter<number>();
  @Output() protected readonly clear = new EventEmitter();
  @Output() protected readonly changeSelectedValue = new EventEmitter<any>();
  @Output() protected readonly emitOpenDropdown = new EventEmitter();
  @Output() protected readonly emitOnCreation = new EventEmitter();
  @Output() protected readonly dropdownScrollOnBottom = new EventEmitter<{ search: string }>();

  selectedItem: any;
  closeSvg = closeSvg;
  locked = false;
  isOpen = false;
  isActiveStaticCreation = false;
  @ContentChild(InputTemplateDirective, { read: TemplateRef, static: false })
  valueTemplate: TemplateRef<ElementRef> | undefined;

  @ContentChild(MultiInputTemplateDirective, { read: TemplateRef, static: false })
  multiLabelTemplate: TemplateRef<ElementRef> | undefined;

  @ContentChild(InputTemplateDirective, { read: TemplateRef, static: false })
  labelTemplate: TemplateRef<ElementRef> | undefined;

  @ContentChild(InputTranslateTemplateDirective, { read: TemplateRef, static: false })
  labelTranslateTemplate: TemplateRef<ElementRef> | undefined;

  @ContentChild(InputHeaderDirective, { read: TemplateRef, static: false })
  headerTemplate: TemplateRef<ElementRef> | undefined;

  @ContentChild(InputNotFoundDirective, { read: TemplateRef, static: false })
  notFoundTemplate: TemplateRef<ElementRef> | undefined;

  @ContentChild(GroupTemplateDirective, { read: TemplateRef, static: false })
  groupTemplate: TemplateRef<ElementRef> | undefined;

  @ViewChild('dropDownInput') dropDownInput!: NgSelectComponent;
  @ViewChild('inputWidth') inputWidth!: ElementRef;
  @ViewChild('creationInput') creationInput?: ElementRef;

  savedValue!: string;
  savedOriginalValue!: string;
  private readonly timeDuration = 100;

  private intersectionObserver: IntersectionObserver | undefined;

  constructor(protected cdr: ChangeDetectorRef, private element: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    if (this.initFocus) {
      this.dropDownInput.focus();
    } else {
      of([])
        .pipe(take(1), delay(this.timeDuration))
        .subscribe(() => {
          if (this.dropDownInput) {
            this.dropDownInput.blur();
          }
        });
    }

    if (this.openDropdownHandle) {
      this.dropDownInput.open();
    }
  }

  protected onChange: (value: any) => void = () => null;
  protected onTouched: () => void = () => null;

  isLocked(value: ILockedTrigger, ngSelect: NgSelectComponent): void {
    this.locked = value.locked ? !value.focused : value.locked;
    if (!this.locked) {
      ngSelect.blur();
    }
  }

  onListenInput(value: { term: string; items: any[] }): void {
    if (this.useInfiniteScroll && this.savedValue === value.term) {
      return;
    }
    this.emitValueSearch.emit(value);
  }

  searchFn = (term: string, item: any): boolean => {
    if (this.serverSideSearch) return true;
    if (this.searchParams.length) {
      return this.searchParams.some((x) => item[x]?.toLowerCase().includes(term.toLowerCase()));
    }
    return item[this.searchLabel || this.bindLabel].toLowerCase().includes(term.toLowerCase());
  };

  onRemoveValue(): void {
    this.clear.emit();
    this.savedValue = '';
    this.cdr.markForCheck();
  }

  onResize(): void {
    if (this.appendTo === 'body') {
      this.dropDownInput?.close();
    }
  }

  openCreation(): void {
    this.isActiveStaticCreation = true;
    setTimeout(() => this.creationInput?.nativeElement.focus(), 0);
  }

  changeValue(value: any): void {
    this.savedValue = '';
    this.savedOriginalValue = '';
    this.selectedItem = value;
    const specificValue = this.clearable
      ? value
        ? value[this.bindValue]
        : null
      : this.bindValue
      ? value[this.bindValue]
      : value;
    this.onChange(specificValue);
    this.itemSelected.emit(specificValue);
    if (this.asPrimitiveSelector) {
      return;
    }
    this.inputValue = specificValue;
    this.changeSelectedValue.emit(this.selectedItem);

    this.cdr.markForCheck();
  }

  changeInput(event: any): void {
    this.savedOriginalValue = event.target.value;
    if (this.savedOriginalValue?.length > this.maxLength) {
      event.target.value = this.savedOriginalValue.slice(0, this.maxLength);
    }
    const replaceEmoji = event.target.value.replaceAll(this.regex, '');
    const replaceCyrillic = replaceEmoji.replace(/[а-яА-ЯёЁ]/g, '');
    this.savedValue = event.target.value = replaceCyrillic;
  }

  initSearch(): void {
    if (this.savedValue && this.saveTermSearch) {
      this.dropDownInput.searchTerm = this.savedValue;
      if (this.dropDownInput.filter) {
        this.dropDownInput.filter(this.savedValue);
      }
    } else if (this.searchable && !this.useInfiniteScroll) {
      this.emitValueSearch.emit({ term: '' });
    }
  }

  onBlur(): void {
    this.onTouched();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    if (this.asPrimitiveSelector) {
      return;
    }
    this.inputValue = value;
    this.updateSelectedValue(this.inputValue);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  ngOnChanges({ listOfItems }: SimpleChanges): void {
    if (this.useInfiniteScroll && this.intersectionObserver && listOfItems) {
      const isTheSame =
        listOfItems.currentValue?.length === listOfItems.previousValue?.length &&
        listOfItems?.currentValue.every((curr: { id: number }) => {
          return !!listOfItems?.previousValue.find((prev: { id: number }) => prev.id === curr.id);
        });
      if (!isTheSame) {
        queueMicrotask(() => {
          this.initIntersectionObserver();
        });
      }
    } else if (listOfItems?.currentValue?.length !== listOfItems?.previousValue?.length) {
      this.updateSelectedValue(this.inputValue);
    } else {
      //TODO: incomprehensible action, breaks the component, for some reason listOfItems is not defined
      // this.changeValue(listOfItems);
    }
  }

  public updateSelectedValue(inputValue: string): void {
    this.selectedItem = CommonHelper.getItemFromObjectList(this.listOfItems ?? [], inputValue, this.bindValue);
    this.cdr.markForCheck();
  }

  onSaveSearchByClose(): void {
    if (this.saveTermSearch && this.savedValue) {
      this.dropDownInput.searchTerm = this.savedValue;
    } else {
      this.dropDownInput.searchTerm = '';
    }

    if (this.useInfiniteScroll && this.intersectionObserver) {
      this.intersectionObserver.disconnect();
      this.intersectionObserver = undefined;
    }

    this.isOpen = false;
    this.emitOpenDropdown.emit(this.isOpen);
    this.closeCreation();
    this.cdr.detectChanges();
  }

  openDropdown(): void {
    this.isOpen = true;
    this.emitOpenDropdown.emit(this.isOpen);
    if (this.useInfiniteScroll) {
      queueMicrotask(() => {
        this.initIntersectionObserver();
      });
    }
  }

  private initIntersectionObserver(): void {
    const optionsSelector = 'ng-dropdown-panel .ng-option:not(.ng-select__not-found)';
    const nodeListOfOptions = this.element.nativeElement.querySelectorAll(optionsSelector);
    if (!nodeListOfOptions.length) {
      return;
    }
    const targetOption = nodeListOfOptions.item(nodeListOfOptions.length - 1);
    this.intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.dropdownScrollOnBottom.emit({ search: this.savedValue });
            this.intersectionObserver?.disconnect();
          }
        });
      },
      { threshold: this.threshold }
    );

    this.intersectionObserver.observe(targetOption);
  }

  closeCreation(clear = false): void {
    this.dropDownInput.searchTerm = '';

    this.isActiveStaticCreation = false;
    if (this.creationInput) {
      this.creationInput.nativeElement.value = '';
    }

    if (clear) {
      this.dropDownInput.filter('');
    }
  }

  onCreation(term: string, e?: KeyboardEvent): void {
    if (e) {
      e.stopPropagation();
    }
    if (term) {
      this.emitOnCreation.emit(term);
      this.dropDownInput.close();
    }
  }
}
