<div class="floor-plan-version__header">
  <div class="d-flex align-items-center justify-content-between mb-12">
    <span class="fz-16 lh-24 fw-500">{{header | translate}}</span>
    <div
      (click)="closeModal()"
      class="floor-plan-version__header-icon"
      [ngbTooltip]="'Shared.Close' | translate"
      [openDelay]="tooltipDelay"
    >
      <img src="assets/close_newest_gray.svg" alt="close" />
    </div>
  </div>

  <atl-simple-tabs
    *ngIf="navList?.length"
    [withId]="true"
    [activeId]="activeId"
    [tabs]="navList"
    [routeNavigation]="false"
    (selectingTab)="selectTab($event)"
  ></atl-simple-tabs>
</div>

<div #parent class="floor-plan-version__main">
  <ng-container *ngIf="previewImage; else empty">
    <img [atlImageResize]="parent" [src]="previewImage" alt="floor-plan">
  </ng-container>
</div>

<ng-template #empty>
  <span class="fz-16 lh-24 text-black-810">{{'Shared.Mark.Message.Floor_plan_empty' | translate}}</span>
</ng-template>
