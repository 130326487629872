import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Exclude, Expose, Transform, Type } from 'class-transformer';

import { OptionChangeRequestModel } from './chenge-request.model';
import { ClientCategory, LayoutProductType } from './layout-option.model';
import { ClientPackageModel } from './package.model';
import { SelectedPackage } from './selected-package.model';
import { ITablePagination } from './table-pagination.interface';

export enum EOptionsUnitStatus {
  Completed = 'Completed',
  Uncompleted = 'Uncompleted',
}

export class OptionsModel {
  @Expose({ name: 'needed_offers_count' }) neededOffersCount!: number;
  @Expose({ name: 'layout_option_id' })
  layoutOptionId!: number;
  @Expose({ name: 'layout_type_id' })
  layoutTypeId!: number;
  @Expose({ name: 'layout_type_name' })
  layoutTypeName!: number;
  @Expose({ name: 'total_price' })
  totalPrice: string;
  @Expose({ name: 'price_currency' })
  priceCurrency: string;
  @Expose({ name: 'unit_identifier' })
  unitIdentifier: string;
  @Expose({ name: 'categories' })
  @Type(() => OptionCategoriesModel)
  categories: OptionCategoriesModel[];
  @Expose({ name: 'change_requests' })
  @Type(() => OptionChangeRequestModel)
  changeRequests!: OptionChangeRequestModel[];
  @Expose({ name: 'all_change_requests_price' })
  allChangeRequestsPrice!: number;
  @Expose({ name: 'all_completed_items_price' })
  allCompletedItemsPrice!: number;
  @Expose({ name: 'all_in_progress_items_price' })
  allInProgressItemsPrice!: number;

  @Expose({ name: 'total_options_price' })
  totalOptionsPrice!: number;

  @Expose({ name: 'client_categories' })
  @Type(() => ClientCategory)
  clientCategories!: ClientCategory[];

  @Expose({ name: 'selected_package' })
  @Type(() => SelectedPackage)
  selectedPackage!: SelectedPackage | null;

  constructor() {
    this.totalPrice = '';
    this.priceCurrency = '';
    this.unitIdentifier = '';
    this.categories = [];
  }
}

export interface IPriceOfferData {
  price: string;
  dueDate: string | NgbDateStruct;
  description?: string;
  files?: File[];
}

export class OptionCategoriesModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'name' })
  name: string;
  @Expose({ name: 'due_date' })
  dueDate: string;
  @Expose({ name: 'price_currency' })
  priceCurrency: string;
  @Expose({ name: 'products' })
  @Type(() => OptionsProductsModel)
  products: OptionsProductsModel[];
  @Expose({ name: 'total_price' })
  totalPrice: string;
  @Expose({ name: 'room_walls_area_total' })
  roomWallsAreaTotal: number;
  @Expose({ name: 'room_size' })
  roomSize: string;

  constructor() {
    this.id = -1;
    this.name = '';
    this.dueDate = '';
    this.priceCurrency = '';
    this.products = [];
    this.totalPrice = '';
    this.roomWallsAreaTotal = 0;
    this.roomSize = '';
  }
}

export class OptionsFileNameW260Model {
  @Expose({ name: 'download_url' })
  downloadUrl: string;
  @Expose({ name: 'url' })
  url: string;

  constructor() {
    this.downloadUrl = '';
    this.url = '';
  }
}

export class OptionsFileNameModel {
  @Expose({ name: 'download_url' })
  downloadUrl: string;
  @Expose({ name: 'type' })
  type: string;
  @Expose({ name: 'url' })
  url: string;
  @Expose({ name: 'w260' })
  @Type(() => OptionsFileNameW260Model)
  w260: OptionsFileNameW260Model;

  constructor() {
    this.downloadUrl = '';
    this.type = '';
    this.url = '';
    this.w260 = new OptionsFileNameW260Model();
  }
}

export class OptionsProductsPreviewModel {
  @Expose({ name: 'created_at' })
  createdAt: string;
  @Expose({ name: 'filename' })
  @Type(() => OptionsFileNameModel)
  filename: OptionsFileNameModel;
  @Expose({ name: 'id' })
  id: string;
  @Expose({ name: 'name' })
  name: string;

  constructor() {
    this.createdAt = '';
    this.filename = new OptionsFileNameModel();
    this.id = '';
    this.name = '';
  }
}

export class OptionsProductsModel {
  @Expose({ name: 'currency' })
  currency: string;
  @Expose({ name: 'customer_price' })
  customerPrice: string;
  @Expose({ name: 'customer_price_with_vat' })
  customerPriceWithVat: string;
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'layout_product_type_id' })
  layoutProductTypeId: number;
  @Expose({ name: 'layout_product_type_item_id' })
  layoutProductTypeItemId: number;
  @Expose({ name: 'preview' })
  @Type(() => OptionsProductsPreviewModel)
  preview: OptionsProductsPreviewModel;
  @Expose({ name: 'price' })
  price: string;
  @Expose({ name: 'product_type_id' })
  productTypeId: number;
  @Expose({ name: 'product_type_name' })
  productTypeName: string;
  @Expose({ name: 'quantity' })
  quantity: number;
  @Expose({ name: 'standard' })
  standard: boolean;
  @Expose({ name: 'title' })
  title: string;
  @Expose({ name: 'allow_free_text' })
  allowFreeText: boolean;
  @Expose({ name: 'free_text' })
  freeText: string | null;

  @Expose({ name: 'is_wall_square' }) isWallSquare!: boolean;
  @Expose({ name: 'price_type' }) priceType!: string;
  @Expose({ name: 'area_size' }) areaSize!: string;

  constructor() {
    this.currency = '';
    this.customerPrice = '';
    this.customerPriceWithVat = '';
    this.id = -1;
    this.layoutProductTypeId = -1;
    this.layoutProductTypeItemId = -1;
    this.preview = new OptionsProductsPreviewModel();
    this.price = '';
    this.productTypeId = -1;
    this.productTypeName = '';
    this.quantity = 0;
    this.standard = false;
    this.title = '';
    this.allowFreeText = false;
    this.freeText = null;
  }
}

export class OptionProgressModel {
  @Expose({ name: 'options_sales_count' })
  optionsSalesCount: number | string;
  @Expose({ name: 'options_profit_count' })
  optionsProfitCount: number | string;
  @Expose({ name: 'options_per_unit_count' })
  optionsPerUnitCount: number | string;
  @Expose({ name: 'progress_count' })
  progressCount: number | string;

  constructor() {
    this.optionsSalesCount = 'N/A';
    this.optionsProfitCount = 'N/A';
    this.optionsPerUnitCount = 'N/A';
    this.progressCount = 'N/A';
  }
}

export class FirmOptionsDataModel {
  @Expose({ name: 'layout_options' })
  @Type(() => FirmOptionsModel)
  layoutOptions!: FirmOptionsModel[];

 @Expose({ name: 'projects' }) projects!:{
    id: number;
    name: string;
 }[]

}

export class FirmOptionsModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'layout_type_name' }) layoutTypeName!: string;
  @Expose({ name: 'project_name' }) projectName!: string;
  @Expose({ name: 'project_id' }) projectId!: number;
  @Expose({ name: 'category_names' }) categoryNames!: string[];
}

export class LayoutOptionCategory {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'description' }) description!: string | null;
  @Expose({ name: 'is_introduction' }) isIntroduction!: boolean;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'layout_product_types' })
  @Type(() => LayoutProductType)
  layoutProductTypes!: LayoutProductType[];

  selected = true;
  atLeastOneSelected = true;
  expanded = false;
}

export class OptionTemplateDefails {
  @Expose({ name: 'layout_option_categories' })
  @Type(() => LayoutOptionCategory)
  layoutOptionCategories!: LayoutOptionCategory[];

  @Expose({ name: 'layout_option_packages' })
  @Type(() => ClientPackageModel)
  layoutOptionPackages!: ClientPackageModel[];
}

export interface TClientRequestOption {
  mandatory_items_count: number;
  submitted_mandatory_items_count: number;
  closest_due_date?: string;
  not_started?: boolean;
  categories: {
    name: string;
    status: string;
    due_date: string;
  }[];
}

export class ClientOptionModel {
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'due_date' }) dueDate!: string;
}

export interface TClientIndex {
  submittedMandatoryItemsCount: number;
  mandatoryItemsCount: number;
  closestDueDate?: string;
  notStarted: boolean;
  categories: ClientOptionModel[];
}

export interface IOptionSetting {
  allowBankId: boolean;
  standardProductPreselection: boolean;
  showCategoryPackages: boolean;
}

export class PhasesModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'start_date' }) startDate!: string;
  @Expose({ name: 'end_date' }) endDate!: string;
  @Expose({ name: 'categories_count' }) categoriesCount!: number;
  @Expose({ name: 'multiple_dates' }) multipleDates!: boolean;
  @Expose({ name: 'coalesce_start_date' }) coalesceStartDate!: string;
  @Expose({ name: 'coalesce_end_date' }) coalesceEndDate!: string;
  selected = false;
}

export interface IPhasesResponse {
  pagination: ITablePagination;
  phases: PhasesModel[];
}

export class PhasesCategoriesLayoutTypesModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'layout_option_id' }) layoutOptionId!: number;
}

export class PhasesCategoriesModel {
  @Expose({ name: 'ids' }) ids!: number[];
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'layout_types' })
  @Type(() => PhasesCategoriesLayoutTypesModel)
  layoutTypes!: PhasesCategoriesLayoutTypesModel[];
  @Expose({ name: 'layout_option_phase' }) layoutOptionPhase!: {
    id: number;
    name: string;
  } | null;

  selected = false;
}

export interface IPhasesCategoriesResponse {
  pagination: ITablePagination;
  category: PhasesCategoriesModel[];
}

export interface ICreatePhase {
  name: string;
  start_date?: string | null;
  end_date?: string | null;
  layout_option_category_names?: string[];
  option_configs_attributes: any[];
  unit_configs_attributes: any[];
}

export class LayoutOptionCategories {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'layout_type' }) layoutType!: {
    id: number;
    name: string;
  };
}

export class PhaseConfig {
  @Expose({ name: 'id' }) id?: number;
  @Expose({ name: 'start_date' }) startDate?: string | null;
  @Expose({ name: 'end_date' }) endDate?: string | null;

  @Exclude()
  get phaseDate(): { from?: string | null; to?: string | null } | null {
    if (!this.startDate && !this.endDate) return null;
    return { from: this.startDate, to: this.endDate };
  }

  constructor(from?: string, to?: string) {
    if (from && to) {
      this.startDate = from;
      this.endDate = to;
    } else {
      this.startDate = null;
      this.endDate = null;
    }
  }
}

export class LayoutOptionPhase {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'layout_type' }) layoutType!: {
    id: number;
    name: string;
  };
  @Expose({ name: 'phase_config' }) @Type(() => PhaseConfig) phaseConfig!: PhaseConfig | null;
  @Expose() @Transform(({ obj }) => obj.phase_config?.id) phaseConfigId?: number;
  destroy?: boolean = false;
}

export class UnitPhase {
  @Expose({ name: 'id' }) id: number;
  @Expose({ name: 'identifier' }) identifier: string;
  @Expose({ name: 'actual_start_date' }) actualStartDate?: string | null;
  @Expose({ name: 'actual_end_date' }) actualEndDate?: string | null;
  @Expose({ name: 'phase_config' }) @Type(() => PhaseConfig) phaseConfig: PhaseConfig | null;
  @Expose() @Transform(({ obj }) => obj.phase_config?.id) phaseConfigId?: number;

  @Exclude()
  get actualPhaseDate(): { from?: string | null; to?: string | null } | null {
    if (!this.actualStartDate && !this.actualEndDate) return null;
    return { from: this.actualStartDate, to: this.actualEndDate };
  }

  layoutTypeId?: number;
  destroy?: boolean = false;

  constructor(
    id: number,
    identifier: string,
    actualStartDate?: string | null,
    actualEndDate?: string | null,
    layoutTypeId?: number | undefined,
  ) {
    this.id = id;
    this.identifier = identifier;
    this.actualStartDate = actualStartDate;
    this.actualEndDate = actualEndDate;
    this.layoutTypeId = layoutTypeId;
    this.phaseConfig = null;
  }
}

export class PhaseDetailModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'start_date' }) startDate!: string;
  @Expose({ name: 'end_date' }) endDate!: string;
  @Expose({ name: 'categories_count' }) categoriesCount!: number;
  @Expose({ name: 'layout_option_categories' })
  @Type(() => LayoutOptionCategories)
  layoutOptionCategories!: LayoutOptionCategories[];

  @Expose({ name: 'layout_options' })
  @Type(() => LayoutOptionPhase)
  layoutOptions!: LayoutOptionPhase[];

  @Expose({ name: 'units' })
  @Type(() => UnitPhase)
  units!: UnitPhase[];

  @Exclude()
  get allPhaseDate(): { from?: string | null; to?: string | null } | null {
    if (!this.startDate && !this.endDate) return null;
    return { from: this.startDate, to: this.endDate };
  }
}

export class OptionsShortInfoModel {
  @Expose({ name: 'not_completed_items_count' }) notCompletedItemsCount!: number;
  @Expose({ name: 'option_start_date' }) optionStartDate!: string;
  @Expose({ name: 'option_end_date' }) optionEndDate!: string;
}
