<div class="confirm-signicat">
  <div class="confirm-signicat__header d-flex align-items-center">
    <h3 class="fz-26 lh-24 fw-500 notification__title ml-24">{{ 'Modal.Signicat.Confirm.Header' | translate }}</h3>
  </div>

  <div class="fz-14 lh-24 confirm-signicat__desc">
    <span class="fz-16 lh-24">
      {{ 'Modal.Signicat.Confirm.Description' | translate }}
    </span>
  </div>

  <div class="confirm-signicat__footer d-flex justify-content-end align-items-center">
    <button (click)="closeModal()" class="newest-button mr-24 capitalize">{{ 'Title.Ok' | translate }}</button>
  </div>
</div>
