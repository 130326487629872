import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  AdminFirmsModel,
  AdminModel,
  AdminProjectModel,
  IAdmin,
  IMembers,
  IMemberUpdates,
  IProjectMemberRoleUpdate,
  ITablePagination,
  IVendor,
  MembersModel,
  VendorModel,
} from '@atlas-workspace/shared/models';
import { AuthAdminService, DataTableHelperService } from '@atlas-workspace/shared/service';
import { plainToClass } from 'class-transformer';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProjectMembersService {
  private firm!: AdminFirmsModel | null;
  public adminPagination$ = new Subject<ITablePagination | null>();

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private http: HttpClient,
    private authAdminService: AuthAdminService,
    private tableService: DataTableHelperService
  ) {
    this.firm = this.authAdminService.firm;
  }

  public getProjectMembers(projectId: number, sort = 'default'): Observable<MembersModel[]> {
    return this.http
      .get<{ data: { members: IMembers[] } }>(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/members?firm_id=${this.firm?.firmId}&sort_by=${sort}`
      )
      .pipe(
        map((res) => res.data.members),
        map((data) => plainToClass(MembersModel, data))
      );
  }

  public getAdmins(search = '', projectId?: number, pagination?: ITablePagination): Observable<AdminModel[]> {
    let params: HttpParams = this.tableService.paramsHandler(search, 'default', pagination);
    if (projectId) params = params.set('excluded_project_id', projectId);
    return this.http
      .get<{ data: { main_admins: IAdmin[] } }>(`${this.environment.apiBaseUrl}api/v1/main_admins`, {
        params: params,
      })
      .pipe(
        map((res) => res.data.main_admins),
        map((data) => plainToClass(AdminModel, data))
      );
  }

  /**
   * @Cypress
   */
  getProjectAdmins(
    projectId: string,
    search = '',
    sort = '',
    paginate?: ITablePagination,
    tags?: string[],
    roles?: string[],
    withUnregistered?: boolean
  ): Observable<AdminProjectModel[]> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (tags?.length) params = params.set('acc_tags', tags.toString());
    if (roles && roles.length) {
      roles.forEach((role) => {
        params = params.append('roles[]', role);
      });
    }
    if (withUnregistered) {
      params = params.set('with_unregistered', withUnregistered.toString());
    }
    return this.http
      .get<any>(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/admins`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          if (result.headers) {
            const pagination = this.tableService.getPagination(result);
            this.adminPagination$.next(pagination);
          }
        }),
        map((res) => res.body.data.project_admins),
        map((data: AdminProjectModel[]) => plainToClass(AdminProjectModel, data))
      );
  }

  public inviteProjectMember(projectId: number, member: IMemberUpdates): Observable<AdminModel> {
    return this.http
      .post(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/members?firm_id=${this.firm?.firmId}`, member)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(AdminModel, data))
      );
  }

  public updateProjectMember(
    projectId: number,
    memberId: number,
    memberData: IProjectMemberRoleUpdate
  ): Observable<AdminModel> {
    return this.http
      .put(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/members/${memberId}?firm_id=${this.firm?.firmId}`,
        memberData
      )
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(AdminModel, data))
      );
  }

  public deleteProjectMember(projectId: number, memberId: number): Observable<string> {
    return this.http
      .delete(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/members/${memberId}?firm_id=${this.firm?.firmId}`
      )
      .pipe(map((res: any) => <string>res?.message));
  }

  public reSendInvite(memberId: number): Observable<string> {
    return this.http
      .patch(
        `${this.environment.apiBaseUrl}api/v1/firms/${this.firm?.firmId}/admin_data/${memberId}/resend_invitation`,
        null
      )
      .pipe(map((res: any) => <string>res?.message));
  }

  public getProjectVendor(): Observable<VendorModel[]> {
    return this.http.get<{ data: { vendors: IVendor[] } }>(this.environment.apiBaseUrl + 'api/v1/vendors').pipe(
      map((res: any) => res.data.vendors),
      map((vendors: []) => plainToClass(VendorModel, vendors))
    );
  }

  public postProjectVendor(body: { vendor: { title: string } }): Observable<VendorModel> {
    return this.http.post<{ data: { vendors: IVendor[] } }>(this.environment.apiBaseUrl + 'api/v1/vendors', body).pipe(
      map((res: any) => res.data),
      map((vendor) => plainToClass(VendorModel, vendor))
    );
  }
}
