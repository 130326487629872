import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ContenteditableValueAccessorDirective,
  DetectZoomModule,
  FileDragNDropModule, LockFieldDirective,
  SharedDirectivesModule,
  VerticalAlignDirectiveModule,
} from '@atlas-workspace/shared/directives';
import {
  AttachErrorPipe,
  AttachIconPipe,
  FileSizePipeModule,
  GetNameExtensionPipe,
  IsImagePipe,
  IsVideoPipe,
  SharedPipesModule,
  TaskStatusPipeModule,
} from '@atlas-workspace/shared/pipes';
import { SharedTranslateForChildModule } from '@atlas-workspace/shared/translate';
import {
  ButtonLoaderComponent,
  CustomIconComponent, HeaderIconComponent,
  ImageMarkComponent,
  NgbModalFooterComponent,
  NgbModalHeaderComponent, SimpleTabsComponent,
} from '@atlas-workspace/shared/ui';
import {
  NgbAccordionModule,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragulaModule } from 'ng2-dragula';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CustomCheckboxComponent } from './components/custom-checkbox/custom-checkbox.component';
import {CustomFilterDropdownComponent} from './components/custom-filter-dropdown/custom-filter-dropdown.component';
import { CustomTextAreaComponent } from './components/custom-text-area/custom-text-area.component';
import { DocumentDragAndDropComponent } from './components/document-drag-and-drop/document-drag-and-drop.component';
import { DrawingModalComponent } from './components/drawing-modal/drawing-modal.component';
import { DropDownV2Component } from './components/drop-down-v2/drop-down-v2.component';
import { DropDownWritingComponent } from './components/drop-down-writing/drop-down-writing.component';
import { DropdownAutocompleteComponent } from './components/dropdown-autocomplete/dropdown-autocomplete.component';
import { NewDropdownAutocompleteComponent } from './components/dropdown-autocomplete-v2/dropdown-autocomplete-v2.component';
import { DropdownInputFileComponent } from './components/dropdown-input-file/dropdown-input-file.component';
import { FloorPlanVersionSelectComponent } from './components/floor-plan-version-select/floor-plan-version-select.component';
import { GoogleAutocompleteComponent } from './components/google-autocomplete/google-autocomplete.component';
import { ImageCropModalComponent } from './components/image-crop-modal/image-crop-modal.component';
import { InlineDateTimePickerComponent } from './components/inline-date-time-picker/inline-date-time-picker.component';
import { InputComponent } from './components/input/input.component';
import { InputDateRangeComponent } from './components/input-date-range/input-date-range.component';
import { InputDateTimePickerComponent } from './components/input-date-time-picker/input-date-time-picker.component';
import { InputFileDragDropComponent } from './components/input-file-drag-drop/input-file-drag-drop.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { NewestDateTimePickerComponent } from './components/newest-date-time-picker/newest-date-time-picker.component';
import { NewestDropDownComponent } from './components/newest-drop-down/newest-drop-down.component';
import { NewestInputComponent } from './components/newest-input/newest-input.component';
import { NewestMultipleSelectComponent } from './components/newest-multiple-select/newest-multiple-select.component';
import { NewestSelectDropdownComponent } from './components/newest-select-dropdown/newest-select-dropdown.component';
import { PlanMarkComponent } from './components/plan-mark/plan-mark.component';
import { FloorMarkPipe } from './components/plan-mark-modal/pipes/floor-mark.pipe';
import { PlanMarkModalComponent } from './components/plan-mark-modal/plan-mark-modal.component';
import { ScalableBorderedImgComponent } from './components/scalable-bordered-img/scalable-bordered-img.component';
import { TextareaAutoresizeModule } from './components/textarea-autoresize/textarea-autoresize.module';
import { TitledCheckboxModule } from './components/titled-checkbox/titled-checkbox.module';
import { UndoCHangeslModule } from './components/undo-changes/undo-changes.module';
import { CropImageModalService } from './services/crop-image-modal.service';
import { CustomDateParserFormatterService } from './services/custom-date-parser-formatter.service';
import { DateAdapterService } from './services/date-adapter.service';
import {PhaseDateRangeComponent} from "./components/phase-date-range/phase-date-range.component";

const sharedComponents = [
  CustomCheckboxComponent,
  CustomTextAreaComponent,
  DropDownWritingComponent,
  DropDownV2Component,
  DropdownAutocompleteComponent,
  GoogleAutocompleteComponent,
  ImageCropModalComponent,
  InputDateTimePickerComponent,
  InlineDateTimePickerComponent,
  InputFileDragDropComponent,
  DocumentDragAndDropComponent,
  NewestInputComponent,
  NewestSelectDropdownComponent,
  NewestDateTimePickerComponent,
  NewestDropDownComponent,
  NewDropdownAutocompleteComponent,
  NewestMultipleSelectComponent,
  DropdownInputFileComponent,
  PlanMarkComponent,
  PlanMarkModalComponent,
  FloorMarkPipe,
  MessageModalComponent,
  InputDateRangeComponent,
  FloorPlanVersionSelectComponent,
  DrawingModalComponent,
  CustomFilterDropdownComponent,
  ContenteditableValueAccessorDirective,
  PhaseDateRangeComponent
];

const sharedModularComponents = [
  ScalableBorderedImgComponent,
  ImageMarkComponent,
  InputComponent,
  CheckboxComponent
];

const sharedModularPipes = [
  SharedPipesModule,
  TaskStatusPipeModule,
  FileSizePipeModule,
  AttachErrorPipe,
  AttachIconPipe,
  IsVideoPipe,
  GetNameExtensionPipe,
  IsImagePipe,
];

@NgModule({
  imports: [
    ...sharedModularComponents,
    ...sharedModularPipes,
    CommonModule,
    FormsModule,
    ImageCropperModule,
    NgSelectModule,
    NgbDatepickerModule,
    TitledCheckboxModule,
    UndoCHangeslModule,
    DragDropModule,
    SharedDirectivesModule,
    VerticalAlignDirectiveModule,
    NgxMaskDirective,
    TextareaAutoresizeModule,
    FileDragNDropModule,
    SharedTranslateForChildModule,
    DragulaModule,
    NgbTooltipModule,
    OverlayModule,
    DetectZoomModule,
    ReactiveFormsModule,
    NgbAccordionModule,
    LockFieldDirective,
    NgbModalFooterComponent,
    NgbModalHeaderComponent,
    CustomIconComponent,
    HeaderIconComponent,
    SimpleTabsComponent,
    ButtonLoaderComponent
  ],
  declarations: sharedComponents,
  exports: [
    ...sharedComponents,
    ...sharedModularComponents,
    ...sharedModularPipes,
    TitledCheckboxModule,
    UndoCHangeslModule,
    TextareaAutoresizeModule,
    SharedTranslateForChildModule,
    CommonModule
  ],
  providers: [
    provideNgxMask(),
    { provide: CropImageModalService },
    { provide: NgbDateAdapter, useClass: DateAdapterService },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService },
  ],
})
export class SharedFormModule {}
