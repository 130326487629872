import { Expose, Type } from 'class-transformer';

import { FileModel } from './file.model';

export enum EPriceOfferItemStatus {
  Offered = 'offered',
  Accepted = 'accepted',
}

interface Owner {
  id: number;
  name: string;
  email: string;
  type: string;
}

export class PriceOfferItemModel {
  @Expose({ name: 'wishlist_item_id' })
  wishlistItemId!: number;
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'status' })
  status!: any;
  @Expose({ name: 'title' })
  title!: string;
  @Expose({ name: 'product_type_name' })
  productTypeName!: string;
  @Expose({ name: 'unit_identifier' })
  unitIdentifier!: string;
  @Expose({ name: 'allow_free_text' })
  allowFreeText!: boolean;
  @Expose({ name: 'free_text' })
  freeText!: string;
  @Expose({ name: 'due_date' })
  dueDate!: string;
  @Expose({ name: 'price' })
  price!: number | null;
  @Expose({ name: 'currency' })
  currency!: string;
  @Type(() => FileModel)
  @Expose({ name: 'preview' })
  preview!: FileModel;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'primary_owner' })
  owner!: Owner;

  @Expose({ name: 'price_requested' }) priceRequested!: boolean;
}
