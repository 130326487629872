import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { DateTimePipeModule } from '@atlas-workspace/shared/pipes';
import { NgbDropdown, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { FirmIconComponent } from '../firm-icon/firm-icon.component';
import { HeaderIconComponent } from '../header-icon/header-icon.component';

@Component({
  selector: 'atl-more-dropdown',
  templateUrl: './more-dropdown.component.html',
  styleUrls: ['./more-dropdown.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HeaderIconComponent,
    NgbDropdownModule,
    TranslateModule,
    FirmIconComponent,
    DateTimePipeModule,
    NgbTooltipModule,
  ],
})
export class MoreDropdownComponent {
  @Input() header = true;
  @Input() body = true;
  @Input() footer = true;
  @Input() date!: string;
  @Input() user!: string;
  @Input() client = false;

  public readonly openTooltipDelay = 500;

  @ViewChild('dropdown') private dropdownRef!: NgbDropdown;

  get dropdown(): NgbDropdown {
    return this.dropdownRef;
  }
}
