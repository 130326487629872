import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ELocalStorageKeys,
  EUserAuthHeaders,
  IAuthHeaders,
  IResetPassParams,
  IUser,
  UserModel,
} from '@atlas-workspace/shared/models';
import { LocalStorageService } from '@atlas-workspace/shared/service';
import { environment } from '@environment-client';
import { plainToClass } from 'class-transformer';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { IRegistrationUser } from '../../interface/registration-user.interface';

@Injectable()
export class AuthService {
  private loginEmail$ = new BehaviorSubject<string | null>(null);

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {}

  public signUp(name: string, email: string, password: string): Observable<UserModel> {
    const userToSave: IRegistrationUser = {
      email: email,
      name: name,
      password: password,
      password_confirmation: password,
    };
    return this.http
      .post<{ data: { data: IUser } }>(`${environment.apiBaseUrl}api/v1/auth`, userToSave, { observe: 'response' })
      .pipe(
        tap((x) => this.saveAuthHeaders(x.headers)),
        map((res) => res.body?.data),
        map((data) => plainToClass(UserModel, data))
      );
  }

  public signIn(email: string, password: string): Observable<UserModel> {
    const signInValue = {
      email: email,
      password: password,
    };
    return this.http
      .post<any>(`${environment.apiBaseUrl}api/v1/auth/sign_in`, signInValue, { observe: 'response' })
      .pipe(
        tap((x) => this.saveAuthHeaders(x.headers)),
        map((res) => res.body?.data),
        map((data) => plainToClass(UserModel, data)),
        tap((user) => this.saveUserInfo(user))
      );
  }

  public validateAuthHeaders(): boolean {
    return !!this.localStorageService.get<IAuthHeaders>(ELocalStorageKeys.AuthHeaders);
  }

  public saveUserInfo(user: UserModel): void {
    this.localStorageService.set(ELocalStorageKeys.UserInfo, { fullName: user.name, id: user.id });
  }

  get getUserInfo(): { fullName: string; id: number } | null {
    return this.localStorageService.get<{ fullName: string; id: number }>(ELocalStorageKeys.UserInfo);
  }

  public getNewUserData(token: string): IRegistrationUser {
    return jwt_decode(token);
  }

  public signOut(): Observable<boolean> {
    return this.http.delete(`${environment.apiBaseUrl}api/v1/auth/sign_out`).pipe(
      tap(() => {
        this.localStorageService.clear();
      }),
      map((res: any) => res.success),
      map((data) => <boolean>data)
    );
  }

  public forgotPassword(email: string, redirect_url: string): Observable<string> {
    return this.http
      .post(`${environment.apiBaseUrl}api/v1/auth/password`, { email, redirect_url })
      .pipe(map((res: any) => <string>res?.message));
  }

  public resetPassword(resetData: IResetPassParams): Observable<UserModel> {
    return this.http.put<any>(`${environment.apiBaseUrl}api/v1/auth/password`, resetData, { observe: 'response' }).pipe(
      tap((x) => this.saveAuthHeaders(x.headers)),
      map((res) => res.body?.data),
      map((data) => plainToClass(UserModel, data)),
      tap((user) => this.saveUserInfo(user))
    );
  }

  public resetPasswordProfile(resetData: IResetPassParams): Observable<UserModel> {
    return this.http.put<any>(`${environment.apiBaseUrl}api/v1/auth`, resetData, { observe: 'response' }).pipe(
      tap((x) => this.saveAuthHeaders(x.headers)),
      map((res) => res.body?.data),
      map((data) => plainToClass(UserModel, data)),
      tap((user) => this.saveUserInfo(user))
    );
  }

  public getUserByEmail(token: string): Observable<string> {
    return this.http
      .get(`${environment.apiBaseUrl}api/v1/user_data/email/${token}`)
      .pipe(map((res: any) => <string>res.data?.email));
  }

  private saveAuthHeaders(headers: HttpHeaders): void {
    const authHeaders: any = {
      token: headers.get(EUserAuthHeaders.AccessToken),
      uid: headers.get(EUserAuthHeaders.Uid),
      client: headers.get(EUserAuthHeaders.Client),
    };

    const refreshToken = headers.get(EUserAuthHeaders.RefreshToken);
    if (refreshToken) {
      authHeaders.refreshToken = refreshToken;
    } else {
      const existingHeaders: IAuthHeaders | null = this.localStorageService.get(ELocalStorageKeys.AuthHeaders);
      const existingRefreshToken = existingHeaders?.refreshToken;
      if (existingRefreshToken) {
        authHeaders.refreshToken = existingRefreshToken;
      }
    }

    this.localStorageService.set(ELocalStorageKeys.AuthHeaders, authHeaders);

    this.localStorageService.set(ELocalStorageKeys.AuthHeaders, authHeaders);
  }

  public set savedLoginEmail(email: string | null) {
    this.loginEmail$.next(email);
  }

  public get savedLoginEmail$(): Observable<string | null> {
    return this.loginEmail$.asObservable();
  }

  public get authHeaders(): IAuthHeaders | null {
    return this.localStorageService.get<IAuthHeaders>(ELocalStorageKeys.AuthHeaders);
  }

  loginByToken(token: string): Observable<UserModel> {
    return this.http
      .post<any>(
        `${environment.apiBaseUrl}api/v1/auth/sign_in_by_token`,
        {},
        {
          observe: 'response',
          params: { token },
        }
      )
      .pipe(
        tap((x) => this.saveAuthHeaders(x.headers)),
        map((res) => res.body?.data),
        map((data) => plainToClass(UserModel, data)),
        tap((user) => this.saveUserInfo(user))
      );
  }

  public refreshClientToken(username: string, refreshToken: string): Observable<any> {
    const body = {
      username: username,
      refresh_token: refreshToken,
    };
    return this.http
      .post(environment.apiBaseUrl + 'api/v1/auth/refresh_token', body, {
        observe: 'response',
      })
      .pipe(
        tap((x) => {
          this.saveAuthHeaders(x.headers);
        })
      );
  }

  registerUser(name: string, email: string, password: string, token: string): Observable<UserModel> {
    const body: IRegistrationUser = {
      email: email,
      name: name,
      password: password,
    };
    return this.http
      .patch<any>(environment.apiBaseUrl + 'api/v1/auth/register', body, {
        params: {
          invite_token: token,
        },
        observe: 'response',
      })
      .pipe(
        tap((x) => {
          this.saveAuthHeaders(x.headers);
        }),
        map((res) => res.body?.data),
        map((admin) => {
          return plainToClass(UserModel, admin);
        }),
        tap((user) => this.saveUserInfo(user))
      );
  }
}
