import { Pipe, PipeTransform } from '@angular/core';
import { ETimeFormat } from '@atl-libs-shared-pipes/time-passed-ago/interfaces/time-passed-ago';
import { ELang } from '@atlas-workspace/shared/models';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import nb from 'dayjs/locale/nb';
import sv from 'dayjs/locale/sv';
import duration from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import { timer } from 'rxjs';
import { map } from 'rxjs/operators';

dayjs.extend(isToday);
dayjs.extend(duration);
dayjs.extend(localeData);
dayjs.extend(utc);

@Pipe({
  name: 'timePassedAgo',
})
export class TimePassedAgoPipe implements PipeTransform {
  public readonly unixToUtcHook = 1000;
  public readonly dayjsLocales = new Map([
    [ELang.English, en],
    [ELang.Norwegian, nb],
    [ELang.Swedish, sv],
  ]);
  constructor(private trs: TranslateService) {}

  transform(value: number, type = ETimeFormat.Default, interval: number = 6e4): unknown {
    if (!value) return '';

    dayjs.locale(this.dayjsLocales.get(<ELang>this.trs.currentLang));

    const transformedDate = dayjs(value * this.unixToUtcHook);

    return timer(0, interval).pipe(
      map(() => {
        const now = dayjs();
        const minutes = now.diff(transformedDate, 'minute');
        const hours = now.diff(transformedDate, 'hour');
        const days = now.diff(transformedDate, 'day');

        let prepDate = '';

        if (transformedDate.isToday()) {
          if (days < 1 && hours >= 1 && hours < 24) {
            prepDate = `
          ${hours} 
          ${hours === 1 ? this.trs.instant('Shared.Entity.Hour') : this.trs.instant('Shared.Entity.Hours')} 
          `;
          } else if (days < 1 && hours < 1 && minutes <= 59 && minutes >= 1) {
            prepDate = `
          ${minutes} 
          ${
            type === ETimeFormat.Short
              ? minutes === 1
                ? this.trs.instant('Shared.Entity.Minute_short')
                : this.trs.instant('Shared.Entity.Minutes_short')
              : minutes === 1
              ? this.trs.instant('Shared.Entity.Minute')
              : this.trs.instant('Shared.Entity.Minutes')
          } 
          `;
          } else if (days < 1 && hours < 1 && minutes < 1) {
            prepDate = this.trs.instant('Shared.Entity.Now');
          }
        } else {
          //TODO localize months
          if (days >= 7) {
            type === ETimeFormat.Short
              ? (prepDate = transformedDate.format('D MMM YYYY'))
              : (prepDate = transformedDate.format('D MMM YYYY, HH:mm'));
          } else {
            type === ETimeFormat.Short
              ? (prepDate = transformedDate.format('dddd'))
              : (prepDate = transformedDate.format('dddd, HH:mm'));
          }
        }
        return prepDate;
      })
    );
  }
}
