/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  AdminModel,
  AdminProjectModel,
  AnalyticsModel,
  analyticsRequestQuery,
  DocumentUrlModel,
  EContractorTypeForReclamationsModel,
  EFileType,
  ELocalStorageKeys,
  EReclamationReportType,
  EReclamationStatusKey,
  ETablePagination,
  EUnitStatus,
  FileDownloadModel,
  FileModel,
  GlobalHeaderModel,
  ICreateRuleForm,
  ImageModel,
  IReclamationCategory,
  IReclamationCategoryTypes,
  IReclamationData,
  IReclamationProducts,
  IReclamationRooms,
  ISelectedReclamationFilters,
  ISelectGlobalReclamationFilters,
  isImageExtension,
  ITablePagination,
  IUnitMember,
  IUnitType,
  IUnitUser,
  ReclamationFiltersIndexCounterModel,
  ReclamationRecipientModel,
  ReclamationRuleModel,
  ReclamationSettingsModel,
  ReclamationsModel,
  ReclamationUnitModel,
  ReclFilterProjectModel,
  ReclFilterUnitGroupModel,
  ReclFilterUnitModel,
  SpecialPermissionsModel,
  UnitMemberModel,
  UnitTypeDetailsModel,
  UnitTypeModel,
  UnitUserModel,
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import { map, switchMap, tap, toArray } from 'rxjs/operators';

import { FileHelper } from '../../helpers/file';
import { PaginationUtil } from '../../helpers/pagination.util';
import { getEntityListByChunks } from '../../helpers/request-chunks.util';
import { AuthAdminService } from '../auth/auth-admin.service';
import { DataTableHelperService } from '../data-table-helper/data-table-helper.service';

@Injectable({
  providedIn: 'root',
})
export class ReclamationAdminService {
  private readonly headers = new HttpHeaders().set(GlobalHeaderModel.SkipGlobalInterceptor, 'true');
  private reclamationPagination$ = new BehaviorSubject<ITablePagination | null>(null);
  public rulesPagination$ = new Subject<ITablePagination | null>();
  private _updateReclamation$ = new Subject<ReclamationsModel>();

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private http: HttpClient,
    private authAdminService: AuthAdminService,
    private tableService: DataTableHelperService
  ) {}

  get pagination$(): Observable<ITablePagination | null> {
    return this.reclamationPagination$.asObservable();
  }

  public get updateReclamation$(): Observable<ReclamationsModel> {
    return this._updateReclamation$.asObservable();
  }

  public setUpdateReclamation(rec: ReclamationsModel): void {
    this._updateReclamation$.next(rec);
  }

  /**
   * @Cypress
   */
  public getUnits(projectId: string, page?: number): Observable<UnitUserModel[]> {
    const statuses = [EUnitStatus.Reserved, EUnitStatus.Sold];
    let params = new HttpParams({
      fromObject: { 'sale_statuses[]': statuses },
    });
    if (page) params = params.set('page', String(page));
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units_users`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data.units),
        map((data: IUnitUser[]) => plainToClass(UnitUserModel, data))
      );
  }

  /**
   * @Cypress
   */
  public getUnitsTypes(projectId: number, page?: number, onlyWithLayoutOption = false): Observable<UnitTypeModel[]> {
    let params: HttpParams = new HttpParams();
    if (onlyWithLayoutOption) {
      params = params.append('only_with_layout_option', String(onlyWithLayoutOption));
    }
    if (page) {
      params = params.append('page', page.toString());
    }
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types`, {
        params: params,
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data.layout_types),
        map((data: IUnitType[]) => plainToClass(UnitTypeModel, data))
      );
  }

  public getUnitDetails(projectId: string, id: number): Observable<UnitUserModel> {
    const includes = ['floors', 'rooms'];
    const params = new HttpParams({
      fromObject: { 'includes[]': includes },
    });
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units_users/${id}`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data),
        map((data: UnitUserModel) => plainToClass(UnitUserModel, data))
      );
  }

  /**
   * @Cypress
   */
  public getCategories(includeTypes = false): Observable<IReclamationCategory[]> {
    const firm_id = this.authAdminService.firm?.firmId.toString();
    let params = new HttpParams();
    if (includeTypes) {
      params = params.append('includes[]', 'types');
    }
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/firms/${firm_id}/reclamation_categories`, {
        observe: 'response',
        params,
      })
      .pipe(map((res) => res.body.data.reclamation_categories));
  }

  /**
   * @Cypress
   */
  public createCategory(category: string): Observable<any> {
    const fd = new FormData();
    fd.append('reclamation_category[name]', category);

    const firm_id = this.authAdminService.firm?.firmId.toString();
    return this.http.post(this.environment.apiBaseUrl + `api/v1/firms/${firm_id}/reclamation_categories`, fd);
  }

  public getTypes(
    categoryId?: number,
    search = '',
    pagination?: ITablePagination
  ): Observable<IReclamationCategoryTypes[]> {
    const firm_id = this.authAdminService.firm?.firmId.toString();
    let params: HttpParams = this.tableService.paramsHandler('', 'default', pagination);
    params = params.set('search', search);
    if (categoryId) {
      params = params.set('category_id', categoryId.toString());
    }
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/firms/${firm_id}/reclamation_types`, {
        params: params,
        observe: 'response',
      })
      .pipe(map((res) => res.body.data.reclamation_types));
  }

  public createType(categoryId: number, type: string): Observable<any> {
    const fd = new FormData();
    fd.append('reclamation_type[category_id]', categoryId.toString());
    fd.append('reclamation_type[name]', type.toString());

    const firm_id = this.authAdminService.firm?.firmId.toString();
    return this.http.post(this.environment.apiBaseUrl + `api/v1/firms/${firm_id}/reclamation_types`, fd);
  }

  //TODO move this method to another service
  public getAdmins(
    projectId?: string,
    tag?: string,
    searchParam = '',
    pagination?: ITablePagination
  ): Observable<AdminModel[]> {
    let params: HttpParams = this.tableService.paramsHandler(searchParam, 'default', pagination);
    if (tag && projectId) {
      params = params.set('project_id', projectId);
      params = params.set('acc_tag', tag);
    }
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/main_admins`, {
        observe: 'response',
        params,
      })
      .pipe(
        map((res) => res.body.data.main_admins),
        map((data: AdminModel[]) => plainToClass(AdminModel, data))
      );
  }

  /**
   * @Cypress
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Reclamations/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1reclamations/get
   */
  public getReclamationsList(
    projectId: number,
    sort = '',
    search = '',
    byUser = false,
    hasMessages = false,
    paginate?: ITablePagination,
    unitId?: number,
    selectedFilters?: ISelectedReclamationFilters
  ): Observable<IReclamationData> {
    // ToDO: refctor this filtering query params logic
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    const userInfo = JSON.parse(localStorage.getItem(ELocalStorageKeys.UserInfo) as string);
    if (byUser) {
      if (selectedFilters) {
        selectedFilters.responsibleIds.push(userInfo.value.id);
        selectedFilters.responsibleIds = Array.from(new Set(selectedFilters.responsibleIds));
      }
    } else {
      if (selectedFilters?.responsibleIds.length) {
        const i = selectedFilters.responsibleIds.findIndex((x) => x === userInfo.value.id);
        if (~i) {
          selectedFilters.responsibleIds.splice(i, 1);
        }
      }
    }

    if (hasMessages) {
      params = params.set('message_thread_has_messages', hasMessages.toString());
    }

    if (unitId && !selectedFilters?.unitIds.length) {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      params = params.set('unit_ids[]', unitId.toString());
    }

    params = this.processSelectedFilters(params, selectedFilters as ISelectedReclamationFilters);

    return this.http
      .get<any>(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/reclamations`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(result.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(result.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalPages),
          };
          this.reclamationPagination$.next(pagination);
        }),
        map((res) => res.body.data),
        tap((data) => {
          data.reclamations = data.reclamations.map((x) => plainToClass(ReclamationsModel, x));
        })
      );
  }

  public getReclamationsListState(
    projectId: number,
    sort = '',
    search = '',
    byUser = false,
    hasMessages = false,
    paginate?: ITablePagination,
    unitId?: number,
    selectedFilters?: ISelectedReclamationFilters
  ): Observable<any> {
    // ToDO: refctor this filtering query params logic
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    const userInfo = JSON.parse(localStorage.getItem(ELocalStorageKeys.UserInfo) as string);
    if (byUser) {
      if (selectedFilters) {
        selectedFilters.responsibleIds.push(userInfo.value.id);
        selectedFilters.responsibleIds = Array.from(new Set(selectedFilters.responsibleIds));
      }
    } else {
      if (selectedFilters?.responsibleIds.length) {
        const i = selectedFilters.responsibleIds.findIndex((x) => x === userInfo.value.id);
        if (~i) {
          selectedFilters.responsibleIds.splice(i, 1);
        }
      }
    }

    if (hasMessages) {
      params = params.set('message_thread_has_messages', hasMessages.toString());
    }

    if (unitId && !selectedFilters?.unitIds.length) {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      params = params.set('unit_ids[]', unitId.toString());
    }

    params = this.processSelectedFilters(params, selectedFilters as ISelectedReclamationFilters);
    return this.http.get<any>(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/reclamations`, {
      params,
      observe: 'response',
    });
  }

  public getGlobalReclamationsList(
    sort = '',
    search = '',
    byUser = false,
    hasMessages = false,
    paginate?: ITablePagination,
    unitId?: number,
    selectedFilters?: ISelectGlobalReclamationFilters
  ): Observable<IReclamationData> {
    // ToDO: refctor this filtering query params logic
    const firmId = this.authAdminService.firm?.firmId;
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    const userInfo = JSON.parse(localStorage.getItem(ELocalStorageKeys.UserInfo) as string);
    if (byUser) {
      if (selectedFilters) {
        selectedFilters.responsibleIds.push(userInfo.value.id);
        selectedFilters.responsibleIds = Array.from(new Set(selectedFilters.responsibleIds));
      }
    } else {
      if (selectedFilters?.responsibleIds.length) {
        const i = selectedFilters.responsibleIds.findIndex((x) => x === userInfo.value.id);
        if (~i) {
          selectedFilters.responsibleIds.splice(i, 1);
        }
      }
    }

    if (hasMessages) {
      params = params.set('message_thread_has_messages', hasMessages.toString());
    }

    if (unitId && !selectedFilters?.unitIds.length) {
      params = params.set('unit_ids[]', unitId.toString());
    }

    if (selectedFilters?.projectIds.length) {
      const idsParams = new HttpParams({ fromObject: { 'project_ids[]': selectedFilters.projectIds } });
      params = this.appendParams(params, idsParams);
    }

    params = this.processSelectedFilters(params, selectedFilters as ISelectedReclamationFilters);

    return this.http
      .get<any>(this.environment.apiBaseUrl + `api/v1/firms/${firmId}/reclamations`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(result.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(result.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalPages),
          };
          this.reclamationPagination$.next(pagination);
        }),
        map((res) => res.body.data),
        tap((data) => {
          data.reclamations = data.reclamations.map((x) => plainToClass(ReclamationsModel, x));
        })
      );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private processSelectedFilters(params: HttpParams, selectedFilters: ISelectedReclamationFilters): HttpParams {
    if (selectedFilters?.unitIds.length) {
      const idsParams = new HttpParams({ fromObject: { 'unit_ids[]': selectedFilters.unitIds } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.statuses.length) {
      const idsParams = new HttpParams({ fromObject: { 'statuses[]': selectedFilters.statuses } });
      params = this.appendParams(params, idsParams);
      if (selectedFilters.statuses.includes(EReclamationStatusKey.InternalReview)) {
        params = params.append('statuses[]', EReclamationStatusKey.WorkDone);
      }
      if (selectedFilters.statuses.includes(EReclamationStatusKey.WorkDone)) {
        params = params.append('statuses[]', EReclamationStatusKey.InternalReview);
      }
    }

    if (selectedFilters?.categoryIds.length) {
      const idsParams = new HttpParams({ fromObject: { 'category_ids[]': selectedFilters.categoryIds } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.typeIds.length) {
      const idsParams = new HttpParams({ fromObject: { 'type_ids[]': selectedFilters.typeIds } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.responsibleIds.length) {
      const idsParams = new HttpParams({ fromObject: { 'responsible_ids[]': selectedFilters.responsibleIds } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.layoutTypeIds.length) {
      const idsParams = new HttpParams({ fromObject: { 'layout_type_ids[]': selectedFilters.layoutTypeIds } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.meetingTypes.length) {
      const idsParams = new HttpParams({ fromObject: { 'meeting_type_ids[]': selectedFilters.meetingTypes } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.author.length) {
      const idsParams = new HttpParams({ fromObject: { 'author_types[]': selectedFilters.author } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.threadState.length) {
      const idsParams = new HttpParams({ fromObject: { 'message_states[]': selectedFilters.threadState } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.contractorCompanies?.length) {
      const idsParams = new HttpParams({
        fromObject: { 'contractor_company_ids[]': selectedFilters.contractorCompanies },
      });
      params = this.appendParams(params, idsParams);
    }
    if (selectedFilters?.contractorsAndCompanies?.length) {
      const idsParams = new HttpParams({
        fromObject: {
          'contractor_company_ids[]': selectedFilters.contractorsAndCompanies
            .filter((item) => item.type === EContractorTypeForReclamationsModel.ContractorCompany)
            .map((item) => item.id),
          'contractor_ids[]': selectedFilters.contractorsAndCompanies
            .filter((item) => item.type === EContractorTypeForReclamationsModel.Contractor)
            .map((item) => item.id),
        },
      });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.date) {
      if (selectedFilters.date[0]?.from) {
        const dateFromParams = new HttpParams({
          fromObject: { min_reported_date: selectedFilters.date[0]?.from as string },
        });
        params = this.appendParams(params, dateFromParams);
      }
      if (selectedFilters.date[0]?.to) {
        const dateToParams = new HttpParams({
          fromObject: { max_reported_date: selectedFilters.date[0]?.to as string },
        });
        params = this.appendParams(params, dateToParams);
      }
    }

    return params;
  }

  private appendParams(params: HttpParams, sourceParams: HttpParams): HttpParams {
    sourceParams.keys().forEach((key: string) => {
      const values = sourceParams.getAll(key);

      if (values) {
        values.forEach((value: string | null) => {
          if (value !== null) {
            params = params.append(key, value);
          }
        });
      }
    });

    return params;
  }

  /**
   * @Cypress
   */
  public getReclamationIndexCounters(
    projectId: number,
    search = '',
    selectedFilters: ISelectedReclamationFilters
  ): Observable<ReclamationFiltersIndexCounterModel> {
    let params: HttpParams = new HttpParams();
    if (search) params = params.set('search', search);
    params = this.processSelectedFilters(params, selectedFilters as ISelectedReclamationFilters);
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/reclamations/index_counter`, {
        params: params,
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data),
        map((data: ReclamationFiltersIndexCounterModel) => plainToClass(ReclamationFiltersIndexCounterModel, data))
      );
  }

  public getGlobalReclamationIndexCounters(
    search = '',
    selectedFilters: ISelectGlobalReclamationFilters
  ): Observable<ReclamationFiltersIndexCounterModel> {
    const firmId = this.authAdminService.firm?.firmId;
    let params: HttpParams = new HttpParams();
    if (search) params = params.set('search', search);
    if (selectedFilters?.projectIds.length) {
      const idsParams = new HttpParams({ fromObject: { 'project_ids[]': selectedFilters.projectIds } });
      params = this.appendParams(params, idsParams);
    }
    params = this.processSelectedFilters(params, selectedFilters as ISelectedReclamationFilters);
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/firms/${firmId}/reclamations/index_counter`, {
        params: params,
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data),
        map((data: ReclamationFiltersIndexCounterModel) => plainToClass(ReclamationFiltersIndexCounterModel, data))
      );
  }

  // ToDO add model in next MR
  public getReclamationsAdmins(projectId: number): Observable<any> {
    return this.http.get(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/reclamation_admins`, {});
  }

  public getProducts(unitId: number, search: string): Observable<IReclamationProducts[]> {
    let params: HttpParams = new HttpParams();
    if (search) params = params.set('search', search);

    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/units/${unitId}/wishlist/products`, {
        params: params,
        observe: 'response',
      })
      .pipe(map((res) => res.body.data.products));
  }

  public getRooms(projectId: string, layoutId: number): Observable<IReclamationRooms[]> {
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types/${layoutId}/rooms`, {
        observe: 'response',
      })
      .pipe(map((res) => res.body.data.rooms));
  }

  public getFloorPlans(projectId: string, layoutId: number): Observable<UnitTypeDetailsModel> {
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types/${layoutId}`, {
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data),
        map((data) => plainToClass(UnitTypeDetailsModel, data))
      );
  }

  /**
   * @Cypress
   */
  public createProjectReclamation(projectId: string, body: FormData): Observable<ReclamationsModel> {
    return this.http
      .post(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/reclamations`, body, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => res.data),
        map((reclamation) => plainToClass(ReclamationsModel, reclamation))
      );
  }

  removeReclamations(projectId: number, ids: number[]): Observable<any> {
    let params: HttpParams = new HttpParams();
    ids.forEach((id) => (params = params.append('ids[]', id.toString())));
    return this.http.delete<{ data: ReclamationsModel }>(
      `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/reclamations/batch_destroy`,
      {
        params,
        headers: this.headers,
      }
    );
  }

  removeGlobalReclamations(ids: number[]): Observable<any> {
    const firmId = this.authAdminService.firm?.firmId;
    let params: HttpParams = new HttpParams();
    ids.forEach((id) => (params = params.append('ids[]', id.toString())));
    return this.http.delete<{ data: ReclamationsModel }>(
      `${this.environment.apiBaseUrl}api/v1/firms/${firmId}/reclamations/batch_destroy`,
      {
        params,
      }
    );
  }

  removeReclamation(projectId: number, id: number): Observable<any> {
    return this.http.delete<{ data: ReclamationsModel }>(
      `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/reclamations/${id}`
    );
  }

  public getReclamation(projectId: string, id: number): Observable<ReclamationsModel> {
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/reclamations/${id}`, {
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data),
        map((data) => plainToClass(ReclamationsModel, data)),
        map((reclamation) => {
          reclamation.fileResources?.forEach((file: any) => {
            if (isImageExtension(file)) {
              file.type = EFileType.Image;
              Object.assign(file, plainToClass(ImageModel, file));
            } else {
              file.type = EFileType.File;
              Object.assign(file, plainToClass(FileModel, file));
            }
          });
          return reclamation;
        })
      );
  }

  /**
   * @Cypress
   */
  public updateProjectReclamation(projectId: string, id: number, body: FormData): Observable<ReclamationsModel> {
    return this.http
      .patch(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/reclamations/${id}`, body, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ReclamationsModel, data))
      );
  }

  public getReclamationUnitBuyers(projectId: string, unitId: string): Observable<UnitMemberModel[]> {
    const params: HttpParams = this.tableService.paramsHandler('', '', { pageItems: 1000 });
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/units/${unitId}/members`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((response) => response.body),
        map(({ data: { members } }) => {
          return plainToClass(UnitMemberModel, members as IUnitMember[]);
        })
      );
  }

  public getDeclineReasons(): Observable<any> {
    const firm_id = this.authAdminService.firm?.firmId.toString();
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/firms/${firm_id}/reclamation_event_reasons`, {
        observe: 'response',
      })
      .pipe(map((res) => res.body.data.reclamation_event_reasons));
  }

  /**
   * @Cypress
   */
  public changeReclamationStatus(
    projectId: number,
    reclamationId: number,
    status: EReclamationStatusKey,
    force: boolean,
    body?: FormData
  ): Observable<ReclamationsModel> {
    let params: HttpParams = new HttpParams();
    params = params.set('status', status);
    if (force) params = params.set('force', force.toString());

    return this.http
      .patch<any>(
        this.environment.apiBaseUrl + `api/v1/projects/${projectId}/reclamations/${reclamationId}/change_status`,
        body,
        {
          params: params,
          observe: 'response',
          headers: this.headers,
        }
      )
      .pipe(
        map((res) => res.body.data),
        map((data) => plainToClass(ReclamationsModel, data))
      );
  }

  /**
   * @Cypress
   */
  public getReclamationUnitsList(
    projectId: number,
    sort = '',
    search = '',
    onlyWithReclamations = false,
    paginate?: ITablePagination
  ): Observable<ReclamationUnitModel[]> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (onlyWithReclamations) {
      params = params.set('only', onlyWithReclamations.toString());
    }
    return this.http
      .get<any>(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/units/stats/reclamations`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(result.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(result.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalPages),
          };
          this.reclamationPagination$.next(pagination);
        }),
        map((res) => res.body.data.units),
        map((data: ReclamationUnitModel[]) => plainToClass(ReclamationUnitModel, data))
      );
  }

  public getGlobalReclamationUnitsList(
    sort = '',
    search = '',
    onlyWithReclamations = false,
    paginate?: ITablePagination
  ): Observable<ReclamationUnitModel[]> {
    const firmId = this.authAdminService.firm?.firmId;
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (onlyWithReclamations) {
      params = params.set('only', onlyWithReclamations.toString());
    }
    return this.http
      .get<any>(this.environment.apiBaseUrl + `api/v1/firms/${firmId}/units/stats/reclamations`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(result.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(result.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalPages),
          };
          this.reclamationPagination$.next(pagination);
        }),
        map((res) => res.body.data.units),
        map((data: ReclamationUnitModel[]) => plainToClass(ReclamationUnitModel, data))
      );
  }

  getAnalytics(projectId: number, from: string, to: string): Observable<AnalyticsModel> {
    return of(analyticsRequestQuery).pipe(
      switchMap((req) => {
        const getReq: Observable<any>[] = req.map((query) => {
          let params: HttpParams = new HttpParams();
          query.forEach((q) => {
            params = params.set(q, 'true');
          });
          params = params.set('start_date', from);
          params = params.set('end_date', to);

          return this.http
            .get(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/reclamations/statistic`, {
              params,
            })
            .pipe(map((res: any) => res.data));
        });

        return concat(...getReq).pipe(toArray());
      }),
      map((data) => data.reduce((acc, d) => ({ ...acc, ...d }), {})),
      map((data) => plainToClass(AnalyticsModel, data))
    );
  }

  getReclamationSettings(projectId: number): Observable<ReclamationSettingsModel> {
    return this.http
      .get(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/project_reclamation_settings`)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ReclamationSettingsModel, data))
      );
  }

  setReclamationSettings(projectId: number, body: any): Observable<ReclamationSettingsModel> {
    return this.http
      .patch(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/project_reclamation_settings`, {
        project_reclamation_settings: body,
      })
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ReclamationSettingsModel, data))
      );
  }

  getReclamationRecipients(projectId: number): Observable<ReclamationRecipientModel[]> {
    return this.http.get<any>(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/reclamation_recipients`).pipe(
      map((res) => res.data.reclamation_recipients),
      map((data: ReclamationRecipientModel[]) => plainToClass(ReclamationRecipientModel, data))
    );
  }

  setReclamationRecipients(projectId: number, fd: FormData): Observable<ReclamationRecipientModel[]> {
    return this.http
      .put<any>(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/reclamation_recipients`, fd)
      .pipe(
        map((res) => res.data.reclamation_recipients),
        map((data: ReclamationRecipientModel[]) => plainToClass(ReclamationRecipientModel, data))
      );
  }

  createRule(projectId: number, data: ICreateRuleForm): Observable<ReclamationRuleModel> {
    const fd = new FormData();
    fd.append('project_reclamation_rule[reclamation_category_id]', data.categoryId.toString());
    if (data.typeId) fd.append('project_reclamation_rule[reclamation_type_id]', data.typeId.toString());
    fd.append('project_reclamation_rule[responsible_id]', data.responsibleId.toString());
    fd.append('project_reclamation_rule[comment]', data.comment.trim());
    return this.http
      .post<any>(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/project_reclamation_rules`, fd)
      .pipe(
        map((res) => res.data),
        map((res) => plainToClass(ReclamationRuleModel, res))
      );
  }

  getRules(
    projectId: string,
    search = '',
    sort = '',
    paginate?: ITablePagination,
    categoryId?: number,
    typeId?: number
  ): Observable<ReclamationRuleModel[]> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (typeId) params = params.set('reclamation_type_id', typeId.toString());
    if (categoryId) params = params.set('reclamation_category_id', categoryId.toString());
    return this.http
      .get<any>(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/project_reclamation_rules`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          if (result.headers) {
            const pagination = this.tableService.getPagination(result);
            this.rulesPagination$.next(pagination);
          }
        }),
        map((res) => res.body.data.project_reclamation_rules),
        map((data: ReclamationRuleModel[]) => plainToClass(ReclamationRuleModel, data))
      );
  }

  getRule(projectId: number, ruleId: number): Observable<ReclamationRuleModel> {
    return this.http
      .get<any>(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/project_reclamation_rules/${ruleId}`, {
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data),
        map((data: ReclamationRuleModel) => plainToClass(ReclamationRuleModel, data))
      );
  }

  editRule(projectId: number, ruleId: number, fd: FormData): Observable<ReclamationRuleModel> {
    return this.http
      .put<any>(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/project_reclamation_rules/${ruleId}`, fd)
      .pipe(
        map((res) => res.data),
        map((data: ReclamationRuleModel) => plainToClass(ReclamationRuleModel, data))
      );
  }

  removeRules(projectId: number, ids: number[]): Observable<any> {
    let params: HttpParams = new HttpParams();
    ids.forEach((id) => (params = params.append('ids[]', id.toString())));
    return this.http.delete<{ data: ReclamationRuleModel }>(
      `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/project_reclamation_rules/batch_destroy`,
      {
        params,
      }
    );
  }

  removeSingleRule(projectId: number, id: number): Observable<any> {
    return this.http.delete<{ data: ReclamationRuleModel }>(
      `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/project_reclamation_rules/${id}`
    );
  }

  downloadReclamationReport(
    projectId: number,
    sort = '',
    search = '',
    byUser = false,
    hasMessages = false,
    unitId?: number,
    selectedFilters?: ISelectedReclamationFilters,
    selectedReclamations: number[] = [],
    type = EReclamationReportType.PDF
  ): Observable<FileDownloadModel> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort);
    const userInfo = JSON.parse(localStorage.getItem(ELocalStorageKeys.UserInfo) as string);
    if (byUser) {
      if (selectedFilters) {
        selectedFilters.responsibleIds.push(userInfo.value.id);
        selectedFilters.responsibleIds = Array.from(new Set(selectedFilters.responsibleIds));
      }
    } else {
      if (selectedFilters?.responsibleIds.length) {
        const i = selectedFilters.responsibleIds.findIndex((x) => x === userInfo.value.id);
        if (~i) {
          selectedFilters.responsibleIds.splice(i, 1);
        }
      }
    }

    if (hasMessages) {
      params = params.set('message_thread_has_messages', hasMessages.toString());
    }

    if (unitId && !selectedFilters?.unitIds.length) {
      params = params.set('unit_ids[]', unitId.toString());
    }

    params = params.set('max_image_count', 1);
    params = params.set('preview_floor_plan', true);
    params = params.set('report_type', type);

    if (selectedReclamations.length) {
      selectedReclamations.forEach((id) => (params = params.append('reclamation_ids[]', id.toString())));
    }

    params = this.processSelectedFilters(params, selectedFilters as ISelectedReclamationFilters);

    return this.http
      .post(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/reclamations/report`, null, {
        params,
      })
      .pipe(map((file) => plainToClass(FileDownloadModel, file)));
  }

  public getUserReclamationPermissions(
    projectId: string,
    id?: number,
    model?: string
  ): Observable<SpecialPermissionsModel[]> {
    let params: HttpParams = new HttpParams();
    if (id) {
      params = params.set('holder_id', id.toString());
      params = params.set('holder_type', 'MainAdmin');
    }
    if (model) params = params.set('model', model);
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/special_permissions`, {
        observe: 'response',
        params,
      })
      .pipe(
        map((res) => res.body.data.special_permissions),
        map((data: SpecialPermissionsModel[]) => plainToClass(SpecialPermissionsModel, data))
      );
  }

  public createPermission(projectId: number, data: SpecialPermissionsModel): Observable<SpecialPermissionsModel> {
    const fd = new FormData();
    fd.append('special_permission[model]', data.model);
    fd.append('special_permission[holder_type]', data.holderType.toString());
    fd.append('special_permission[holder_id]', data.holderId.toString());
    fd.append('special_permission[access_level]', data.accessLevel);
    if (data.restrictionName) fd.append('special_permission[restriction_name]', data.restrictionName);
    if (data.restrictionValueId)
      fd.append('special_permission[restriction_value_id]', data.restrictionValueId.toString());
    return this.http
      .post<any>(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/special_permissions`, fd)
      .pipe(
        map((res) => res.data),
        map((res) => plainToClass(SpecialPermissionsModel, res))
      );
  }

  destroyPermission(projectId: number, id: number): Observable<any> {
    return this.http.delete<{ data: SpecialPermissionsModel }>(
      `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/special_permissions/${id}`
    );
  }

  setNotes(projectId: string, id: number, note: string): Observable<any> {
    const body = new FormData();
    body.append('reclamation[note]', note);
    return this.http
      .patch(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/reclamations/${id}/update_note`, body)
      .pipe(map((res: any) => res.data.note));
  }

  /**
   * @Cypress
   */
  public batchUpdateReclamations(projectId: string, body: FormData): Observable<any> {
    return this.http.patch(
      this.environment.apiBaseUrl + `api/v1/projects/${projectId}/reclamations/batch_update`,
      body
    );
  }

  public generateDocumentUrlsS3(file: File): Observable<DocumentUrlModel[]> {
    const fd = new FormData();
    fd.append('filenames[]', file.name);
    return this.http
      .post<{
        data: DocumentUrlModel[];
      }>(`${this.environment.apiBaseUrl}api/v1/files/generate_multiple_presigned_urls`, fd, {})
      .pipe(
        map((res) => res.data),
        map((data: DocumentUrlModel[]) => plainToClass(DocumentUrlModel, data))
      );
  }

  uploadFileToS3(url: DocumentUrlModel, file: File): Observable<any> {
    let fileType = '';
    if (!file.type) {
      fileType = FileHelper.getFileTypeByExtension(file);
    } else {
      fileType = file.type;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': fileType });

    return this.http.put(url.uploadUrl, file, { headers });
  }

  /**
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Firm-Reclamations/paths/~1api~1v1~1firms~1{firm_id}~1reclamations~1projects/get
   */
  getFilterProjects(componentInstance: unknown): Observable<ReclFilterProjectModel[]> {
    const firmId = this.authAdminService.firm?.firmId;
    const currentObservable$ = { observable: undefined };
    return getEntityListByChunks<ReclFilterProjectModel>({
      http: this.http,
      url: `${this.environment.apiBaseUrl}api/v1/firms/${firmId}/reclamations/projects`,
      componentInstance: componentInstance,
      currentObservable$: currentObservable$,
      plainToClassCb: (body) => plainToClass(ReclFilterProjectModel, <ReclFilterProjectModel[]>body.data.projects),
    }).observable;
  }

  getFilterUnits(projectsIds: string[], search = '', sortBy = ''): Observable<ReclFilterUnitModel[]> {
    const firmId = this.authAdminService.firm?.firmId;
    let params = new HttpParams();
    if (projectsIds.length) {
      const idsParams = new HttpParams({ fromObject: { 'project_ids[]': projectsIds } });
      params = this.appendParams(params, idsParams);
    }
    params = params.set('search', search);
    if (sortBy) params = params.set('sort_by', sortBy);
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/firms/${firmId}/reclamations/units`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data.units),
        map((data: ReclFilterUnitModel[]) => plainToClass(ReclFilterUnitModel, data))
      );
  }

  getFilterUnitGroups(projectsIds: string[], search = '', sortBy = ''): Observable<ReclFilterUnitGroupModel[]> {
    const firmId = this.authAdminService.firm?.firmId;
    let params = new HttpParams();
    if (projectsIds.length) {
      const idsParams = new HttpParams({ fromObject: { 'project_ids[]': projectsIds } });
      params = this.appendParams(params, idsParams);
    }
    params = params.set('search', search);
    if (sortBy) params = params.set('sort_by', sortBy);
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/firms/${firmId}/reclamations/layout_types`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data.layout_types),
        map((data: ReclFilterUnitGroupModel[]) => plainToClass(ReclFilterUnitGroupModel, data))
      );
  }

  setProjectReclamationsAsRead(projectId: string, ids: number[]): Observable<any> {
    const body = new FormData();
    ids.forEach((id) => {
      body.append('ids[]', id.toString());
    });
    return this.http.patch(
      this.environment.apiBaseUrl + `api/v1/projects/${projectId}/reclamations/mark_as_read`,
      body
    );
  }

  setGlobalReclamationsAsRead(ids: number[]): Observable<any> {
    const firmId = this.authAdminService.firm?.firmId;
    const body = new FormData();
    ids.forEach((id) => {
      body.append('ids[]', id.toString());
    });
    return this.http.patch(this.environment.apiBaseUrl + `api/v1/firms/${firmId}/reclamations/mark_as_read`, body);
  }

  setAllProjectReclAsRead(projectId: number): Observable<any> {
    return this.http.patch(
      this.environment.apiBaseUrl + `api/v1/projects/${projectId}/reclamations/mark_all_as_read`,
      {}
    );
  }

  setAllGlobalReclAsRead(): Observable<any> {
    const firmId = this.authAdminService.firm?.firmId;
    return this.http.patch(this.environment.apiBaseUrl + `api/v1/firms/${firmId}/reclamations/mark_all_as_read`, {});
  }

  getResponsibleForReclamation(projectId:number, unitId?: number, categoryId?: number): Observable<Partial<AdminModel>> {
    let params = new HttpParams();
    if (unitId) params = params.set('unit_id', unitId);
    if (categoryId) params = params.set('category_id', categoryId);
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/reclamations/default_responsible`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((res) => res.body.data),
        map((data: Partial<AdminModel>) => plainToClass(AdminModel, data))
      );
  }

}
