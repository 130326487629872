import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageBannerService {
  private _showBanner$ = new Subject<string>();

  get showMessageBannerEvent$(): Observable<string> {
    return this._showBanner$.asObservable();
  }

  public triggerShowBanner(text: string): void {
    this._showBanner$.next(text);
  }
}
