import {
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {ILockedTrigger, InputTemplateDirective} from '@atlas-workspace/shared/directives';

import arrowIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/newest_dropdown_state.svg';

import { DropdownItem } from '../../interface/dropdown-item';
import { CustomSelectDropdownComponent } from '../custom-select-dropdown/custom-select-dropdown.component';

@Component({
  selector: 'atl-newest-select-dropdown',
  templateUrl: './newest-select-dropdown.component.html',
  styleUrls: ['./newest-select-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewestSelectDropdownComponent),
      multi: true,
    },
  ],
})
export class NewestSelectDropdownComponent extends CustomSelectDropdownComponent implements OnChanges {
  @Input() listOfValues: DropdownItem[] = [];
  @Input() bindTitle = 'name';
  @Input() bindValue = 'id';
  @Input() showArrow = false;
  @Input() showCheck = true;
  @Input() sweechLockOff = false;

  @Input() customTitleTemplate = false;
  @Input() customTitleValue = false;

  @Input() emptyText!: string;
  @Input() showIcon = false;

  @ContentChild(InputTemplateDirective, { read: TemplateRef, static: false })
  valueTemplate: TemplateRef<ElementRef> | undefined;

  @ContentChild(InputTemplateDirective, { read: TemplateRef, static: false })
  labelTemplate: TemplateRef<ElementRef> | undefined;

  public displayValue!: string | null;
  public selectedItem!: DropdownItem;

  public readonly arrowIcon = arrowIcon;
  public locked = false;

  constructor() {
    super();
  }

  isLocked(value: ILockedTrigger): void {
    this.locked = value.locked ? !value.focused : value.locked;
  }

  onChangeValue(newValue: string | number | DropdownItem | null): void {
    this.changeValue(newValue);
    this.findDisplayValue(newValue);
    this.onCloseModal();
  }

  changeValue(value: string | number | DropdownItem | null): void {
    this.formValue = value;
    this.onChange(value);
  }

  writeValue(value: string | number | DropdownItem | null): void {
    this.formValue = value;
    this.findDisplayValue(value);
  }

  findDisplayValue(value: string | number | DropdownItem | null): void {
    if (value && this.listOfValues) {
      const foundItem = this.listOfValues.find((item) => item[this.bindValue] === value);
      if (foundItem) {
        this.selectedItem = foundItem;
        this.displayValue = foundItem[this.bindTitle];
      }
    } else {
      this.displayValue = null;
    }
  }

  ngOnChanges({ listOfValues }: SimpleChanges): void {
    if (listOfValues && listOfValues.currentValue) {
      this.listOfValues = listOfValues.currentValue;
      this.findDisplayValue(this.formValue);
    }
  }
}
