import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FloorModel, IMark, ISettingsMenu, UnitModel } from '@atlas-workspace/shared/models';
import { PlanMarkService } from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'atl-plan-mark-modal',
  templateUrl: './plan-mark-modal.component.html',
  styleUrls: ['./plan-mark-modal.component.scss'],
})
export class PlanMarkModalComponent implements OnInit {
  @Input() modalRef!: NgbModalRef;
  @Input() oneMarkMode = false;
  @Input() floorPlan!: FloorModel[];
  @Input() units?: UnitModel[];
  @Input() disabled?: boolean;
  @Input() client = false;
  @Input() hasUnitAccess = true;
  @Input() disableDrag = true;
  @Input() activeIndex: number | undefined;
  @Input() readonly multiplePins = false;

  public readonly titleNewReclamation = 'Title.Reclamations_details';
  public readonly titleMarkReclamation = 'Shared.Entity.Floor_plan';
  public activeId!: number;
  public navList!: ISettingsMenu[];
  public mark: IMark[] = [];
  public windowVisualHeight = window?.visualViewport?.height + 'px';
  public readonly tooltipDelay = 500;

  constructor(private cdr: ChangeDetectorRef, private planMarkService: PlanMarkService) {}

  ngOnInit(): void {
    if (!this.oneMarkMode) {
      this.initPlanMarkRemoveListener();
    }
    if (this.floorPlan?.length >= 1) {
      this.initNavList();
    }
  }

  private initPlanMarkRemoveListener(): void {
    this.planMarkService.updatedMarkRemove$
      .pipe(
        filter((res) => !!res),
        untilDestroyed(this)
      )
      .subscribe((res: IMark[] | null) => {
        if (res) {
          this.mark = res;
        }
      });
  }

  existingMarkClick(v: { left: string; top: string; order: number }, plan: FloorModel): void {
    const newData = {
      ...v,
      floorId: plan.id,
      floorNumber: plan.floorNumber,
      floorType: plan.floorType,
    };

    this.planMarkService.setExistingMarkClick(newData);
  }

  public floorTrackBy(index: number, item: FloorModel): number {
    return item.id;
  }

  public closeModal(): void {
    this.modalRef.close();
  }

  public addTags(): void {
    if (this.mark.length) {
      this.modalRef.close(this.mark);
    }
  }

  public setMark(mark: IMark): void {
    if (!this.disabled) {
      this.oneMarkMode ? this.oneMark(mark) : this.moreMark(mark);
    }
  }

  public selectUnit(id: number): void {
    this.floorPlan = this.units?.find((unit) => unit.id === id) as any;
    if (this.floorPlan.length) {
      this.initNavList();
    }
    this.cdr.detectChanges();
  }

  private moreMark(mark: IMark): void {
    const idx = this.mark?.findIndex((m) => m.floorId === mark.floorId);
    if (idx !== -1) {
      this.mark[idx].floorNumber = mark.floorNumber;
      this.mark[idx].floorType = mark.floorType;
      this.mark[idx].mark?.length
        ? (this.mark[idx].mark = [...this.mark[idx].mark, ...mark.mark])
        : (this.mark[idx] = mark);

      this.mark = [...this.mark];
    } else {
      this.mark = [...this.mark, ...[mark]];
    }
    if (!this.oneMarkMode) {
      this.planMarkService.setMarkPin(this.mark);
    }

    this.cdr.detectChanges();
  }

  private oneMark(mark: IMark): void {
    this.mark = [...[mark]];
  }

  public selectTab(event: { itemId: number }): void {
    this.activeId = event.itemId;
  }

  private initNavList(): void {
    let activeTabIndex = this.activeIndex ?? 0;
    this.navList = this.floorPlan.map((p, i) => {
      if (this.mark.length && p.id === this.mark[0].floorId) activeTabIndex = i;
      return {
        id: p.id,
        name: p.name,
        route: '',
        disabled: false,
        hasPlan: !!p.plan,
      };
    });

    if (this.activeIndex !== undefined) {
      this.activeId = this.navList[this.activeIndex].id as number;
      return;
    }
    this.activeId = this.navList[activeTabIndex].id as number;
  }
}
